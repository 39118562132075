import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from 'constants/endpoints';
import routes from 'constants/routes';
import useApi from 'hooks/useApi';
import { Button, Card, EventCard, Logo } from 'components';
import { RootState } from 'types/store';

const EventInfoPage = () => {
  const { isLogged } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const fetchEventInfo = () => {
    api.fetchData(endpoints.GET_EVENT_INFO.get({ id }));
  };

  const onSignIn = () => {
    navigate(`${routes.SIGN_IN}?redirect=${routes.EVENT_INFO.replace(':id', id!)}`);
  };

  useEffect(() => {
    isLogged && fetchEventInfo();
  }, [isLogged]);

  if (!isLogged) {
    return (
      <div className="page-event-info">
        <Logo contrast={true} />
        <Card title="Event information" description="You need to be logged in to see who are registered to this event.">
          <Card.Item>
            <Button text="Sign in" onClick={onSignIn} />
          </Card.Item>
        </Card>
      </div>
    );
  }

  if (api.isLoading || api.success === null) {
    return (
      <div className="page-event-info">
        <Card title="Event information"> </Card>
      </div>
    );
  }

  const { event, registered, iAmRegistered, backgroundCheckProcessing, employeeHasBackgroundCheck } = api.response;

  return (
    <div className="page-event-info">
      <Card
        title="Event information"
        description={
          api.response.iAmRegistered
            ? 'You are successfully registered for this event'
            : 'You are not registered to this event yet'
        }
      >
        {''}
      </Card>

      {!api.success ? (
        <Card title="Event not found"> </Card>
      ) : (
        <>
          <EventCard
            id={event.id}
            address={event.venue}
            date={event.date}
            reportTimeAt={event.report_time}
            groupMeLink={event.group_me}
            payModelUrl={event.paymodel_url}
            channelUrl={event.sendbird_channel_id}
            eventLogisticsUrl={event.event_logistics_url}
            eventTrainingUrl={event.event_training_url}
            title={event.address}
            subscribed={iAmRegistered}
            requireBackgroundCheck={event.requirebackgroundcheck && !employeeHasBackgroundCheck}
            requireAlcoholCertification={
              event.requireAlcoholCertification && !api.response.employeeHasAlcoholCertification
            }
            backgroundCheckProcessing={backgroundCheckProcessing}
            registrationCallback={fetchEventInfo}
            timezone={event.timezone}
            canConfirm={event.canConfirm}
            canDropout={event.canDropout}
            dropped={event.dropped}
            confirmationDeadline={event.confirmationDeadline}
            jobType={event.jobType}
            allocation={event.allocation}
          />

          <Card title="Registered" description="Colleagues who have registered to the event">
            <Card.Item>
              <table className="body-1">
                <thead>
                  <tr>
                    <th className="strong">Last name</th>
                    <th className="strong">First name</th>
                  </tr>
                </thead>
                {registered.length > 0 ? (
                  <tbody>
                    {registered.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.legal_last_name}</td>
                        <td>{item.legal_first_name}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </Card.Item>
          </Card>
        </>
      )}
    </div>
  );
};

export default EventInfoPage;

import React from 'react';
import classnames from 'classnames';

type Props = {
  icon: React.ReactNode;
  variant: 'primary' | 'warning' | 'success';
};

const IconBag = ({ icon, variant }: Props) => <div className={classnames('icon-bag', variant)}>{icon}</div>;

export default IconBag;

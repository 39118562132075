import React, { ChangeEvent } from 'react';
import Checkbox from 'components/common/checkbox';

import { CheckboxgroupProps } from 'types/dynamicInputs';
import classNames from 'classnames';

const CheckBoxGroup = ({
  value,
  label,
  required,
  onChange,
  disabled,
  pristine,
  error,
  options,
  isQuiz,
  questionId,
  wrongAnswer
}: CheckboxgroupProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value: v } = e.target;
    if (checked) {
      onChange([...value, v]);
    } else {
      onChange(value.filter((item: string) => item !== v));
    }
  };

  return (
    <div className={classNames('input-wrapper', { isQuiz })}>
      {!!label && (
        <div
          className={classNames('label-content', {
            'wrong-answer': wrongAnswer
          })}
        >
          {questionId && <span className="question-id">{questionId}</span>}
          <label className="label">
            {label}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      {options.map((option, index) => (
        <div key={index} className="checkbox-group">
          <Checkbox
            checked={value.includes(option.value)}
            label={option.value}
            value={option.value}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
      ))}
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default CheckBoxGroup;

import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { toast } from 'sonner';
import { Platform } from 'constants/platforms';

export const objectMap = (obj: Object, fn: Function) => {
  const arr = Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]);

  return arr.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {}); // Object.fromEntries
};

export const objectToArray = (object: Object) => {
  const array = Object.entries(object).map(([k, v]) => ({ ...v, id: k }));

  return array;
};

export const endpointsPrototype = {
  init() {
    for (const key of Object.keys(this)) {
      // @ts-ignore
      const item = this[key];

      Object.assign(item, {
        get(params?: any) {
          let { endpoint, method } = item;

          if (params) {
            for (const [key, value] of Object.entries(params)) {
              endpoint = endpoint.replace(`:${key}`, value as any);
            }
          }

          return { endpoint, method };
        }
      });
    }
  }
};

export const base64toBlob = async (base64: string, type = 'application/octet-stream'): Promise<Blob> => {
  const response = await fetch(`data:${type};base64,${base64}`);
  const blob = await response.blob();
  return blob;
};

export const blobToBase64 = (blob: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export const downloadFromBase64 = async (b64: string, fileName = 'file', fileExt = '.pdf') => {
  return new Promise(resolve => {
    if (Capacitor.getPlatform() === Platform.WEB) {
      base64toBlob(b64)
        .then(blob => {
          downloadBlob(blob, `${fileName}${fileExt}`);
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          resolve(false);
        });
    } else {
      const fileOptions = {
        path: `${fileName}${fileExt}`,
        data: b64,
        recursive: true,
        directory: Capacitor.getPlatform() === Platform.ANDROID ? Directory.External : Directory.Documents
      };
      const promptErr = (err: string) => {
        toast(err, {
          className: 'toast-message'
        });
      };

      Filesystem.writeFile(fileOptions)
        .then(res => {
          FileOpener.open({ filePath: res.uri });
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          promptErr('Something went wrong. Check for permissions');
          resolve(false);
        });
    }
  });
};

export const base64ToFile = async (dataurl: string, filename: string): Promise<File> => {
  const res = await fetch(dataurl);
  const blob = await res.blob();
  return new File([blob], filename, { type: blob.type });
};

export const generateFilename = (baseName: string, extension: string): string => {
  const timestamp = new Date().getTime();
  return `${baseName}-${timestamp}.${extension}`;
};

export const formatSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = (bytes / Math.pow(k, i)).toFixed(2);

  return `${size} ${sizes[i]}`;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

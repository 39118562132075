import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DocumentText, Import } from 'iconsax-react';
import { DropFileProps } from 'types/dynamicInputs';
import { FilePreview } from 'components';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { disableApplicantForm } from 'helpers/applicant';
import classNames from 'classnames';

const DropFile = ({ value, onChange, acceptedFileTypes, schema, setSchema, disabled }: DropFileProps) => {
  const api = useApi();
  const apiS3 = useApi();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileToS3 = async (file: File) => {
    const signedUrl = await api.fetchData(endpoints.GET_RESUME_SIGNED_URL.get(), {
      fileName: file.name
    });
    await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);

    return signedUrl.filePath;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length > 0) {
        disableApplicantForm({ schema, setSchema });
        setIsLoading(true);

        const file = acceptedFiles[0];
        const filePath = await uploadFileToS3(file);
        onChange({
          fileName: file.name,
          fileSize: file.size,
          path: filePath
        });
        setIsLoading(false);
      }
    },
    accept: acceptedFileTypes,
    multiple: false,
    disabled
  });

  const confirmRemoveFile = () => {
    modal.open(
      ModalsList.CONFIRM,
      flag => {
        if (flag) {
          onChange(null);
        }
      },
      {
        title: 'Remove file',
        message: 'Are you sure you want to remove the file?'
      }
    );
  };

  return (
    <>
      <section className="drop-file">
        <label className="label">Attach Resume</label>
        <>
          {value ? (
            <FilePreview
              fileName={value.fileName}
              fileSize={value.fileSize}
              onRemove={confirmRemoveFile}
              icon={<DocumentText size={16} />}
            />
          ) : (
            <div
              className={classNames('file-dropzone', {
                disabled
              })}
              {...getRootProps()}
            >
              <input
                style={{
                  display: 'none'
                }}
                {...getInputProps()}
              />
              <div className="placeholder">
                {isLoading ? (
                  <div className="loading-text">Uploading file...</div>
                ) : (
                  <>
                    <Import size={24} />
                    <p>Select a file or drag and drop</p>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      </section>
    </>
  );
};

export default DropFile;

import React from 'react';
import {
  Input,
  Select,
  Textarea,
  MaskedInput,
  AsyncSelect,
  InputFile,
  CheckboxGroup,
  RadioGroup,
  TimePicker,
  FormSection,
  DatePicker,
  VenueSelector,
  RadioSelector,
  DropImage,
  DropVideo,
  DropFile,
  AlternativeTextArea
} from 'components';

import { InputsEnum } from 'types/dynamicInputs';

interface Props {
  handler: any;
  onInputChange?: (query: string, key: string) => void;
}

const DynamicForm = ({ handler, onInputChange }: Props) => {
  if (!handler || handler.data === null) {
    return null;
  }

  return (
    <>
      {Object.keys(handler.schema).map(key => (
        <React.Fragment key={key}>
          {
            {
              [InputsEnum.INPUT]: (
                <Input
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.TEXTAREA]: (
                <Textarea
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.SELECT]: (
                <Select
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.MASKED]: (
                <MaskedInput
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.DATE]: (
                <DatePicker
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.ASYNC_SELECT]: (
                <AsyncSelect
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                  onSearch={query => onInputChange?.(key, query)}
                />
              ),
              [InputsEnum.CHECK_GROUP]: (
                <CheckboxGroup
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  label={handler.schema[key].label}
                  onChange={handler.setFormData(key)}
                  disabled={handler.schema[key].disabled}
                  options={handler.schema[key].options}
                />
              ),
              [InputsEnum.RADIO_GROUP]: (
                <RadioGroup
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  label={handler.schema[key].label}
                  onChange={handler.setFormData(key)}
                  disabled={handler.schema[key].disabled}
                  options={handler.schema[key].options}
                />
              ),
              [InputsEnum.TIME_PICKER]: (
                <TimePicker
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  setDirtyError={handler.setDirtyError}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.FORM_SECTION]: <FormSection {...handler.schema[key]} />,
              [InputsEnum.FILE_INPUT]: (
                <InputFile
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.VENUE_SELECTOR]: (
                <VenueSelector
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.RADIO_SELECTOR]: (
                <RadioSelector
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.DROP_IMAGE]: (
                <DropImage
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                  schema={handler.schema}
                  setSchema={handler.setSchema}
                />
              ),
              [InputsEnum.DROP_VIDEO]: (
                <DropVideo
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                  schema={handler.schema}
                  setSchema={handler.setSchema}
                />
              ),
              [InputsEnum.DROP_FILE]: (
                <DropFile
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                  schema={handler.schema}
                  setSchema={handler.setSchema}
                />
              ),
              [InputsEnum.ALTERNATIVE_TEXT_AREA]: (
                <AlternativeTextArea
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  placeholder={handler.schema[key].placeholder}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              )
            }[handler.schema[key].input as InputsEnum]
          }
        </React.Fragment>
      ))}
    </>
  );
};

export default DynamicForm;

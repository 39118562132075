import React from 'react';
import { Button, DynamicForm } from 'components';

type Props = {
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
};

const ForgotYourPassword = ({ formHandler, loading, onSubmit }: Props) => {
  return (
    <>
      <h6>Forgot your password?</h6>
      <p className="body">
        No problem. Just let us know your email address and we will email you a password reset link that will allow you
        to choose a new one.
      </p>
      <hr />
      <DynamicForm handler={formHandler} />
      <Button text="Request password reset" loading={loading} disabled={!formHandler.valid} onClick={onSubmit} />
    </>
  );
};

export default ForgotYourPassword;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'constants/routes';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card, Sanitized, ToggleSwitch, ViewerFile } from 'components';
import { setNotices } from 'store/actions/user';
import { RootState } from 'types/store';

const NoticesPage = () => {
  const [selected, setSelected] = useState<any>();
  const [valid, setValid] = useState<boolean>(false);
  const { notices } = useSelector((state: RootState) => state.user);
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    api.fetchData(endpoints.AGREE_NOTICES.get(), { selected: Object.keys(selected) });
  };

  const handleSwitch = (id: string) => () => {
    setSelected({ ...selected, [id]: !selected[id] });
  };

  useEffect(() => {
    if (selected) {
      setValid(Object.values(selected).every(value => value));
    }
  }, [selected]);

  useEffect(() => {
    if (notices.length) {
      setSelected(notices.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: false }), {}));
    } else {
      navigate(routes.HOME);
    }
  }, [notices]);

  useEffect(() => {
    if (api?.response?.success) {
      dispatch(setNotices([]));
    }
  }, [api.response]);

  return (
    <div className="page-notices">
      <Card title="Required notices">
        <>
          <Card.Item>
            {notices.length > 0 &&
              !!selected &&
              notices.map((item: any, index: number) => (
                <div key={item.id} className="notice">
                  <div className="body strong">
                    Notice {index + 1} of {notices.length}
                  </div>
                  <Sanitized html={item.content} />
                  {item.file && <ViewerFile fileExtension={item.file_extension} fileUrl={item.file_url} />}
                  <ToggleSwitch value={selected[item.id]} onChange={handleSwitch(item.id)} />
                </div>
              ))}
          </Card.Item>
          <Card.Footer>
            <Button text="Submit" onClick={handleSubmit} disabled={!valid} loading={api.isLoading} />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default NoticesPage;

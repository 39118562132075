import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'components';
import Summary from './summary';
import EventStats from './tabs/event';
import StandStats from './tabs/stand';
import PositionStats from './tabs/position';
import { RootState } from 'types/store';

const MyStatsPage = () => {
  const { data } = useSelector((state: RootState) => state.user);
  const employeeId = Number(data.employeeId);

  return (
    <div className="page-my-stats">
      <div className="container">
        <div className="title">
          <h6 className="semibold">My Stats</h6>
        </div>
        <Summary employeeId={employeeId} />
        <div className="tabs-container">
          <Tabs
            defaultIndex={0}
            data={[
              {
                title: 'Stats per Event',
                component: <EventStats employeeId={employeeId} />
              },
              {
                title: 'Stand Stats',
                component: <StandStats employeeId={employeeId} />
              },
              {
                title: 'Position Stats',
                component: <PositionStats employeeId={employeeId} />
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MyStatsPage;

import React from 'react';
import avatar from 'assets/images/avatar.png';

type ChannelListHeaderProps = {
  profileUrl: string;
  nickname: string;
  isMobile: boolean;
};

const ChannelListHeader = ({ profileUrl, nickname, isMobile }: ChannelListHeaderProps) => {
  const headerContent = (
    <>
      <div className="avatar">
        <img src={profileUrl ? profileUrl : avatar} alt="avatar" />
      </div>
      <span>{nickname}</span>
    </>
  );

  return isMobile ? headerContent : <div className="channel-header-container">{headerContent}</div>;
};

export default ChannelListHeader;

import React, { useEffect, useState } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card, Sanitized, ToggleSwitch, ViewerFile } from 'components';
import { Props } from '../types';

const RequiredNotices = ({ handleContinue, step }: Props) => {
  const api = useApi();
  const apiNotices = useApi();
  const [selected, setSelected] = useState<any>();
  const [valid, setValid] = useState<boolean>(false);

  const handleSubmit = () => {
    api.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step, selected: Object.keys(selected) });
  };

  const handleSwitch = (id: string) => () => {
    setSelected({ ...selected, [id]: !selected[id] });
  };

  const handleAcceptAll = () => {
    setSelected((prevState: any) => {
      const isChecked = !Object.values(prevState).every(value => value);
      return Object.keys(prevState)
        .map(key => ({ [key]: isChecked }))
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});
    });
  };

  useEffect(() => {
    if (selected) {
      const checks = Object.values(selected);
      setValid(!checks.includes(false));
    }
  }, [selected]);

  useEffect(() => {
    if (api.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [api.response]);

  useEffect(() => {
    if (apiNotices.response) {
      setSelected(apiNotices.response.items.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: false }), {}));
    }
  }, [apiNotices.response]);

  useEffect(() => {
    apiNotices.fetchData(endpoints.NOTICES.get());
  }, []);

  return (
    <div className="required-notices">
      <Card title="Required notices">
        <>
          <Card.Item>
            <div className="accept-all">
              <span>Accept all notices</span>
              <ToggleSwitch
                value={!!selected && Object.values(selected).every(value => value)}
                onChange={handleAcceptAll}
              />
            </div>
          </Card.Item>
          <Card.Item>
            {!!apiNotices.response &&
              !!selected &&
              apiNotices.response.items.map((item: any, index: number) => (
                <div key={index} className="notice">
                  <div className="body strong">
                    Notice {index + 1} of {apiNotices.response.items.length}
                  </div>
                  <Sanitized html={item.content} />
                  {item.file && <ViewerFile fileExtension={item.file_extension} fileUrl={item.file_url} />}
                  <ToggleSwitch value={selected[item.id]} onChange={handleSwitch(item.id)} />
                </div>
              ))}
          </Card.Item>
          <Card.Footer>
            <Button text="Continue" onClick={handleSubmit} disabled={!valid} loading={api.isLoading} />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default RequiredNotices;

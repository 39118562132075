export default [
  {
    order: 0,
    name: 'Welcome',
    componentName: 'Welcome'
  },
  {
    order: 1,
    name: 'Employee identity',
    componentName: 'EmployeeIdentity'
  },
  {
    order: 2,
    name: 'Training',
    componentName: 'Training'
  },
  {
    order: 3,
    name: 'Residential & mailing address',
    componentName: 'Residence'
  },
  {
    order: 4,
    name: 'Select venues',
    componentName: 'Venues'
  },
  {
    order: 5,
    name: 'Required notices',
    componentName: 'RequiredNotices'
  },
  {
    order: 6,
    name: 'Payment setup',
    componentName: 'Payment'
  },
  {
    order: 7,
    name: 'Tax withholding',
    componentName: 'TaxWithhold'
  },
  {
    order: 8,
    name: 'I9 verification',
    componentName: 'I9Verification'
  }
];

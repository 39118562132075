/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { AlreadyReadNotification, Card, Spinner, UnreadNotification } from 'components';
import { useNavigate } from 'react-router-dom';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { useDispatch } from 'react-redux';
import { setHasNotifications, setNotifications } from 'store/actions/user';
import friendlyCharacter from 'assets/images/friendlyCharacter.svg';
import { Notification, Verify } from 'iconsax-react';

type NotificationType = {
  id: number;
  title: string;
  message: string;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  type: string | null;
  image: string;
  sender: string;
  read: boolean;
};

const NotificationPage = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    api.fetchData(endpoints.GET_NOTIFICATIONS.get()).then(response => {
      if (response) {
        const hasUnread = +response.unReadNotifications > 0;

        const mappedNotifications = response.notifications.map((n: any) => ({
          id: n.id,
          title: n.title,
          message: n.message,
          sender: n.sender,
          read: n.read,
          created_at: n.created_at,
          image: n.image
        }));

        dispatch(setNotifications(mappedNotifications));
        dispatch(setHasNotifications(hasUnread));
      }
    });
  }, []);

  const unreadNotifications = notifications.filter((item: NotificationType) => !item.read);
  const alreadyReadNotifications = notifications.filter((item: NotificationType) => item.read);

  return (
    <div className="notification-page">
      <Card title="My Notifications" />
      {api.isLoading ? (
        <div className="notifications-loading">
          <Spinner />
        </div>
      ) : (
        <>
          {unreadNotifications.length > 0 && (
            <div className="unread">
              <div className="section-title">
                <span>Unread</span>
              </div>
              {unreadNotifications.map((notification: NotificationType) => (
                <UnreadNotification
                  onClick={() => {
                    navigate(`/notifications/${notification.id}`, {
                      state: notification
                    });
                  }}
                  key={notification.id}
                  title={notification.title}
                  sender={notification.sender}
                  message={notification.message}
                  created_at={notification.created_at}
                  image={notification.image}
                />
              ))}
            </div>
          )}

          {alreadyReadNotifications.length > 0 && (
            <div>
              <div className="section-title">
                <span>Already read</span>
              </div>
              {alreadyReadNotifications.map((notification: NotificationType) => (
                <AlreadyReadNotification
                  key={notification.id}
                  title={notification.title}
                  sender={notification.sender}
                  created_at={notification.created_at}
                  image={notification.image}
                  onClick={() => {
                    navigate(`/notifications/${notification.id}`, {
                      state: notification
                    });
                  }}
                />
              ))}
            </div>
          )}

          {unreadNotifications.length === 0 && alreadyReadNotifications.length === 0 && (
            <div className="no-notifications">
              <div className="empty-notifications-box">
                <div>
                  <Verify className="verify-icon" size={20} />
                  <Notification className="bell-icon" size={28} />
                </div>
                <div>
                  <span className="title">You are up to date</span>

                  <p className="text-content">There are no notifications for you to read</p>
                </div>
              </div>
              <div>
                <img src={friendlyCharacter} alt="friendly character" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationPage;

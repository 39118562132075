import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft2 } from 'iconsax-react';
import routes from 'constants/routes';
import { Sanitized } from 'components';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { useDispatch } from 'react-redux';
import { setHasNotifications } from 'store/actions/user';

const NotificationDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const notification = location.state;
  const api = useApi();

  useEffect(() => {
    const notificationId = +notification.id;
    const isRead = notification.read;

    if (!isRead) {
      api
        .fetchData(endpoints.READ_NOTIFICATION.get(), {
          items: [notificationId]
        })
        .then(r => {
          const unreadNotifications = +r?.unReadNotifications > 0;
          dispatch(setHasNotifications(unreadNotifications));
        });
    }
  }, []);

  return (
    <div className="notification-detail-page">
      <div className="back-button-container">
        <button
          className="back-button"
          onClick={() => {
            navigate(routes.NOTIFICATIONS);
          }}
        >
          <ArrowLeft2 size="20" />
          <span className="body">Back</span>
        </button>
      </div>
      <div className="title-container">
        <span className="strong">{notification.title}</span>
      </div>

      <div className="content">
        <Sanitized html={notification.message} />
      </div>
    </div>
  );
};

export default NotificationDetailPage;

import * as React from 'react';

const CheckCircle = () => (
  <svg width={65} height={66} fill="none">
    <path
      stroke="#573BFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5.417}
      d="M59.583 30.508V33a27.083 27.083 0 1 1-16.06-24.754"
    />
    <path
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5.417}
      d="M59.583 11.333 32.5 38.443l-8.125-8.124"
    />
  </svg>
);
export default CheckCircle;

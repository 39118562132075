import React from 'react';
import { ArrowLeft2 } from 'iconsax-react';

type Props = {
  onClick: () => void;
};

const BackButton = ({ onClick }: Props) => {
  return (
    <button className="back-button" onClick={onClick}>
      <ArrowLeft2 size={22} />
    </button>
  );
};

export default BackButton;

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import useStatsList from 'hooks/useStatsList';
import { getCoreRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { Table, Badge, Button, Card } from 'components';
import { format } from 'date-fns';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { removeGetParams } from 'utils/data';
import { useToast } from 'hooks/useToast';

type WarningsStatsType = {
  warning: string;
  warning_type: string;
  created_at: string;
  event_name: string;
  event_date: string;
  id: number;
  can_appeal: boolean;
};

type DataCellProps = {
  readonly info: any;
};

function DateCell({ info }: DataCellProps) {
  return <div className="event-date-cell">{format(new Date(info.renderValue()!), 'LLL dd, yy')}</div>;
}

type AppealButtonProps = {
  readonly info: any;
  readonly reload: any;
};

function AppealButton({ info, reload }: AppealButtonProps) {
  const modal = useModal();
  const apiFile = useApi();
  const apiS3 = useApi();
  const submitApi = useApi();
  const notify = useToast();

  return info.row.original.can_appeal ? (
    <Button
      text="Appeal"
      onClick={() => {
        modal.open(
          ModalsList.APPEAL_WARNING,
          async data => {
            if (data) {
              let succeeded = true;

              let fileOne = '';
              if (data.fileOne?.name) {
                if (data.fileOne?.name.length <= 255) {
                  const signedUrl = await apiFile.fetchData(endpoints.FILE_APPEAL.get(), {
                    fileName: data.fileOne.name
                  });

                  apiS3.uploadFileToS3(data.fileOne, signedUrl.extension, signedUrl.url);

                  fileOne = removeGetParams(signedUrl.url);
                } else {
                  notify('File #1 name length too long');
                  succeeded = false;
                }
              }

              let fileTwo = '';
              if (data.fileTwo?.name) {
                if (data.fileTwo?.name.length <= 255) {
                  const signedUrl = await apiFile.fetchData(endpoints.FILE_APPEAL.get(), {
                    fileName: data.fileTwo.name
                  });

                  apiS3.uploadFileToS3(data.fileTwo, signedUrl.extension, signedUrl.url);

                  fileTwo = removeGetParams(signedUrl.url);
                } else {
                  notify('File #2 name length too long');
                  succeeded = false;
                }
              }

              if (succeeded) {
                await submitApi.fetchData(endpoints.CREATE_APPEAL.get(), {
                  warningId: info.getValue(),
                  message: data.message,
                  fileOne,
                  fileTwo
                });

                notify('Your appeal has been submitted');
                reload();
              }
            }
          },
          {}
        );
      }}
    />
  ) : (
    <Badge text="Appealed" />
  );
}

const columnHelper = createColumnHelper<WarningsStatsType>();

const createColumns = (reload: any) => {
  return [
    columnHelper.accessor('warning_type', {
      header: 'Warning Type',
      cell: info => info.renderValue()
    }),
    columnHelper.accessor('warning', {
      header: 'Warning',
      cell: info => info.renderValue()
    }),
    columnHelper.accessor('created_at', {
      header: 'Created At',
      cell: info => <DateCell info={info} />
    }),
    columnHelper.accessor('event_name', {
      header: 'Event Name',
      cell: info => info.renderValue()
    }),
    columnHelper.accessor('event_date', {
      header: 'Event Date',
      cell: info => <DateCell info={info} />
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: info => <AppealButton info={info} reload={reload} />
    })
  ];
};

const WarningsPage = () => {
  const { data: user } = useSelector((state: RootState) => state.user);
  const employeeId = Number(user.employeeId);

  const { items, total, isLoading, handleLoadMore, reload } = useStatsList({
    statsPath: 'warningsStats',
    itemsKey: 'warningsStatistics',
    itemsPerPage: 10,
    employeeId
  });

  const table = useReactTable({
    data: items as any,
    columns: createColumns(reload),
    getCoreRowModel: getCoreRowModel()
  });

  const { containerRef, fetchMoreOnBottomReached } = useInfinityScroll({
    onLoadMore: handleLoadMore,
    totalDBRowCount: total,
    totalFetched: items.length,
    isFetching: isLoading
  });

  return (
    <div className="page-warnings">
      <Card title="My Warnings">
        <Card.Item>
          <Table
            config={table}
            ref={containerRef}
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            isLoading={isLoading}
          />
        </Card.Item>
      </Card>
    </div>
  );
};

export default WarningsPage;

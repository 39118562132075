import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';

const Email = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    const { email } = params.data.personal;
    formHandler.setFullForm({ email: email });
  }, []);

  return (
    <Base
      header="Email"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Save changes" disabled={!formHandler.valid} onClick={() => onClose(formHandler.data)} />}
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default Email;

import React from 'react';

interface BadgeProps {
  text: string;
  onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({ text, onClick }) => {
  return (
    <div className="badge" onClick={onClick}>
      <span className="body">{text}</span>
    </div>
  );
};

export default Badge;

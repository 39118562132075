import {
  Confirm,
  DisableNotifications,
  LegalName,
  ResidentialAddress,
  SocialSecurityNumber,
  EventPreferences,
  SuccessModal,
  LeaderPositionNoAvailable,
  PhoneNumber,
  Email,
  FinishTrainingModal,
  AppealWarningModal,
  ViewAlcoholCertificateModal,
  UploadProfilePictureModal,
  RecordVideoModal,
  RequestBackgroundCheckModal
} from 'components';
import FileUploader from 'components/modals/fileUploader';
import UploadResumePicture from 'components/modals/uploadResumePicture';

export enum ModalsList {
  CONFIRM = 'CONFIRM',
  LEGAL_NAME = 'LEGAL_NAME',
  RESIDENTIAL_ADDRESS = 'RESIDENTIAL_ADDRESS',
  SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
  DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS',
  EVENT_PREFERENCES = 'EVENT_PREFERENCES',
  SUCCESS_MODAL = 'SUCCESS_MODAL',
  LEAD_POSITION_NO_AVAILABLE = 'LEAD_POSITION_NO_AVAILABLE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  FINISH_TRAINING = 'FINISH_TRAINING',
  APPEAL_WARNING = 'APPEAL_WARNING',
  TRAINING_COMPLETED = 'TRAINING_COMPLETED',
  FILE_UPLOADER = 'FILE_UPLOADER',
  UNIFORM_FILE_UPLOADER = 'UNIFORM_FILE_UPLOADER',
  VIEW_ALCOHOL_CERTIFICATE = 'VIEW_ALCOHOL_CERTIFICATE',
  UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE',
  UPLOAD_PROFILE_RESUME = 'UPLOAD_PROFILE_RESUME',
  RECORD_VIDEO = 'RECORD_VIDEO',
  REQUEST_BACKGROUND_CHECK = 'REQUEST_BACKGROUND_CHECK'
}

export const modals = {
  [ModalsList.CONFIRM]: Confirm,
  [ModalsList.LEGAL_NAME]: LegalName,
  [ModalsList.RESIDENTIAL_ADDRESS]: ResidentialAddress,
  [ModalsList.SOCIAL_SECURITY_NUMBER]: SocialSecurityNumber,
  [ModalsList.DISABLE_NOTIFICATIONS]: DisableNotifications,
  [ModalsList.EVENT_PREFERENCES]: EventPreferences,
  [ModalsList.SUCCESS_MODAL]: SuccessModal,
  [ModalsList.LEAD_POSITION_NO_AVAILABLE]: LeaderPositionNoAvailable,
  [ModalsList.PHONE_NUMBER]: PhoneNumber,
  [ModalsList.EMAIL]: Email,
  [ModalsList.FINISH_TRAINING]: FinishTrainingModal,
  [ModalsList.APPEAL_WARNING]: AppealWarningModal,
  [ModalsList.FILE_UPLOADER]: FileUploader,
  [ModalsList.VIEW_ALCOHOL_CERTIFICATE]: ViewAlcoholCertificateModal,
  [ModalsList.UPLOAD_PROFILE_PICTURE]: UploadProfilePictureModal,
  [ModalsList.UPLOAD_PROFILE_RESUME]: UploadResumePicture,
  [ModalsList.RECORD_VIDEO]: RecordVideoModal,
  [ModalsList.REQUEST_BACKGROUND_CHECK]: RequestBackgroundCheckModal
};

export type Props = {
  closing: boolean;
  onClose: (data?: any) => void;
  params: any;
};

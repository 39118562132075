import { useEffect, useState } from 'react';
import joi from 'joi';
import { objectToArray, objectMap } from 'helpers/data';

const fillDefaultData = (schema: any) => (schema ? objectMap(schema, (item: any) => item.value) : null);
const fillDefaultPristine = (schema: any) => (schema ? objectMap(schema, () => true) : null);
const fillDefaultErrors = (schema: any) => (schema ? objectMap(schema, () => '') : null);

export default (schema?: any) => {
  const [extraParams, setExtraParams] = useState<any>();
  const [form, setForm] = useState<any>({
    valid: false,
    dirtyError: false,
    data: fillDefaultData(schema),
    pristine: fillDefaultPristine(schema),
    errors: fillDefaultErrors(schema),
    schema
  });

  const resetForm = () => {
    setForm({
      valid: false,
      pristine: fillDefaultPristine(schema),
      data: fillDefaultData(schema),
      errors: fillDefaultErrors(schema),
      schema
    });
  };

  const blurred = (key: string) => () => {
    setForm((prev: any) => ({ ...prev, pristine: { ...prev.pristine, [key]: false } }));
  };

  const setSchema = (schema: any) => {
    setForm({ ...form, schema });
  };

  const setFormData = (key: string) => (value: any) => {
    setForm({
      ...form,
      data: { ...form.data, [key]: value }
    });
  };

  const setFullForm = (data: any) => {
    setForm({ ...form, data: { ...form.data, ...data } });
  };

  const setFullSchema = (schema: any) => {
    setForm({
      valid: false,
      pristine: fillDefaultPristine(schema),
      data: fillDefaultData(schema),
      errors: fillDefaultErrors(schema),
      schema
    });
  };

  const validate = () => {
    const { schema, data } = form;
    const errors: any = fillDefaultErrors(schema);
    const validator = joi
      .object(objectToArray(schema).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.joi }), {}))
      .options({ abortEarly: false });
    const { details = [] } = { ...validator.validate(data).error };
    let isFormValid = true;

    // uncomment the line bellow to check the messages key needed for custom error messages
    // console.log(details);

    for (let key of Object.keys(schema)) {
      const e = details.filter(item => item.path.includes(key));
      const hasError = e.length > 0;

      if (hasError) {
        isFormValid = false;
        errors[key] = e[0].message;
      }
    }

    setForm({ ...form, errors, valid: isFormValid });
  };

  useEffect(() => {
    if (form.data) {
      validate();
    }
  }, [form.data]);

  return {
    setExtraParams,
    setSchema,
    setFormData,
    setFullForm,
    setFullSchema,
    resetForm,
    setForm,
    extraParams,
    schema: form.schema,
    valid: form.valid,
    data: form.data,
    pristine: form.pristine,
    errors: form.errors,
    blurred
  };
};

import React from 'react';
import { default as MaskInput } from 'react-text-mask';
import classnames from 'classnames';
import { MaskedInputProps } from 'types/dynamicInputs';

const MaskedInput = ({
  label,
  value,
  onChange,
  onBlur,
  disabled,
  error,
  placeholder,
  pristine = true,
  required = true,
  mask
}: MaskedInputProps) => {
  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <MaskInput
        mask={mask}
        className="input-body"
        placeholder={placeholder}
        guide={false}
        keepCharPositions
        showMask
        id="mask-id"
        value={value}
        onChange={({ target }) => onChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default MaskedInput;

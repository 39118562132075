import joi from 'joi';
import formErrors from 'constants/formErrors';
import { zipCodeRegex } from 'constants/regex';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  lineOne: {
    value: '',
    label: 'Line One',
    limit: 150,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  lineTwo: {
    value: '',
    label: 'Line Two',
    limit: 150,
    required: false,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.any().optional()
  },
  city: {
    value: '',
    label: 'City',
    limit: 150,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  state: {
    value: '',
    options: [],
    label: 'State',
    input: InputsEnum.SELECT,
    disabled: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  postalCode: {
    value: '',
    label: 'Postal code',
    limit: 5,
    input: InputsEnum.INPUT,
    joi: joi.string().regex(zipCodeRegex).required().messages({
      'string.base': formErrors.INVALID_LENGTH,
      'string.empty': formErrors.REQUIRED,
      'string.pattern.base': formErrors.INVALID_ZIP
    })
  },
  country: {
    value: 'US',
    label: 'Country',
    limit: 150,
    disabled: true,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required()
  }
};

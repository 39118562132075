import React, { useState } from 'react';
import { Button } from 'components';
import { CommonProps, StepSequence } from '../types';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { blobToBase64, downloadFromBase64 } from 'helpers/data';

export const howDidYouHearAboutUsLabel = {
  existing_worker: 'Existing worker',
  flyer: 'Flyer',
  digital_ad: 'Digital ad',
  word_of_mouth: 'Word of mouth',
  other: 'Other'
};

const educationLabels = {
  'high-school': 'High school degree or equivalent (e.g., GED)',
  college: 'Currently enrolled in college, not graduated',
  'associate-bachelor': 'Associate / Bachelor degree',
  graduate: 'Graduate degree'
};

const Summary = ({ application, handleBack, handleContinue }: CommonProps) => {
  const [downloadingStatus, setDownloadingStatus] = useState({
    profile: false,
    video: false,
    resume: false
  });
  const summaryData = application?.summary;
  const api = useApi();

  const submit = () => {
    const transformedData = {
      currentStep: StepSequence.SUMMARY
    };

    api.fetchData(endpoints.UPDATE_APPLICATION.get(), transformedData).then(() => {
      handleContinue();
    });
  };

  const handleDownload = async (url: string, key: string) => {
    setDownloadingStatus(prevState => ({ ...prevState, [key]: true }));
    const response = await fetch(`${url}?cacheblock=true`);
    const blob = await response.blob();
    const b64 = ((await blobToBase64(blob)) as string).split(',')[1];
    const fileName = url.split('/').pop() ?? 'file';

    await downloadFromBase64(b64 as string, fileName, '');
    setDownloadingStatus(prevState => ({ ...prevState, [key]: false }));
  };
  return (
    <div className="applicant-summary">
      <h6>Form Summary</h6>
      <div className="summary-divider" />

      <div className="summary-container">
        <div className="summary-item">
          <p className="title body">State</p>
          <p className="value body">{summaryData?.['State']}</p>
        </div>

        <div className="summary-item">
          <p className="title body">Venues</p>
          {summaryData?.Venues?.map((venue, index) => (
            <p key={index} className="value body">
              {venue}
            </p>
          ))}
        </div>
        <div className="summary-divider" />

        <div className="summary-item">
          <p className="title body">Level of Education</p>
          <p className="value body">
            {educationLabels[summaryData?.['Level of Education'] as keyof typeof educationLabels]}
          </p>
        </div>
        <div className="summary-divider" />

        <div className="summary-item">
          <p className="title body">Profile Picture</p>
          <span
            className="link body"
            onClick={() => {
              const value = summaryData?.['Profile Picture'] as any;
              const url = value.url;

              handleDownload(url, 'profile');
            }}
          >
            {downloadingStatus['profile'] ? 'Downloading...' : summaryData?.['Profile Picture'].fileName}
          </span>
        </div>

        <div className="summary-item">
          <p className="title body">Video Presentation</p>
          <span
            className="link body"
            onClick={() => {
              const value = summaryData?.['Video Presentation'] as any;
              const url = value.url;

              handleDownload(url, 'video');
            }}
          >
            {downloadingStatus['video'] ? 'Downloading...' : summaryData?.['Video Presentation'].fileName}
          </span>
        </div>

        <div className="summary-item">
          <p className="title body">Resume</p>
          <span
            className="link body"
            onClick={() => {
              const value = summaryData?.Resume as any;
              const url = value.url;
              handleDownload(url, 'resume');
            }}
          >
            {downloadingStatus['resume'] ? 'Downloading...' : summaryData?.Resume.fileName}
          </span>
        </div>

        <div className="summary-item">
          <p className="title body">Bartender/Food Experience</p>
          <p className="value body">{summaryData?.['Bartender/Food Experience']}</p>
        </div>

        <div className="summary-item">
          <p className="title body">Management Experience</p>
          <p className="value body">{summaryData?.['Management Experience']}</p>
        </div>

        <div className="summary-item">
          <p className="title body">What it means to serve?</p>
          <p className="value body">{summaryData?.['What it means to serve']}</p>
        </div>

        <div className="summary-item">
          <p className="title body">How did you hear about us?</p>
          <p className="value body">
            {
              howDidYouHearAboutUsLabel[
                summaryData?.['How did you hear about us?'] as keyof typeof howDidYouHearAboutUsLabel
              ]
            }
          </p>
        </div>
      </div>
      <div className="apply-buttos">
        <Button onClick={handleBack} variant="secondary" text="Back" />
        <Button onClick={submit} text="Continue" loading={api.isLoading} />
      </div>
    </div>
  );
};

export default Summary;

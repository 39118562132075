import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';
import { getPlaneSSN, getTruncatedSSN } from 'utils/data';

const SocialSecurityNumber = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    const newSchema = {
      ...schema,
      ssnOrItin: {
        ...schema.ssnOrItin,
        placeholder: getTruncatedSSN(params.data.personal.truncatedTaxPayerNumber)
      }
    };

    formHandler.setSchema(newSchema);
  }, []);

  return (
    <Base
      header="Social Security Number"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid}
          onClick={() => onClose({ ssnOrItin: getPlaneSSN(formHandler.data.ssnOrItin) })}
        />
      }
    >
      <div className="modal-social-security-number">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default SocialSecurityNumber;

export enum LeaderRoles {
  SITE_LEAD = 0,
  AREA_LEAD = 1,
  STAND_LEAD = 2
}

export type AvailablePositions = {
  areaLeadFull: boolean;
  siteLeadFull: boolean;
  standLeadFull: boolean;
};

type PositionValue = 'siteLeadFull' | 'areaLeadFull' | 'standLeadFull';

export type PositionMapType = {
  [key in LeaderRoles]: PositionValue;
};

export const positionMap: PositionMapType = {
  [LeaderRoles.SITE_LEAD]: 'siteLeadFull',
  [LeaderRoles.AREA_LEAD]: 'areaLeadFull',
  [LeaderRoles.STAND_LEAD]: 'standLeadFull'
};

export enum RANKING {
  UNRANKED = 'Unranked',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  MOON = 'Moon'
}

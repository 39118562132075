import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import endpoints from 'constants/endpoints';
import steps from 'constants/onBorading';
import routes from 'constants/routes';
import { onBoardingCompleted } from 'store/actions/user';
import useApi from 'hooks/useApi';
import { Card } from 'components';
import * as Steps from './steps';

const OnBoardingPage = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(-1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const fetchOnBoardingStatus = async () => {
    await api.fetchData(endpoints.ON_BOARDING_STATUS.get());
  };

  const handleContinue = async () => {
    if (currentStep === steps.length - 1) {
      return;
    }
    await fetchOnBoardingStatus();
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    fetchOnBoardingStatus();
  }, []);

  useEffect(() => {
    if (api.response) {
      const data = api.response.data;

      if (data.completed) {
        dispatch(onBoardingCompleted());
        navigate(routes.HOME);
      } else {
        setCurrentStep(data.nextStep);
        setCompletedSteps(data.completedSteps);
      }
    }
  }, [api.response]);

  if (currentStep === -1) {
    return null;
  }

  return (
    <div className="page-on-boarding">
      {
        // @ts-ignore : @todo: fix ts alert
        React.createElement(Steps[steps[currentStep].componentName], { handleContinue, step: currentStep })
      }

      <Card title="Onboarding" description={`Step ${currentStep + 1} of ${steps.length + 1}`}>
        <Card.Item>
          <ul className="list-numbers">
            {steps.map((item, index) => (
              <li key={index} className={classnames({ checked: completedSteps.includes(item.order) })}>
                {item.name}
              </li>
            ))}
          </ul>
        </Card.Item>
      </Card>
    </div>
  );
};

export default OnBoardingPage;

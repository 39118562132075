import React, { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { GroupChannelContextType } from '@sendbird/uikit-react/GroupChannel/context';
import { Button } from 'components';
import { ArrowLeft2 } from 'iconsax-react';
import { setChannelUrl } from 'store/actions/user';

type MobileChannelHeaderProps = {
  context: GroupChannelContextType;
  isOperator: boolean;
  dispatch: Dispatch<AnyAction>;
  setIsOperator: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileChannelHeader: React.FC<MobileChannelHeaderProps> = ({
  context,
  isOperator,
  dispatch,
  setIsOperator,
  setShowSettings
}) => {
  return (
    <div className="channel-header">
      <div className="channel-back">
        <Button
          text="Back"
          variant="alternative"
          icon={<ArrowLeft2 />}
          onClick={() => {
            dispatch(setChannelUrl(''));
            setIsOperator(false);
          }}
        />
      </div>
      <div className="channel-title">
        <span className="channel-name body">{context.currentChannel?.name}</span>
        <span className="channel-member-count body-2">{context.currentChannel?.memberCount} members</span>
      </div>
      <div
        className="channel-avatar"
        onClick={() => {
          if (isOperator) {
            setShowSettings(true);
          }
        }}
      >
        <img src={context.currentChannel?.coverUrl} alt="avatar" />
      </div>
    </div>
  );
};

export default MobileChannelHeader;

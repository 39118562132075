import { createColumnHelper } from '@tanstack/react-table';

export type StandStatsType = {
  standName: string;
  quantity: number;
};

const columnHelper = createColumnHelper<StandStatsType>();

export const columns = [
  columnHelper.accessor('standName', {
    header: 'Stand',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    cell: info => info.renderValue()
  })
];

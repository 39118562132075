import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';

const ResidentialAddress = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  useEffect(() => {
    formHandler.setFullForm({
      lineOne: params.data.personal.residentialAddress.line_one,
      lineTwo: params.data.personal.residentialAddress.line_two,
      city: params.data.personal.residentialAddress.city,
      state: String(params.data.personal.residentialAddress.addressState.id),
      postalCode: params.data.personal.residentialAddress.postal_code
    });
  }, []);

  useEffect(() => {
    if (api.response) {
      const options = api.response.items.map(({ id: value, name: label }: any) => ({ value, label }));
      const newSchema = { ...schema, state: { ...schema.state, options, disabled: false } };

      formHandler.setSchema(newSchema);
    }
  }, [api.response]);

  useEffect(() => {
    api.fetchData(endpoints.VALID_STATES.get());
  }, []);

  return (
    <Base
      header="Residential address"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid}
          onClick={() => onClose({ residence: formHandler.data })}
        />
      }
    >
      <div className="modal-residential-address">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default ResidentialAddress;

import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import { Card } from 'components';
import useApi from 'hooks/useApi';

const MyPaymentMethod = () => {
  const api = useApi();
  const url = api?.response?.url;

  useEffect(() => {
    api.fetchData(endpoints.GET_PAYMENT_URL.get());
  }, []);

  return (
    <div className="page-my-payment-method">
      <Card title="My Payment Method">
        <Card.Item>{!!url && <iframe id="payment-iframe" title="Payment" src={url} />}</Card.Item>
      </Card>
    </div>
  );
};

export default MyPaymentMethod;

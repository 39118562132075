import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, Card, Wizard } from 'components';

const TrainingWizard = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  return (
    <div className="training-wizard">
      <Card title="Trainings" leftElement={<BackButton onClick={() => navigate(-1)} />} />
      <Wizard trainingId={params.id!} page="MY-TRAINING" />
    </div>
  );
};

export default TrainingWizard;

import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  venue: {
    value: {
      state: '',
      venues: []
    },
    label: 'Select the venue(s) closest to you',
    input: InputsEnum.VENUE_SELECTOR,
    joi: joi
      .object({
        state: joi.string().required(),
        venues: joi.array().items(joi.string()).min(1).required()
      })
      .required()
  }
};

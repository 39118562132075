import joi from 'joi';
import formErrors from 'constants/formErrors';
import { ChoiceQuestionItem, FormItem, FormResponse } from 'types/googleForm';
import { InputsEnum } from 'types/dynamicInputs';

const stringSchema = joi.string().required().messages({
  'string.empty': formErrors.REQUIRED
});
const arraySchema = joi.array().min(1).required().messages({
  'array.min': formErrors.REQUIRED
});
const dateSchema = joi.date().iso().required().messages({
  'date.empty': formErrors.REQUIRED,
  'date.base': formErrors.REQUIRED,
  'date.format': formErrors.INVALID_DATE
});
const optionalSchema = joi.optional();

const choiceInputMap = {
  CHECKBOX: InputsEnum.CHECK_GROUP,
  RADIO: InputsEnum.RADIO_GROUP,
  DROP_DOWN: InputsEnum.SELECT
};

const getInputType = (item: FormItem): InputsEnum | undefined => {
  if (item.pageBreakItem) {
    return InputsEnum.FORM_SECTION;
  }

  const question = item.questionItem?.question;
  if (question?.textQuestion) {
    return InputsEnum.INPUT;
  }

  if (question?.dateQuestion) {
    return InputsEnum.DATE;
  }

  if (question?.timeQuestion) {
    return InputsEnum.TIME_PICKER;
  }

  if (question?.choiceQuestion) {
    return choiceInputMap[question.choiceQuestion.type];
  }
};

const getChoiceOptions = (item: ChoiceQuestionItem) => {
  const choiceQuestion = item.questionItem?.question.choiceQuestion;
  return choiceQuestion?.options.map(option => ({ label: option.value, value: option.value }));
};

const getJoiSchema = (item: FormItem) => {
  if (item.pageBreakItem) {
    return optionalSchema;
  }

  const question = item.questionItem?.question;
  if (question?.textQuestion || question?.choiceQuestion?.type === 'DROP_DOWN') {
    return stringSchema;
  }

  if (question?.dateQuestion || question?.timeQuestion) {
    return dateSchema;
  }

  return arraySchema;
};

export const createSchemaFromGoogleForm = (formResponse: FormResponse) => {
  return Object.fromEntries(
    formResponse.items.map((item: any, index) => {
      return [
        index,
        {
          label: item.title,
          value: '',
          input: getInputType(item),
          options: getChoiceOptions(item),
          joi: getJoiSchema(item),
          description: item?.description || '',
          questionId: `#${item.questionNumber}:`,
          isQuiz: true,
          wrongAnswer: false
        }
      ];
    })
  );
};

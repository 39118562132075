import React from 'react';
import { GroupChannelContextType } from '@sendbird/uikit-react/GroupChannel/context';
import { InfoCircle } from 'iconsax-react';

type DesktopChannelHeaderProps = {
  context: GroupChannelContextType;
  isOperator: boolean;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
};

const DesktopChannelHeader = ({ context, isOperator, setShowSettings }: DesktopChannelHeaderProps) => {
  return (
    <div className="channel-header">
      <div className="channel-header-container">
        <div className="channel-title">
          <div className="channel-avatar">
            <img src={context.currentChannel?.coverUrl} alt="avatar" />
          </div>
          <span className="channel-name body">{context.currentChannel?.name}</span>
        </div>
        {isOperator && (
          <button
            onClick={() => {
              if (isOperator) {
                setShowSettings(true);
              }
            }}
          >
            <InfoCircle className="cursor-pointer" color="#573bff" />
          </button>
        )}
      </div>
    </div>
  );
};

export default DesktopChannelHeader;

import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import { allowedFileTypes } from 'constants/fileTypes';

export default {
  message: {
    value: '',
    label: 'Message',
    limit: 255,
    input: InputsEnum.TEXTAREA,
    type: TextInputTypes.TEXT,
    joi: joi.string().max(255).required().messages({
      'string.pattern.invert.base': formErrors.REQUIRED,
      'string.max': formErrors.INVALID_LENGTH
    })
  },
  fileOne: {
    value: {},
    label: 'File #1 (Optional)',
    disabled: false,
    input: InputsEnum.FILE_INPUT,
    joi: joi
      .object({
        name: joi.string(),
        type: joi
          .string()
          .valid(...allowedFileTypes)
          .required(),
        size: joi.number()
      })
      .empty({})
      .messages({
        'any.required': formErrors.REQUIRED,
        'any.only': formErrors.INVALID_EXTENSION
      })
  },
  fileTwo: {
    value: {},
    label: 'File #2 (Optional)',
    disabled: false,
    input: InputsEnum.FILE_INPUT,
    joi: joi
      .object({
        name: joi.string(),
        type: joi
          .string()
          .valid(...allowedFileTypes)
          .required(),
        size: joi.number()
      })
      .empty({})
      .messages({
        'any.required': formErrors.REQUIRED,
        'any.only': formErrors.INVALID_EXTENSION
      })
  }
};

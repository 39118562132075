import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';

const DisableNotificationsModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" onClick={() => onClose(false)} variant="secondary" capitalize />
          <Button text="Confirm" onClick={() => onClose(true)} capitalize />
        </>
      }
    >
      <div>
        Are you sure you want to disable notifications? We recommend that you do not do it for a correct use of the app
      </div>
    </Base>
  );
};

export default DisableNotificationsModal;

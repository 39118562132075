import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { SafeAreaController } from '@aashu-dubey/capacitor-statusbar-safe-area';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'sonner';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import useWindowSize from '@revolt-digital/use-window-size';
import { createBrowserHistory } from 'history';
import { Platform } from 'constants/platforms';
import ModalProvider from 'hooks/useModal';
import RefsProvider from 'hooks/useRefs';
import { Toolbar } from 'components';
import { persistor, store } from 'store';
import Router from './router';

const customHistory = createBrowserHistory();

const Content = () => {
  const windowSize = useWindowSize();

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [windowSize.height]);

  useEffect(() => {
    if (Capacitor.getPlatform() === Platform.IOS) {
      SafeAreaController.injectCSSVariables();
    }
  }, []);

  return (
    <HistoryRouter history={customHistory as any}>
      <Toaster theme="dark" position="top-center" />
      <div className="app">
        <Toolbar />
        <div className="page">
          <Router />
        </div>
      </div>
    </HistoryRouter>
  );
};

const App = () => (
  <RefsProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ModalProvider>
          <Content />
        </ModalProvider>
      </PersistGate>
    </Provider>
  </RefsProvider>
);

export default App;

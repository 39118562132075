import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { login, setHasNotifications } from 'store/actions/user';
import { forms } from 'types/signIn';
import { ChangePassword, EmailVerification, ForgotPassword, Login, Register, VerifyYourEmail } from './forms';
import { changePassSchema, forgotPassSchema, loginSchema, registerSchema, emailVerificationSchema } from './schemas';
import { APP_VERSION } from 'constants/appVersion';
import { Capacitor } from '@capacitor/core';
import { Platform } from 'constants/platforms';
import classNames from 'classnames';

const SignInPage = () => {
  const [searchParams] = useSearchParams();
  const [currentForm, setCurrentForm] = useState(forms.LOGIN);
  const api = useApi();
  const dispatch = useDispatch();
  const loginFormHandler = useFormHandler(loginSchema);
  const registerFormHandler = useFormHandler(registerSchema);
  const forgotPassFormHandler = useFormHandler(forgotPassSchema);
  const changePasswordFormHandler = useFormHandler(changePassSchema);
  const emailVerificationFormHandler = useFormHandler(emailVerificationSchema);

  const IOS = Capacitor.getPlatform() === Platform.IOS;

  const handleLogin = () => {
    api.fetchData(endpoints.LOGIN.get(), loginFormHandler.data);
  };

  const handleRegister = async () => {
    const data = {
      ...registerFormHandler.data,
      phone: registerFormHandler.data.phone.replace(/[^\d+]/g, '')
    };
    await api.fetchData(endpoints.REGISTER.get(), data);
    registerFormHandler.resetForm();
  };

  const handleForgotPass = async () => {
    await api.fetchData(endpoints.RESET_PASSWORD.get(), forgotPassFormHandler.data);
  };

  const handleChangePassword = () => {
    const token = searchParams.get('reset_password');
    api.fetchData(endpoints.CONFIRM_RESET_PASSWORD.get(), { token, ...changePasswordFormHandler.data });
  };

  const handleResendEmail4Verification = () => {
    api.fetchData(endpoints.RESEND_EMAIL_FOR_VERIFICATION.get(), emailVerificationFormHandler.data);
  };

  const handleSetCurrentForm = (moveTo: forms) => () => {
    !api.isLoading && setCurrentForm(moveTo);
  };

  const onLogin = () => {
    const { token, refreshToken, data, isApplicationCompleted, onBoardingCompleted, unReadNotifications } =
      api.response;
    const hasNotifications = +unReadNotifications > 0;

    if (data.unverified) {
      emailVerificationFormHandler.setFormData('email')(loginFormHandler.data.email);
      setCurrentForm(forms.EMAIL_VERIFICATION);
    } else {
      dispatch(login(data, token, refreshToken, onBoardingCompleted, isApplicationCompleted));
      dispatch(setHasNotifications(hasNotifications));
    }
  };

  const onSuccessfulRegister = () => {
    registerFormHandler.resetForm();
    setCurrentForm(forms.VERIFY_YOUR_EMAIL);
  };

  const onSuccessfulForgotPass = () => {
    forgotPassFormHandler.resetForm();
    setCurrentForm(forms.VERIFY_YOUR_EMAIL);
  };

  useEffect(() => {
    if (api.response) {
      switch (currentForm) {
        case forms.LOGIN:
        case forms.CHANGE_PASSWORD:
          onLogin();
          break;
        case forms.REGISTER:
          api.response.registered && onSuccessfulRegister();
          break;
        case forms.EMAIL_VERIFICATION:
        case forms.FORGOT_PASS:
          api.response.success && onSuccessfulForgotPass();
      }
    }
  }, [api.response]);

  useEffect(() => {
    const token = searchParams.get('verify');

    if (token) {
      api.fetchData(endpoints.VERIFY.get(), { token });
    } else {
      const isResetPassword = searchParams.get('reset_password');
      isResetPassword && setCurrentForm(forms.CHANGE_PASSWORD);
    }
  }, []);

  return (
    <div className="page-sign-in">
      <div className="signup-box">
        {
          {
            [forms.LOGIN]: (
              <Login
                loading={api.isLoading}
                formHandler={loginFormHandler}
                onSubmit={handleLogin}
                handleSetCurrentForm={handleSetCurrentForm}
              />
            ),
            [forms.REGISTER]: (
              <Register
                loading={api.isLoading}
                formHandler={registerFormHandler}
                onSubmit={handleRegister}
                handleSetCurrentForm={handleSetCurrentForm}
              />
            ),
            [forms.FORGOT_PASS]: (
              <ForgotPassword loading={api.isLoading} formHandler={forgotPassFormHandler} onSubmit={handleForgotPass} />
            ),
            [forms.CHANGE_PASSWORD]: (
              <ChangePassword
                loading={api.isLoading}
                formHandler={changePasswordFormHandler}
                onSubmit={handleChangePassword}
              />
            ),
            [forms.VERIFY_YOUR_EMAIL]: <VerifyYourEmail />,
            [forms.EMAIL_VERIFICATION]: (
              <EmailVerification
                formHandler={emailVerificationFormHandler}
                loading={api.isLoading}
                onSubmit={handleResendEmail4Verification}
              />
            )
          }[currentForm]
        }

        {![forms.FORGOT_PASS, forms.EMAIL_VERIFICATION, forms.VERIFY_YOUR_EMAIL, forms.CHANGE_PASSWORD].includes(
          currentForm
        ) ? (
          <span className="body link" onClick={handleSetCurrentForm(forms.FORGOT_PASS)}>
            Forgot your password?
          </span>
        ) : (
          <span className="body link" onClick={handleSetCurrentForm(forms.LOGIN)}>
            Go to login
          </span>
        )}
      </div>
      <small
        className={classNames('app-version', {
          ios: IOS
        })}
      >
        Version {APP_VERSION}
      </small>
    </div>
  );
};

export default SignInPage;

export const logoutMessage = {
  title: 'Logout',
  message: 'Are you sure you want to logout?'
};

export const confirmDropEventMessage = {
  title: 'Are you sure you wish to continue?',
  message:
    'You will receive a <span style="color: red;">written warning</span> for calling out of this shift per our Call-Out policy. You are allowed 2 call-outs before you will be terminated.'
};

import React from 'react';
import GroupChannelList from '@sendbird/uikit-react/GroupChannelList';
import { SendbirdChatType } from '@sendbird/uikit-react/types/lib/types';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import ChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import { GroupChannelContextType } from '@sendbird/uikit-react/GroupChannel/context';
import { Send2 } from 'iconsax-react';
import { AnyAction, Dispatch } from 'redux';

import { setChannelUrl } from 'store/actions/user';
import { Spinner } from 'components';
import DesktopChannelHeader from './desktopChannelHeader';
import ChannelPreview from '../channelPreview';
import ChannelListHeader from '../channelListHeader';

type DesktopChannelListProps = {
  sdkInstance: SendbirdChatType;
  channelUrl: string;
  context: GroupChannelContextType;
  dispatch: Dispatch<AnyAction>;
  showSettings: boolean;
  isOperator: boolean;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOperator: React.Dispatch<React.SetStateAction<boolean>>;
  checkIsOperator: (channelUrl: string) => void;
};

const DesktopChannelList: React.FC<DesktopChannelListProps> = ({
  sdkInstance,
  channelUrl,
  context,
  showSettings,
  isOperator,
  dispatch,
  setShowSettings,
  checkIsOperator
}) => {
  return (
    <>
      <GroupChannelList
        disableAutoSelect={!!channelUrl}
        onChannelCreated={() => null}
        onChannelSelect={channel => {
          checkIsOperator(channel?.url!);
          dispatch(setChannelUrl(channel?.url!));
          setShowSettings(false);
        }}
        renderHeader={() => (
          <ChannelListHeader
            isMobile={false}
            profileUrl={sdkInstance.currentUser?.profileUrl!}
            nickname={sdkInstance.currentUser?.nickname!}
          />
        )}
        renderChannelPreview={channel => <ChannelPreview {...channel} isMobile />}
      />

      {channelUrl &&
        (context.loading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            {!showSettings ? (
              <GroupChannel
                channelUrl={channelUrl}
                renderChannelHeader={() => (
                  <DesktopChannelHeader context={context} isOperator={isOperator} setShowSettings={setShowSettings} />
                )}
                renderSendMessageIcon={() => <Send2 />}
              />
            ) : (
              <ChannelSettings
                className="custom-channel-settings"
                channelUrl={channelUrl}
                onCloseClick={() => setShowSettings(false)}
              />
            )}
          </>
        ))}
    </>
  );
};

export default DesktopChannelList;

import React from 'react';
import classNames from 'classnames';
import { TextareaProps } from 'types/dynamicInputs';

const AlternativeTextArea = ({ label, value, name, limit, disabled, onChange }: TextareaProps) => (
  <div className={classNames('alternative-text-area', { disabled })}>
    <label className="text-area-label">{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={({ target }) => onChange(target.value, target.name)}
      disabled={disabled}
    />
    {value?.length > limit! && (
      <small className="body-2 text-limit">You have reached the limit of {limit} characters</small>
    )}
  </div>
);

export default AlternativeTextArea;

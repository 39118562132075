import React, { cloneElement, useState, ReactNode } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';

type Props = {
  data: { title: string; component: ReactNode }[];
  forceRenderTabPanel?: boolean;
  defaultIndex?: number;
};

const TabsSwitcher = ({ data, forceRenderTabPanel, defaultIndex = 0 }: Props) => {
  const [selected, setSelected] = useState(defaultIndex);

  const handleSelected = (index: number) => {
    setSelected(index);
  };

  return (
    <Tabs forceRenderTabPanel={forceRenderTabPanel} defaultIndex={defaultIndex} onSelect={handleSelected}>
      <TabList>
        {data.map(({ title }: any, index: number) => (
          <Tab key={index}>{title}</Tab>
        ))}
      </TabList>

      {data.map(({ component }: any, index: number) => (
        <TabPanel key={index}>{cloneElement(component, { focus: index === selected })}</TabPanel>
      ))}
    </Tabs>
  );
};

export default TabsSwitcher;

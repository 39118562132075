import joi from 'joi';
import formErrors from 'constants/formErrors';
import { nameRegex } from 'constants/regex';
import { blockNonAlphaCharacters } from 'helpers/keyBlockers';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  legalName: {
    value: '',
    label: 'Legal Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  }
};

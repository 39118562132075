import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import endpoints from 'constants/endpoints';
import { dateFormat, dateTimeFormat } from 'constants/dates';
import { downloadFromBase64 } from 'helpers/data';
import { utcDate } from 'helpers/date';
import useApi from 'hooks/useApi';
import { Button, Card } from 'components';

type Props = {
  event: string;
  eventDate: string;
  paymentDate: string;
  venue: string;
  checkPayrollId: string;
};

const PaymentCard = ({ event, eventDate, paymentDate, venue, checkPayrollId }: Props) => {
  const [downloading, setDownloading] = useState(false);
  const api = useApi();

  const handleDownload = () => {
    api.fetchData(endpoints.DOWNLOAD_PAYMENT.get({ id: checkPayrollId }));
  };

  useEffect(() => {
    if (api.response) {
      setDownloading(true);
      downloadFromBase64(api.response.pdf, `payment_${eventDate}`).then(() => {
        setDownloading(false);
      });
    }
  }, [api.response]);

  return (
    <Card className="payment-card" title={event} description={venue}>
      <Card.Item>
        Event date: {format(utcDate(eventDate), dateFormat)}
        <br />
        Payment date: {format(utcDate(paymentDate), dateTimeFormat)}
      </Card.Item>
      <Card.Item>
        <Button text="Download" onClick={handleDownload} loading={api.isLoading} disabled={downloading} />
      </Card.Item>
    </Card>
  );
};

export default PaymentCard;

import {
  LOG_IN,
  LOG_OUT,
  SET_TOKEN,
  ON_BOARDING_COMPLETED,
  NOTICES,
  UserActionTypes,
  SET_NOTIFICATION_TOKEN,
  SET_CHANNEL_URL,
  SET_HAS_CHATS,
  HAS_NOTIFICATIONS,
  NOTIFICATIONS,
  ADD_NOTIFICATION,
  SET_APPLICATION_COMPLETED,
  SET_BACKGROUND_CHECK_PROCESSING
} from 'types/store';

export function login(
  data: any,
  token: string,
  refreshToken: string,
  onBoardingCompleted: boolean,
  isApplicationCompleted: boolean
): UserActionTypes {
  return { type: LOG_IN, data, token, refreshToken, onBoardingCompleted, isApplicationCompleted };
}

export function logout(): UserActionTypes {
  return { type: LOG_OUT };
}

export function setToken(token: string): UserActionTypes {
  return { type: SET_TOKEN, token };
}

export function onBoardingCompleted(): UserActionTypes {
  return { type: ON_BOARDING_COMPLETED };
}

export function setApplicationCompleted(): UserActionTypes {
  return { type: SET_APPLICATION_COMPLETED };
}

export function setNotices(notices: any[]): UserActionTypes {
  return { type: NOTICES, notices };
}

export function setNotificationToken(notificationToken: string | null): UserActionTypes {
  return { type: SET_NOTIFICATION_TOKEN, notificationToken };
}

export function setChannelUrl(channelUrl?: string): UserActionTypes {
  return { type: SET_CHANNEL_URL, channelUrl };
}

export function setHasChats(hasChats: boolean): UserActionTypes {
  return { type: SET_HAS_CHATS, hasChats };
}

export function setHasNotifications(hasNotifications: boolean): UserActionTypes {
  return { type: HAS_NOTIFICATIONS, hasNotifications };
}

export function setNotifications(notifications: any[]): UserActionTypes {
  return { type: NOTIFICATIONS, notifications };
}

export const addNotification = (notification: any): UserActionTypes => ({
  type: ADD_NOTIFICATION,
  notification
});

export const setBackgroundCheckProcessing = (): UserActionTypes => {
  return { type: SET_BACKGROUND_CHECK_PROCESSING };
};

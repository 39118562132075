import classNames from 'classnames';
import React from 'react';
import { SelectorCheckboxProps } from 'types/dynamicInputs';

const RadioSelector = ({ value, label, name, onChange, options, disabled }: SelectorCheckboxProps) => {
  return (
    <div className="radio-selector">
      <div className="radio-title">{label}</div>
      <div className="radio-list">
        {options.map(level => (
          <label
            key={level.value}
            htmlFor={level.value}
            className={classNames('radio-item', {
              disabled
            })}
          >
            <span className="radio-label body">{level.label}</span>
            <input
              className="radio-button body"
              type="radio"
              id={level.value}
              name={name}
              value={level.value}
              checked={value === level.value}
              onChange={() => onChange(level.value)}
              disabled={disabled}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioSelector;

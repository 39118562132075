import React from 'react';
import { Book1, Briefcase, Location, Message, TickCircle } from 'iconsax-react';
import { StepSequence } from 'pages/applicant/steps/types';
import classNames from 'classnames';

const steps = [
  { icon: <Location />, step: StepSequence.VENUES },
  { icon: <Briefcase />, step: StepSequence.EDUCATION },
  { icon: <Book1 />, step: StepSequence.PERSONAL_INTRODUCTION },
  { icon: <Message />, step: StepSequence.HOW_DID_YOU_HEAR_ABOUT_US }
];

interface Props {
  currentStep: number;
}

const ApplicantStepper: React.FC<Props> = ({ currentStep }) => {
  return (
    <div className="applicant-stepper">
      {steps.map((item, index) => {
        const active = currentStep === item.step || currentStep > item.step;
        const completed = currentStep > item.step;

        return (
          <div
            key={index}
            className={classNames('step', {
              active,
              completed
            })}
          >
            {completed ? <TickCircle /> : item.icon}
          </div>
        );
      })}
    </div>
  );
};

export default ApplicantStepper;

import joi from 'joi';
import { emailRegex, passwordRegex, phoneNumberRegex, nameRegex } from 'constants/regex';
import formErrors from 'constants/formErrors';
import { phoneNumberMask } from 'constants/masks';
import { blockNonAlphaCharacters } from 'helpers/keyBlockers';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export const loginSchema = {
  email: {
    value: '',
    label: 'Email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  },
  password: {
    value: '',
    label: 'Password',
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
export const registerSchema = {
  firstName: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  },
  lastName: {
    value: '',
    label: 'Last name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  },
  email: {
    value: '',
    label: 'Email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  },
  birthDate: {
    value: '',
    label: 'Birth date',
    input: InputsEnum.INPUT,
    type: TextInputTypes.DATE,
    joi: joi.date().required().messages({
      'date.base': formErrors.INVALID_DATE,
      'any.required': formErrors.REQUIRED
    })
  },
  phone: {
    value: '',
    label: 'Phone number',
    required: true,
    input: InputsEnum.MASKED,
    mask: phoneNumberMask,
    joi: joi.string().regex(phoneNumberRegex).required().messages({
      'string.base': formErrors.INVALID_NUMBER,
      'string.empty': formErrors.REQUIRED,
      'string.length': formErrors.INVALID_LENGTH,
      'string.pattern.base': formErrors.INVALID_LENGTH
    })
  },
  password: {
    value: '',
    label: 'Password',
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.string().regex(passwordRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_PASSWORD,
      'string.empty': formErrors.REQUIRED,
      'any.required': formErrors.REQUIRED
    })
  },
  passwordConfirm: {
    value: '',
    label: 'Password confirmation',
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.any().equal(joi.ref('password')).required().messages({
      'any.only': formErrors.PASSWORD_MUST_MATCH
    })
  }
};

export const forgotPassSchema = {
  email: {
    value: '',
    label: 'Email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  }
};

export const changePassSchema = {
  password: {
    value: '',
    label: 'Password',
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.string().regex(passwordRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_PASSWORD,
      'string.empty': formErrors.REQUIRED,
      'any.required': formErrors.REQUIRED
    })
  },
  passwordConfirm: {
    value: '',
    label: 'Password confirmation',
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.any().equal(joi.ref('password')).required().messages({
      'any.only': formErrors.PASSWORD_MUST_MATCH
    })
  }
};

export const emailVerificationSchema = {
  email: {
    value: '',
    label: 'Current email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  }
};

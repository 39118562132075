import React, { ChangeEvent } from 'react';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
};

const Checkbox = ({ onChange, label, value, checked = false, disabled = false }: Props) => (
  <div className="checkbox-wrapper">
    <input
      className="checkbox"
      onChange={onChange}
      type="checkbox"
      id={label}
      value={value}
      checked={checked}
      disabled={disabled}
    />
    {!!label && (
      <label className="body" htmlFor={label}>
        {label}
      </label>
    )}
  </div>
);

export default Checkbox;

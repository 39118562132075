import React, { useEffect, useState } from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import { useNavigate } from 'react-router-dom';
import { CloseSquare, HambergerMenu, Notification } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'constants/routes';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';
import { logoutMessage } from 'constants/messages';
import { useModal } from 'hooks/useModal';
import { useRefs } from 'hooks/useRefs';
import { Button, DropdownButton, Logo, MenuMobile } from 'components';
import { logout } from 'store/actions/user';
import { RootState } from 'types/store';
import { ModalsList } from 'types/modal';

const Toolbar = () => {
  const { extraButtonsRef } = useRefs();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const window = useWindowSize();
  const navigate = useNavigate();
  const { data, onBoardingCompleted, isLogged, hasChats, hasNotifications } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const modal = useModal();

  const handleLogout = () => {
    modal.open(
      ModalsList.CONFIRM,
      flag => {
        flag && dispatch(logout());
      },
      logoutMessage
    );
  };

  const handleOpenMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleGoHome = () => {
    navigate(routes.HOME);
    setMobileMenuOpen(false);
  };

  const menu = [
    {
      text: 'Home',
      onClick: () => navigate(routes.HOME),
      disabled: data.suspended
    },
    {
      text: 'Staff Passport',
      onClick: () => navigate(routes.STAFF_PASSPORT),
      disabled: !onBoardingCompleted || data.suspended
    },
    {
      text: 'My Profile',
      onClick: () => navigate(routes.MY_PROFILE),
      disabled: !onBoardingCompleted
    },
    {
      text: 'My Chats',
      onClick: () => navigate(routes.MY_CHATS),
      disabled: !onBoardingCompleted || data.suspended || !hasChats
    },
    {
      text: 'My Trainings',
      onClick: () => navigate(routes.MY_TRAININGS),
      disabled: !onBoardingCompleted || data.suspended
    },
    {
      text: 'Payments',
      onClick: () => navigate(routes.PAYMENTS),
      disabled: !onBoardingCompleted
    },
    {
      text: 'My Payment Method',
      onClick: () => navigate(routes.MY_PAYMENT_METHOD),
      disabled: !onBoardingCompleted
    },
    {
      text: 'My Notices',
      onClick: () => navigate(routes.MY_NOTICES_ACCEPTED),
      disabled: !onBoardingCompleted || data.suspended
    },
    {
      text: 'My Venues',
      onClick: () => navigate(routes.MY_VENUES),
      disabled: !onBoardingCompleted || data.suspended
    },
    {
      text: 'My Stats',
      onClick: () => navigate(routes.MY_STATS),
      disabled: !onBoardingCompleted || data.suspended
    },
    {
      text: 'My Warnings',
      onClick: () => navigate(routes.MY_WARNINGS),
      disabled: !onBoardingCompleted
    },
    {
      text: 'Logout',
      onClick: handleLogout
    }
  ];

  useEffect(() => {
    if (mobileMenuOpen && window.width > MOBILE_BREAKPOINT) {
      setMobileMenuOpen(false);
    }
  }, [window.width]);

  return (
    <div className="toolbar">
      <div className="content">
        <div onClick={handleGoHome} className="logo-link">
          <Logo />
        </div>

        <div>
          <div className="extra-buttons-slot" ref={extraButtonsRef} />
          {isLogged && onBoardingCompleted && !data.suspended && (
            <Button
              variant="alternative"
              icon={
                <div className={`toolbar-notification ${hasNotifications ? 'has-notifications' : ''}`}>
                  <Notification />
                </div>
              }
              onClick={() => {
                navigate(routes.NOTIFICATIONS);
                setMobileMenuOpen(false);
              }}
            />
          )}

          {isLogged && (
            <div>
              {window.width > MOBILE_BREAKPOINT ? (
                <DropdownButton
                  align="end"
                  offsetY={8}
                  items={menu}
                  button={<Button fake variant="alternative" icon={<HambergerMenu />} />}
                />
              ) : (
                <Button
                  variant="alternative"
                  icon={mobileMenuOpen ? <CloseSquare /> : <HambergerMenu />}
                  onClick={handleOpenMobileMenu}
                />
              )}
            </div>
          )}
        </div>

        <MenuMobile open={mobileMenuOpen} onClose={handleCloseMobileMenu} />
      </div>
    </div>
  );
};

export default Toolbar;

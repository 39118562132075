import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';
import formErrors from 'constants/formErrors';

export default {
  positionPreference: {
    value: null,
    label: 'Is there a role that you prefer?',
    options: [],
    input: InputsEnum.ASYNC_SELECT,
    joi: joi.number().required().messages({
      'any.required': formErrors.REQUIRED,
      'number.base': formErrors.REQUIRED
    })
  },
  employeePreference: {
    value: null,
    label: 'Is there an employee from E2S that you would like to work with?',
    input: InputsEnum.ASYNC_SELECT,
    searchable: true,
    options: [],
    shouldScrollToBottom: true,
    joi: joi.any().optional()
  },
  standPreference: {
    value: null,
    label: 'Is there a stand that you prefer?',
    input: InputsEnum.ASYNC_SELECT,
    options: [],
    shouldScrollToBottom: true,
    joi: joi.any().optional()
  }
};

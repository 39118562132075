import React from 'react';
import ReactCrop from 'react-image-crop';
import useWindowSize from '@revolt-digital/use-window-size';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import { base64ToFile, generateFilename } from 'helpers/data';
import useImageCrop from 'hooks/useImageCrop';

const UploadResumePicture = ({ closing, onClose, params }: Props) => {
  const { crop, setCrop, setCompletedCrop, imgRef, onImageLoad, getCroppedImg } = useImageCrop();
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const handleFileImage = async () => {
    const croppedImageUrl = getCroppedImg();
    const filename = generateFilename('profile', 'jpg');
    const file = await base64ToFile(croppedImageUrl!, filename);

    onClose(file);
  };

  return (
    <Base
      header="Upload Picture"
      closing={closing}
      onClose={onClose}
      size={isMobile ? 'lg' : 'md'}
      footer={<Button text="Save changes" onClick={handleFileImage} />}
    >
      <div className="resume-profile-crop">
        <ReactCrop
          circularCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={c => setCompletedCrop(c)}
          locked
        >
          <img ref={imgRef} src={params.url} alt="user-profile" onLoad={onImageLoad} />
        </ReactCrop>
      </div>
    </Base>
  );
};

export default UploadResumePicture;

import { nameRegex } from 'constants/regex';

export const blockNonNumericCharacters = (event: any) => {
  const isNonNumericChar = isNaN(Number(event.key)) && event.key !== 'Delete';

  if (isNonNumericChar) {
    event.preventDefault();
  }
};

export const blockNonAlphaCharacters = (event: any) => {
  const isNonAlphaChar = !nameRegex.test(event.key) && event.key !== 'Delete';

  if (isNonAlphaChar) {
    event.preventDefault();
  }
};

import React, { Ref, forwardRef } from 'react';
import classnames from 'classnames';
import DateInput from 'react-datepicker';
import { dateFormat, dateTimeFormat } from 'constants/dates';
import { DateInputTypes, DatePickerProps } from 'types/dynamicInputs';

const DatePicker = forwardRef(
  (
    {
      name,
      type,
      label,
      value,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      error,
      required = true,
      pristine = true,
      disabled = false,
      allowedDates
    }: DatePickerProps,
    ref: Ref<any>
  ) => {
    const handleChange = (value: any) => {
      onChange(value, name);
    };

    return (
      <div className={classnames('input-wrapper', { error: !pristine && error })}>
        {!!label && (
          <label className="label">
            {label}
            {required ? ' *' : ''}
          </label>
        )}
        <DateInput
          ref={ref}
          wrapperClassName="date-picker"
          showPopperArrow={false}
          popperPlacement="bottom-end"
          dateFormat={type === DateInputTypes.DATE_TIME ? dateTimeFormat : dateFormat}
          showTimeSelect={type === DateInputTypes.DATE_TIME}
          selected={value}
          onSelect={() => onBlur && onBlur()}
          onChange={handleChange}
          onClickOutside={onBlur}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          disabled={disabled}
          includeDates={allowedDates!}
        />
        {!pristine && !!error && <span className="error-message body-2">{error}</span>}
      </div>
    );
  }
);

export default DatePicker;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PushNotifications } from '@capacitor/push-notifications';
import endpoints from 'constants/endpoints';
import { isPluginAvailable } from 'constants/capacitor';
import useApi from './useApi';
import { addNotification, setChannelUrl, setHasNotifications, setNotificationToken } from 'store/actions/user';
import { RootState } from 'types/store';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const usePushNotifications = (isLogged: boolean) => {
  const api = useApi();
  const dispatch = useDispatch();
  const { notificationToken } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isPluginAvailable && isLogged) {
      let isCancelled = false;

      const handleRegistration = async (token: { value: string }) => {
        if (isCancelled) return;

        if (token.value !== notificationToken) {
          await api.fetchData(endpoints.SAVE_NOTIFICATION_TOKEN.get(), { token: token.value });
          dispatch(setNotificationToken(token.value));
        }
      };

      PushNotifications.addListener('registration', handleRegistration);

      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        const senbirdChannelId = notification.notification.data.sendbird_channel_id;

        if (senbirdChannelId) {
          dispatch(setChannelUrl(senbirdChannelId));
          navigate(routes.MY_CHATS);
        }

        if (notification.notification.data.notificationCenter === 'true') {
          navigate(routes.NOTIFICATIONS);
        }
      });

      // Listener for notifications received in foreground
      PushNotifications.addListener('pushNotificationReceived', notification => {
        if (notification.data.notificationCenter === 'true') {
          dispatch(
            addNotification({
              id: notification.data.id,
              title: notification.title,
              message: notification.data.html,
              sender: notification.data.sender,
              read: false,
              created_at: new Date().toISOString(),
              image: notification.data.image
            })
          );

          dispatch(setHasNotifications(true));
        }
      });

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        await PushNotifications.register();
      };

      registerNotifications();

      return () => {
        isCancelled = true;
        PushNotifications.removeAllListeners();
      };
    }
  }, []);

  return {};
};

export default usePushNotifications;

import React from 'react';
import ApprovedQuiz from '../approvedQuiz';

const QuizCompleted = () => {
  return (
    <div className="quiz-completed">
      <div className="content">
        <ApprovedQuiz />
        <h6>Congratulations, this quiz was successfully completed</h6>
      </div>
    </div>
  );
};

export default QuizCompleted;

import React from 'react';
import { Button } from 'components';
import { Link } from 'iconsax-react';

type Props = {
  data: any[];
  isMultiple: boolean;
  onOpenDocument?: () => void;
};

const AlcoholInstructions = ({ data, isMultiple, onOpenDocument }: Props) => {
  return (
    <>
      {isMultiple ? (
        <div className="alcohol-instructions">
          {data?.map((item: any, index: number) => (
            <div key={index}>
              <a className="body-2" href={item.url} target="_blank" rel="noreferrer">
                {item.name}
              </a>
            </div>
          ))}
        </div>
      ) : (
        <div className="actions-width">
          <Button icon={<Link />} text="Show" variant="alternative" small onClick={onOpenDocument} />
        </div>
      )}
    </>
  );
};

export default AlcoholInstructions;

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { TextInputProps, TextInputTypes } from 'types/dynamicInputs';
import classNames from 'classnames';

const Text = ({
  type,
  placeholder,
  label,
  name,
  value,
  onChange,
  onBlur,
  autoComplete,
  limit,
  error,
  keyBlocker,
  required = true,
  pristine = true,
  disabled = false,
  isQuiz,
  questionId,
  wrongAnswer
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: any, name: string) => {
    if (type === TextInputTypes.EMAIL) {
      value = value.toLowerCase();
    }

    onChange(value, name);
  };

  useEffect(() => {
    const inputElement = inputRef.current;

    keyBlocker && inputElement && inputElement.addEventListener('keypress', keyBlocker);

    return () => {
      keyBlocker && inputElement && inputElement.removeEventListener('keypress', keyBlocker);
    };
  }, []);

  return (
    <div className={classnames('input-wrapper', { error: !pristine && error, isQuiz })}>
      {!!label && (
        <div
          className={classNames('label-content', {
            'wrong-answer': wrongAnswer
          })}
        >
          {questionId && <span className="question-id">{questionId}</span>}
          <label className="label">
            {label}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      <input
        className="input-body"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={({ target }) => handleChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={limit}
        ref={inputRef}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default Text;

import React, { useEffect } from 'react';

export const useOutsideClick = (callback: () => void, refs: React.RefObject<any>[]) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (refs.some(ref => ref.current && ref.current.contains(event.target as Node))) {
        return;
      }
      callback();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, refs]);
};

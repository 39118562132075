import React from 'react';
import classnames from 'classnames';
import { SelectProps } from 'types/dynamicInputs';
import classNames from 'classnames';

const Select = ({
  label,
  onChange,
  onBlur,
  options,
  pristine,
  error,
  value,
  disabled = false,
  isQuiz,
  questionId,
  wrongAnswer
}: SelectProps) => {
  return (
    <div className={classnames('input-wrapper', { error: !pristine && error, isQuiz })}>
      {!!label && (
        <div
          className={classNames('label-content', {
            'wrong-answer': wrongAnswer
          })}
        >
          {questionId && <span className="question-id">{questionId}</span>}
          <label className="label">{label}</label>
        </div>
      )}
      <select
        className="input-body select"
        onChange={({ target }) => onChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
      >
        {' '}
        <option key="empty" value="">
          -
        </option>
        {options?.map((selected: any) => (
          <option key={selected?.value} value={selected.value}>
            {selected.label}
          </option>
        ))}
      </select>
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default Select;

import React, { useEffect, useState } from 'react';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import useDebounce from 'hooks/useDebounce';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';

type Employee = {
  event_id: string;
  id: string;
  legal_first_name: string;
  legal_last_name: string;
  user_id: string;
  email: string;
  phone_number: string;
  has_job: boolean;
  attendance_confirmed: boolean;
  coming_from: string;
  alcohol_certification: null | string;
  employeePreference: null | string;
  positionPreference: null | string;
  standPreference: null | string;
};

type Options = {
  name: string;
  id: string;
  isFull: boolean;
};

const EventPreferences = ({ closing, onClose, params }: Props) => {
  const { id } = params;
  const api = useApi();
  const apiEmployees = useApi();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);
  const formHandler = useFormHandler(schema);

  const handleSearch = (key: string, query: string) => {
    setSearchQuery(query);
  };

  const filteredOptions = (options: Options[]) => {
    return options
      .filter(e => !e.isFull)
      .map(item => ({
        label: item.name,
        value: item.id
      }));
  };

  const excludeOwnUser = (employees: Employee[]) => {
    return employees.filter(e => e.user_id !== params.userId);
  };

  useEffect(() => {
    api.fetchData(endpoints.EVENT_PREFERENCES.get({ id }));
  }, []);

  useEffect(() => {
    apiEmployees.fetchData(endpoints.EMPLOYEES_SUBSCRIBED.get({ eventId: id, paginated: true }), {
      getFilters: true,
      sort: {
        column: 'id',
        order: 'ASC'
      },
      filter: [{ column: 'all', value: debouncedQuery }],
      page: 1,
      limit: 10
    });
  }, [debouncedQuery]);

  useEffect(() => {
    if (api.response && apiEmployees.response) {
      const positions = filteredOptions(api.response.data.positions);
      const stands = filteredOptions(api.response.data.stands);

      const employeesRegistered = excludeOwnUser(apiEmployees.response.items).map((employee: any) => ({
        label: `${employee.legal_first_name} ${employee.legal_last_name}`,
        value: +employee.id
      }));

      formHandler.setSchema({
        ...schema,
        positionPreference: { ...schema.positionPreference, options: positions },
        standPreference: { ...schema.standPreference, options: stands },
        employeePreference: { ...schema.employeePreference, options: employeesRegistered }
      });
    }
  }, [api.response, apiEmployees.response, debouncedQuery]);

  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" onClick={() => onClose(false)} variant="secondary" capitalize />
          <Button text="Continue" onClick={() => onClose(formHandler.data)} disabled={!formHandler.valid} capitalize />
        </>
      }
    >
      <DynamicForm handler={formHandler} onInputChange={handleSearch} />
    </Base>
  );
};

export default EventPreferences;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import endpoints from 'constants/endpoints';
import useApi from './useApi';
import { RootState } from 'types/store';
import { setHasChats } from 'store/actions/user';

interface Props {
  isLogged: boolean;
  onBoardingCompleted: boolean;
  isApplicationCompleted: boolean;
}

const useActiveChatsValidator = ({ isLogged, onBoardingCompleted, isApplicationCompleted }: Props) => {
  const { hasChats } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const api = useApi();
  const userActive = isLogged && onBoardingCompleted && isApplicationCompleted;

  useEffect(() => {
    if (userActive) {
      api.fetchData(endpoints.GET_ACTIVE_CHATS.get()).then(res => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          dispatch(setHasChats(true));
        }
      });
    }
  }, [isLogged]);

  return {
    hasChats,
    isLoading: api.isLoading
  };
};

export default useActiveChatsValidator;

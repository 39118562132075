import React, { useEffect, useState } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card, VenueItem } from 'components';

const MyVenuesPage = () => {
  const api = useApi();
  const apiVenues = useApi();
  const [selected, setSelected] = useState<string[]>([]);

  const handleSubmit = () => {
    api.fetchData(endpoints.UPDATE_MY_VENUES.get(), { items: selected.map(item => +item) });
  };

  const handleToggle = (id: string) => {
    if (apiVenues.isLoading || api.isLoading) {
      return;
    }

    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  useEffect(() => {
    if (apiVenues.response) {
      setSelected(apiVenues.response.selected.map((item: any) => item.venue_id));
    }
  }, [apiVenues.response]);

  useEffect(() => {
    apiVenues.fetchData(endpoints.VENUES.get());
  }, []);

  return (
    <div className="page-my-venues">
      <Card title="My venues" description="Add or remove the venues you could work.">
        <>
          <Card.Item className="venue-items">
            {!!apiVenues.response &&
              apiVenues.response.items.map((item: any, index: number) => (
                <VenueItem
                  key={index}
                  selected={selected}
                  id={item.id}
                  name={item.name}
                  address={item.addressVenue.address}
                  onToggle={handleToggle}
                />
              ))}
          </Card.Item>
          <Card.Footer>
            <Button
              text="Save changes"
              onClick={handleSubmit}
              disabled={!selected.length}
              loading={apiVenues.isLoading || api.isLoading}
            />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default MyVenuesPage;

import React, { useEffect } from 'react';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';
import { Button, DynamicForm } from 'components';
import { CommonProps, StepSequence } from '../types';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { allPropertiesHaveValue } from 'utils/data';

const Resume = ({ application, handleContinue, handleBack, updateApplication }: CommonProps) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const submit = () => {
    const transformedData = {
      currentStep: StepSequence.PERSONAL_INTRODUCTION,
      data: {
        profilePicture: formHandler.data.profilePicture.path,
        videoPresentation: formHandler.data.videoPresentation.path,
        resume: formHandler.data.resume.path,
        foodExperience: formHandler.data.foodExperience ? 'Yes' : 'No',
        managementExperience: formHandler.data.managementExperience ? 'Yes' : 'No',
        servingOthers: formHandler.data.servingOthers
      }
    };

    api.fetchData(endpoints.UPDATE_APPLICATION.get(), transformedData).then(r => {
      updateApplication?.(r.data);
      handleContinue();
    });
  };

  useEffect(() => {
    const personalIntro = application?.personalIntroduction;

    if (personalIntro) {
      const createFileObject = (file: any) => ({
        path: file?.path || '',
        fileName: file?.fileName || '',
        fileSize: file?.fileSize || ''
      });

      const {
        videoPresentation: rawVideoPresentation,
        profilePicture: rawProfilePicture,
        resume: rawResume,
        foodExperience,
        managementExperience,
        servingOthers
      } = personalIntro;

      const videoPresentation = allPropertiesHaveValue(rawVideoPresentation)
        ? createFileObject(rawVideoPresentation)
        : null;
      const profilePicture = allPropertiesHaveValue(rawVideoPresentation) ? createFileObject(rawProfilePicture) : null;
      const resume = allPropertiesHaveValue(rawVideoPresentation) ? createFileObject(rawResume) : null;

      formHandler.setFullForm({
        profilePicture,
        videoPresentation,
        resume,
        foodExperience: foodExperience === null ? null : foodExperience === 'Yes',
        managementExperience: managementExperience === null ? null : managementExperience === 'Yes',
        servingOthers
      });
    }
  }, []);

  return (
    <div>
      <DynamicForm handler={formHandler} />
      <div className="apllicant-form-footer">
        <Button onClick={handleBack} variant="secondary" text="Back" />
        <Button onClick={submit} text="Continue" loading={api.isLoading} disabled={!formHandler.valid} />
      </div>
    </div>
  );
};

export default Resume;

import { VIDEO_DURATION } from 'constants/video';
import { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';

const useVideoRecorder = () => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [videoURL, setVideoURL] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<number>(30);
  const [videoFile, setVideoFile] = useState<File | null>(null);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    setTimeLeft(VIDEO_DURATION);
    if (webcamRef.current && webcamRef.current.stream) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm'
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setCapturing(false);
    setTimeLeft(VIDEO_DURATION);
  }, [mediaRecorderRef, setCapturing]);

  const handleSave = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      const file = new File([blob], 'video.webm', { type: 'video/webm' });
      const videoURL = URL.createObjectURL(blob);

      setVideoFile(file);
      setVideoURL(videoURL);
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (capturing && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (timeLeft === 0) {
      handleStopCaptureClick();
    }
  }, [capturing, timeLeft, handleStopCaptureClick]);

  useEffect(() => {
    if (!capturing && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      const videoURL = URL.createObjectURL(blob);
      setVideoURL(videoURL);

      const file = new File([blob], 'video.webm', { type: 'video/webm' });
      setVideoFile(file);
    }
  }, [capturing, recordedChunks]);

  return {
    videoFile,
    webcamRef,
    capturing,
    videoURL,
    timeLeft,
    handleSave,
    setVideoURL,
    formatTime,
    handleStartCaptureClick,
    handleStopCaptureClick
  };
};

export default useVideoRecorder;

import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { ArrowDown2 } from 'iconsax-react';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface Option {
  label: string;
  value: string;
}

interface AsyncSelectProps {
  label?: string;
  options: Option[];
  placeholder?: string;
  shouldScrollToBottom?: boolean;
  pristine?: boolean;
  searchable?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onSearch?: (query: string) => void;
  isLoading?: boolean;
}
interface OptionsListProps {
  options: Option[];
  onSelect: (option: Option) => void;
}

const OptionsList = forwardRef<HTMLDivElement, OptionsListProps>(({ options, onSelect }, ref) => (
  <div className="options" ref={ref}>
    {options.length === 0 ? (
      <div className="empty-option">No options available</div>
    ) : (
      options.map(option => (
        <div key={option.value} className="option" onClick={() => onSelect(option)}>
          {option.label}
        </div>
      ))
    )}
  </div>
));

const AsyncSelect: React.FC<AsyncSelectProps> = ({
  label,
  placeholder = '',
  shouldScrollToBottom = false,
  options = [],
  pristine,
  searchable = false,
  error,
  onChange,
  onBlur,
  onSearch
}) => {
  const [query, setQuery] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const asyncSelectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useOutsideClick(() => setShowOptions(false), [inputRef, optionsRef]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!searchable) return;

    setQuery(event.target.value);
    if (onSearch) onSearch(event.target.value);
  };

  const handleSelect = (item: Option) => {
    setQuery(item.label);
    onChange?.(item.value);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  useEffect(() => {
    if (showOptions && shouldScrollToBottom && asyncSelectRef?.current) {
      asyncSelectRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showOptions]);

  const arrowClassNames = classNames('select-arrow', { 'animate-arrow-rotation': showOptions });

  return (
    <>
      {label && <label className="async-select-label">{label}</label>}
      <div className="async-select" ref={asyncSelectRef}>
        <input
          className="body"
          placeholder={placeholder}
          onClick={toggleOptions}
          style={searchable ? {} : { cursor: 'pointer' }}
          readOnly={!searchable}
          onBlur={onBlur}
          ref={inputRef}
          value={query}
          onChange={handleInputChange}
        />
        {!pristine && !!error && <span className="error-message body-2">{error}</span>}
        <ArrowDown2 className={arrowClassNames} size={24} />
        {showOptions && <OptionsList options={options} onSelect={handleSelect} ref={optionsRef} />}
      </div>
    </>
  );
};

export default AsyncSelect;

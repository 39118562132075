import * as React from 'react';

const Close = ({ onClick }: { onClick: () => void }) => (
  <svg width={20} height={21} onClick={onClick} fill="none">
    <path
      stroke="#535372"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6 14.601 8-8M14 14.601l-8-8"
    />
  </svg>
);
export default Close;

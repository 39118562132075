import React from 'react';

const VerifyYourEmail = () => {
  return (
    <>
      <h6>Verify your email</h6>
      <p className="body">We have sent you an email. Please check and confirm.</p>
    </>
  );
};

export default VerifyYourEmail;

import React, { useEffect, useState } from 'react';
import Select from '../select';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { Spinner } from 'components';
import { VenuSelector } from 'types/dynamicInputs';

interface State {
  id: string;
  name: string;
}

interface Venue {
  id: string;
  name: string;
  location: string;
}

const VenueSelector = ({ value, name, onChange }: VenuSelector) => {
  const apiStates = useApi();
  const apiVenues = useApi();
  const [states, setStates] = useState<{ value: string; label: string }[]>([]);
  const [venues, setVenues] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    apiStates.fetchData(endpoints.VALID_STATES.get());
  }, []);

  useEffect(() => {
    if (apiStates.response) {
      const states = apiStates.response.items.map((state: State) => ({
        value: state.id,
        label: state.name
      }));
      setStates(states);
    }
  }, [apiStates.response]);

  useEffect(() => {
    if (value.state) {
      apiVenues.fetchData(endpoints.GET_VENUE_BY_STATE.get({ stateId: value.state }));
    }
  }, [value.state]);

  useEffect(() => {
    if (apiVenues.response) {
      const venues = apiVenues.response.items.map((venue: Venue) => ({
        value: venue.id,
        label: venue.name
      }));
      setVenues(venues);
    }
  }, [apiVenues.response]);

  const handleVenueChange = (venueId: string) => {
    onChange({
      ...value,
      venues: value.venues.includes(venueId) ? value.venues.filter(id => id !== venueId) : [...value.venues, venueId]
    });
  };

  return (
    <div className="venue-selector">
      <Select
        label="State"
        value={value.state}
        onChange={stateId => {
          onChange({
            state: stateId,
            venues: ''
          });
        }}
        options={states}
      />

      <div>
        <label className="body">Select the venue(s) closest to you</label>
        {value.state ? (
          apiVenues.isLoading ? (
            <div className="loading-venues">
              <Spinner />
            </div>
          ) : (
            <div className="venues-list">
              {venues.length > 0 ? (
                venues.map(item => (
                  <div key={item.value} className="venue-element">
                    <label htmlFor={item.value} className="venue-label body">
                      {item.label}
                    </label>
                    <input
                      name={name}
                      type="checkbox"
                      id={item.value}
                      value={item.value}
                      checked={value?.venues?.includes(item.value)}
                      onChange={() => handleVenueChange(item.value)}
                      className="venue-checkbox"
                    />
                  </div>
                ))
              ) : (
                <div className="no-venues">
                  <span className="body-2">No venues available</span>
                </div>
              )}
            </div>
          )
        ) : (
          <div className="choose-state">
            <span className="body-2">Choose state to refresh the venues</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueSelector;

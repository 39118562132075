import React from 'react';
import classnames from 'classnames';

type Props = {
  full?: boolean;
  contrast?: boolean;
};

const Logo = ({ full = true, contrast = false }: Props) => (
  <svg className={classnames('logo', { full }, { contrast })} viewBox={full ? '0 0 99 69' : '0 0 99 55.9'}>
    <g clipPath="url(#logo-cp)">
      {full && (
        <>
          <path d="M0 68.8705V59.8137H6.70068V61.5863H1.94167V63.4235H6.1296V65.1961H1.94167V67.098H6.76413V68.8705H0Z" />
          <path d="M17.0686 68.8705L16.2564 66.8392H12.5L11.6877 68.8705H9.69531L13.5025 59.749H15.3046L19.1118 68.8705H17.0686ZM14.3782 62.1426L13.1979 65.0796H15.5584L14.3782 62.1426Z" />
          <path d="M25.3176 61.651V68.8705H23.3633V61.651H20.6602V59.8137H28.0207V61.651H25.3176Z" />
          <path d="M34.443 68.9999C33.0977 68.9999 31.7398 68.5212 30.6738 67.5508L31.8287 66.1405C32.6282 66.8133 33.4658 67.2403 34.481 67.2403C35.2805 67.2403 35.7628 66.9168 35.7628 66.3864V66.3605C35.7628 65.8559 35.4582 65.5971 33.9734 65.209C32.184 64.7432 31.0292 64.2386 31.0292 62.4402V62.4143C31.0292 60.7711 32.3236 59.6843 34.1384 59.6843C35.4328 59.6843 36.5369 60.0984 37.438 60.8358L36.4227 62.3367C35.6359 61.7803 34.8618 61.4439 34.113 61.4439C33.3643 61.4439 32.9708 61.7933 32.9708 62.2332V62.259C32.9708 62.8542 33.3516 63.0483 34.8871 63.4494C36.6892 63.9281 37.7045 64.5879 37.7045 66.1664V66.1923C37.7045 67.9907 36.3593 68.9999 34.443 68.9999Z" />
          <path d="M42.3989 62.686L41.0283 61.5604C41.9039 60.3442 42.7542 59.6843 44.3405 59.6843C46.2315 59.6843 47.399 60.797 47.399 62.4919C47.399 64.0057 46.6376 64.7691 45.0639 66.0112L43.6172 67.1497H47.4878V68.8705H40.9141V67.292L43.871 64.8208C44.9751 63.8893 45.4066 63.3976 45.4066 62.6472C45.4066 61.8968 44.9116 61.4698 44.2136 61.4698C43.5157 61.4698 43.0588 61.858 42.3989 62.686Z" />
          <path d="M54.3805 68.9999C53.0352 68.9999 51.6773 68.5212 50.6113 67.5508L51.7662 66.1405C52.5657 66.8133 53.4033 67.2403 54.4185 67.2403C55.218 67.2403 55.7003 66.9168 55.7003 66.3864V66.3605C55.7003 65.8559 55.3957 65.5971 53.9109 65.209C52.1215 64.7432 50.9667 64.2386 50.9667 62.4402V62.4143C50.9667 60.7711 52.2611 59.6843 54.0759 59.6843C55.3703 59.6843 56.4744 60.0984 57.3755 60.8358L56.3602 62.3367C55.5734 61.7803 54.7993 61.4439 54.0505 61.4439C53.3018 61.4439 52.9083 61.7933 52.9083 62.2332V62.259C52.9083 62.8542 53.2891 63.0483 54.8246 63.4494C56.6267 63.9281 57.642 64.5879 57.642 66.1664V66.1923C57.642 67.9907 56.2967 68.9999 54.3805 68.9999Z" />
          <path d="M61.2969 68.8705V59.8137H67.9976V61.5863H63.2386V63.4235H67.4265V65.1961H63.2386V67.098H68.061V68.8705H61.2969Z" />
          <path d="M78.3655 68.8705L77.5533 66.8392H73.7968L72.9846 68.8705H70.9922L74.7994 59.749H76.6015L80.4087 68.8705H78.3655ZM75.6751 62.1426L74.4948 65.0796H76.8553L75.6751 62.1426Z" />
          <path d="M86.6145 61.651V68.8705H84.6601V61.651H81.957V59.8137H89.3176V61.651H86.6145Z" />
          <path d="M95.7379 68.9999C94.3927 68.9999 93.0348 68.5212 91.9688 67.5508L93.1236 66.1405C93.9231 66.8133 94.7607 67.2403 95.776 67.2403C96.5755 67.2403 97.0577 66.9168 97.0577 66.3864V66.3605C97.0577 65.8559 96.7531 65.5971 95.2683 65.209C93.4789 64.7432 92.3241 64.2386 92.3241 62.4402V62.4143C92.3241 60.7711 93.6185 59.6843 95.4333 59.6843C96.7277 59.6843 97.8318 60.0984 98.7329 60.8358L97.7176 62.3367C96.9308 61.7803 96.1567 61.4439 95.4079 61.4439C94.6592 61.4439 94.2658 61.7933 94.2658 62.2332V62.259C94.2658 62.8542 94.6465 63.0483 96.1821 63.4494C97.9841 63.9281 98.9994 64.5879 98.9994 66.1664V66.1923C98.9994 67.9907 97.6542 68.9999 95.7379 68.9999Z" />
        </>
      )}
      <path d="M82.0833 22.5903C72.8699 20.1838 70.5856 19.0193 70.5856 15.4483V15.2931C70.5856 12.6537 72.946 10.5577 77.4385 10.5577C81.931 10.5577 86.5758 12.576 91.2968 15.9141L97.3883 6.90906C91.9821 2.48416 85.3575 0 77.5908 0C71.8039 0 66.9053 1.86312 63.5803 5.08475C60.2427 1.85018 55.3821 0 49.4175 0C45.7753 0 42.793 0.582224 40.2041 1.64316V1.5526H0V55.8935H79.4183C90.916 55.8935 98.9873 49.8384 98.9873 39.0478V38.8926C98.9873 29.4217 92.8958 25.4626 82.0833 22.5903ZM28.3256 45.2582H11.65V33.8466H36.7776V23.2113H11.65V12.1879H27.7418L28.5413 12.8219L29.4297 13.5464L37.3106 20.0156L38.4908 20.9859L39.4426 19.7827C43.3133 14.9308 45.5595 13.3653 48.6941 13.3653C52.0826 13.3653 54.0242 15.2672 54.0242 18.5794C54.0242 21.8916 52.3237 24.324 45.7499 29.8746L28.7317 44.8959L28.3256 45.2582ZM67.5017 54.3409H29.7216V46.0733L30.648 45.2582L46.7144 31.0778C53.0597 25.7214 55.5344 22.9008 55.5344 18.5923C55.5344 14.2839 52.6917 11.8256 48.6815 11.8256C44.6712 11.8256 42.0442 14.051 38.2497 18.8123L30.3688 12.3431C30.3688 12.3431 30.4449 12.2396 30.483 12.2008C33.4526 8.09938 36.3969 5.11063 40.2041 3.3769C42.7803 2.18657 45.7499 1.56554 49.4175 1.56554C55.0014 1.56554 59.4812 3.26045 62.5143 6.22333C65.3951 9.03094 66.9941 12.9642 66.9941 17.6996C66.9941 21.8657 65.9789 25.0356 63.9483 27.9985C61.7275 31.2201 58.301 34.2089 53.5801 37.9222L45.2677 44.469H67.5144V54.3538L67.5017 54.3409ZM87.3373 40.2123C87.3373 43.3951 84.4438 45.3358 79.6467 45.3358C73.5552 45.3358 68.5297 42.774 63.7326 38.7373L60.3188 42.9034H49.7221L54.5065 39.1384C59.1259 35.4898 62.7808 32.3975 65.2047 28.8524C68.1617 30.7155 72.0323 31.9576 76.6136 33.1479C85.5225 35.4768 87.35 37.0294 87.35 40.057V40.2123H87.3373Z" />
    </g>
    <defs>
      <clipPath id="logo-cp">
        <rect width="99" height="69" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import TrainingList from './trainingList';
import { BackButton, Card, Wizard } from 'components';
import { Props } from '../types';

export type TrainingItem = {
  active: boolean;
  completed: boolean;
  flow: string;
  id: number;
  name: string;
};

const OnboardingTraining = ({ handleContinue, step }: Props) => {
  const [trainingId, setTrainingId] = useState<string | null>(null);
  const handleSelectTraining = (id: any) => {
    setTrainingId(id);
  };

  return (
    <div className="onboarding-training">
      {trainingId ? (
        <div>
          <Card title="Trainings" leftElement={<BackButton onClick={() => setTrainingId(null)} />} />
          <Wizard trainingId={trainingId} setTrainingId={setTrainingId} page="ONBOARDING" />
        </div>
      ) : (
        <TrainingList step={step} handleContinue={handleContinue} onSelect={handleSelectTraining} />
      )}
    </div>
  );
};

export default OnboardingTraining;

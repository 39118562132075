import React from 'react';
import { getRelativeTime } from 'helpers/date';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  title: string;
  sender: string;
  created_at: string;
  image: string;
  onClick: () => void;
};

const AlreadyReadNotification = ({ title, sender, created_at, image, onClick }: Props) => {
  return (
    <div className="already-read-notification" onClick={onClick}>
      <div className="picture">
        <div className="img-container">
          <LazyLoadImage alt="profile" src={image} effect="blur" />
          <img loading="lazy" />
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <div className="title">
            <span className="body">{title}</span>
          </div>
        </div>
        <div className="date">
          <span className="body-2 date">{getRelativeTime(created_at).replace('about', '')}</span> -{' '}
          <span className="body-2 send-by">{sender}</span>
        </div>
      </div>
    </div>
  );
};

export default AlreadyReadNotification;

import React, { useEffect, useState } from 'react';
import endpoints from 'constants/endpoints';
import { Button, Card, ToggleSwitch, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Props } from '../types';
import schema from './schema';

const Residence = ({ handleContinue, step }: Props) => {
  const [sameResidence, setSameResidence] = useState(true);
  const residenceFormHandler = useFormHandler(schema);
  const mailingFormHandler = useFormHandler(schema);
  const api = useApi();
  const apiStates = useApi();

  const handleSubmit = () => {
    const residence = residenceFormHandler.data;
    const mailing = sameResidence ? undefined : mailingFormHandler.data;

    api.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step, data: { residence, mailing } });
  };

  useEffect(() => {
    if (api.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [api.response]);

  useEffect(() => {
    if (apiStates.response) {
      const options = apiStates.response.items.map(({ id: value, name: label }: any) => ({ value, label }));
      const newSchema = { ...schema, state: { ...schema.state, options, disabled: false } };

      residenceFormHandler.setSchema(newSchema);
      mailingFormHandler.setSchema(newSchema);
    }
  }, [apiStates.response]);

  useEffect(() => {
    apiStates.fetchData(endpoints.VALID_STATES.get());
  }, []);

  return (
    <div className="residence">
      <Card title="Residence">
        <>
          <Card.Item>
            <DynamicForm handler={residenceFormHandler} />
          </Card.Item>

          <Card.Item>
            <p className="body-1">Do you receive mail to the same address as your current primary residence?</p>
            <br />
            <ToggleSwitch value={sameResidence} onChange={setSameResidence} />
          </Card.Item>

          {!sameResidence && (
            <Card.Item>
              <h6>Mailing address</h6>
              <hr />
              <DynamicForm handler={mailingFormHandler} />
            </Card.Item>
          )}

          <Card.Footer>
            <Button
              text="Continue"
              onClick={handleSubmit}
              disabled={!residenceFormHandler.valid || (!sameResidence && !mailingFormHandler.valid)}
              loading={api.isLoading}
            />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default Residence;

import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  answer: {
    value: '',
    options: [
      { value: 'high-school', label: 'High school degree or equivalent (e.g., GED)' },
      { value: 'college', label: 'Currently enrolled in college, not graduated' },
      { value: 'associate-bachelor', label: 'Associate / Bachelor degree' },
      { value: 'graduate', label: 'Graduate degree' }
    ],
    label: 'Please select the highest level of education you have completed',
    input: InputsEnum.RADIO_SELECTOR,
    joi: joi.string().required()
  }
};

import React from 'react';
import { MenuButton, SubMenu, Menu, MenuItem, MenuAlign } from '@szhsin/react-menu';

type SubItem = {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
};

type Item = {
  text: string;
  icon?: React.ReactNode;
  onClick?: (id?: string | number, params?: any) => void;
  subItems?: SubItem[];
  selected?: boolean;
  disabled?: boolean;
};

type Props = {
  items: Item[];
  button: React.ReactElement;
  params?: any;
  align?: MenuAlign;
  isPortal?: boolean;
  id?: string | number;
  offsetX?: number;
  offsetY?: number;
  viewScroll?: 'initial' | 'auto' | 'close';
};

const DropdownButton = ({
  items,
  button,
  params,
  align = 'start',
  isPortal,
  id,
  offsetX = 0,
  offsetY = 0,
  viewScroll = 'initial'
}: Props) => {
  const portal = isPortal && {
    target: document.getElementById('root')
  };

  return (
    <div className="menu-container">
      <Menu
        portal={portal}
        menuButton={<MenuButton>{button}</MenuButton>}
        transition
        align={align}
        offsetX={offsetX}
        offsetY={offsetY}
        viewScroll={viewScroll}
      >
        {items.map((item, i) => (
          <React.Fragment key={i}>
            {!!item.subItems && item.subItems.length > 0 ? (
              <SubMenu label={item.text}>
                {item.subItems.map((subItem, j) => (
                  <MenuItem key={j} onClick={subItem.onClick} disabled={subItem.disabled}>
                    {!!subItem.icon && subItem.icon}
                    {subItem.selected ? <strong>{subItem.text}</strong> : subItem.text}
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem onClick={item.onClick ? () => item.onClick!(id, params) : undefined} disabled={item.disabled}>
                {!!item.icon && item.icon}
                {item.selected ? <strong>{item.text}</strong> : item.text}
              </MenuItem>
            )}
          </React.Fragment>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownButton;

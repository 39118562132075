import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card } from 'components';
import { Props } from '../types';

const Payment = ({ step, handleContinue }: Props) => {
  const api = useApi();
  const apiPayment = useApi();
  const url = api?.response?.url;

  const handleSubmit = () => {
    apiPayment.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step });
  };

  useEffect(() => {
    apiPayment.success && handleContinue();
  }, [apiPayment.success]);

  useEffect(() => {
    api.fetchData(endpoints.GET_PAYMENT_URL.get());
  }, []);

  return (
    <div className="payment">
      <Card title="Payment">
        <Card.Item>{!!url && <iframe id="payment-iframe" title="Payment" src={url} />}</Card.Item>
        <Card.Footer>
          <Button text="Continue" onClick={handleSubmit} loading={api.isLoading || apiPayment.isLoading} />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Payment;

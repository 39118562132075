import React, { useState, useRef, useCallback } from 'react';
import { type Crop, centerCrop, makeAspectCrop } from 'react-image-crop';

const ASPECT_RATIO = 1;

const useImageCrop = () => {
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const initialCrop = makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(initialCrop, width, height);
    setCrop(centeredCrop);
  }, []);

  const getCroppedImg = useCallback((): string | null => {
    const image = imgRef.current;
    if (!completedCrop || !image) {
      return null;
    }
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return canvas.toDataURL('image/jpeg');
  }, [completedCrop]);

  return {
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    imgRef,
    onImageLoad,
    getCroppedImg
  };
};

export default useImageCrop;

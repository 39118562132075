import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { SendBirdProvider } from '@sendbird/uikit-react';
import routes from 'constants/routes';
import usePushNotifications from 'hooks/usePushNotifications';
import {
  EventInfoPage,
  HomePage,
  MyPaymentMethod,
  MyNoticesAcceptedPage,
  MyProfilePage,
  MyVenuesPage,
  NotFoundPage,
  NoticesPage,
  OnBoarding,
  PaymentsPage,
  SignInPage,
  StaffPassportPage,
  MyStatsPage,
  MyTrainingsPage,
  TrainingWizard,
  WarningsPage,
  MyChatsPage,
  NotificationPage,
  NotificationDetailPage,
  ApplicantPage
} from 'pages';
import { RootState } from 'types/store';
import useActiveChatsValidator from 'hooks/useActiveChatsValidator';
import useActiveNotifications from 'hooks/useActiveNotifications';

const Auth = ({ page, skip = false }: { page: React.ReactElement; skip?: boolean }) => {
  const { data, isLogged, onBoardingCompleted, isApplicationCompleted } = useSelector((state: RootState) => state.user);
  const { hasChats, isLoading } = useActiveChatsValidator({ isLogged, onBoardingCompleted, isApplicationCompleted });

  usePushNotifications(isLogged);
  useActiveNotifications({ isLogged, onBoardingCompleted, isApplicationCompleted });

  if (!isLogged) {
    return <Navigate to={routes.SIGN_IN} replace />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isApplicationCompleted && !skip) {
    return <Navigate to={routes.APPLICANT} replace />;
  }

  if (!onBoardingCompleted && !skip) {
    return <Navigate to={routes.ON_BOARDING} replace />;
  }

  if (data.suspended) {
    const allowedRoutes = [routes.MY_PROFILE, routes.MY_WARNINGS, routes.PAYMENTS, routes.MY_PAYMENT_METHOD];
    if (!allowedRoutes.includes(window.location.pathname)) {
      return <Navigate to={routes.MY_PROFILE} replace />;
    }
  }

  const content = page;

  return hasChats ? (
    <SendBirdProvider appId={process.env.REACT_APP_SENDBIRD_APP_ID!} userId={data?.id}>
      {content}
    </SendBirdProvider>
  ) : (
    content
  );
};

const Avoid = ({ page }: { page: React.ReactElement }) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const { isLogged } = useSelector((state: RootState) => state.user);

  return !isLogged ? page : <Navigate to={redirect || routes.HOME} replace />;
};

const Router = () => (
  <Routes>
    <Route path={routes.SIGN_IN} element={<Avoid page={<SignInPage />} />} />
    <Route path={routes.HOME} element={<Auth page={<HomePage />} />} />
    <Route path={routes.EVENT_INFO} element={<EventInfoPage />} />
    <Route path={routes.PAYMENTS} element={<Auth page={<PaymentsPage />} />} />
    <Route path={routes.MY_PAYMENT_METHOD} element={<Auth page={<MyPaymentMethod />} />} />
    <Route path={routes.NOTICES} element={<Auth page={<NoticesPage />} />} />
    <Route path={routes.MY_NOTICES_ACCEPTED} element={<Auth page={<MyNoticesAcceptedPage />} />} />
    <Route path={routes.MY_VENUES} element={<Auth page={<MyVenuesPage />} />} />
    <Route path={routes.MY_PROFILE} element={<Auth page={<MyProfilePage />} />} />
    <Route path={routes.APPLICANT} element={<Auth page={<ApplicantPage />} skip={true} />} />
    <Route path={routes.ON_BOARDING} element={<Auth page={<OnBoarding />} skip={true} />} />
    <Route path={routes.STAFF_PASSPORT} element={<Auth page={<StaffPassportPage />} />} />
    <Route path={routes.MY_STATS} element={<Auth page={<MyStatsPage />} />} />
    <Route path={routes.MY_TRAININGS} element={<Auth page={<MyTrainingsPage />} />} />
    <Route path={routes.TRAINING_WIZARD} element={<Auth page={<TrainingWizard />} />} />
    <Route path={routes.MY_WARNINGS} element={<Auth page={<WarningsPage />} />} />
    <Route path={routes.MY_CHATS} element={<Auth page={<MyChatsPage />} />} />
    <Route path={routes.NOTIFICATIONS} element={<Auth page={<NotificationPage />} />} />
    <Route path={routes.NOTIFICATION_DETAIL} element={<Auth page={<NotificationDetailPage />} />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default Router;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import classNames from 'classnames';
import { ArrowDown2, ArrowRight2, InfoCircle, TickCircle } from 'iconsax-react';

type Item = { value: number; label: string; completed: string | number };

interface Props {
  title: string;
  items: Item[];
  icon?: React.ReactNode;
  onClick?: (id: number) => void;
}

const TrainingDropdown: React.FC<Props> = ({ title, items, icon, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const iconClass = classNames({
    'icon-rotate': isOpen
  });

  return (
    <div className="training-dropdown">
      <button className="training-dropdown-button" onClick={() => setIsOpen(!isOpen)}>
        <div className="button-content">
          <span className="category-icon">{icon}</span>
          <span className="heading-md">{title}</span>
        </div>
        <ArrowDown2 className={iconClass} size={18} />
      </button>

      {isOpen && (
        <div className="training-dropdown-list">
          {items?.length > 0 ? (
            items.map((item, index) => (
              <div
                key={index}
                className="training-dropdown-item input-body"
                onClick={() => onClick?.(item.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onClick?.(item.value);
                  }
                }}
                tabIndex={0}
                aria-pressed="false"
                role="button"
              >
                <div className="content">
                  <div className="item">
                    {!item.completed ? <InfoCircle className="info-circle" /> : <TickCircle className="tick-circle" />}
                    <p>{item.label}</p>
                  </div>
                  <ArrowRight2 className="icon" size={18} />
                </div>
              </div>
            ))
          ) : (
            <li className="training-dropdown-item input-body">No items</li>
          )}
        </div>
      )}
    </div>
  );
};

export default TrainingDropdown;

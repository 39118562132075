import React from 'react';

interface Props {
  onLoadMore: () => void;
  isFetching: boolean;
  totalFetched: number;
  totalDBRowCount: number;
}

const useInfinityScroll = ({ onLoadMore, isFetching, totalFetched, totalDBRowCount }: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const fetchMoreOnBottomReached = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isFetching && totalFetched < totalDBRowCount) {
        onLoadMore();
      }
    }
  };

  return { containerRef, fetchMoreOnBottomReached };
};

export default useInfinityScroll;

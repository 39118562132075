/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import { Refresh } from 'iconsax-react';
import { Portal } from 'react-portal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import endpoints from 'constants/endpoints';
import { TABLET_BREAKPOINT } from 'constants/breakpoints';
import routes from 'constants/routes';
import useApi from 'hooks/useApi';
import { useRefs } from 'hooks/useRefs';
import { useToast } from 'hooks/useToast';
import { Alert, Button, Card, EventCard } from 'components';
import { setHasChats, setNotices } from 'store/actions/user';
import { formatPositions } from 'utils/data';
import { isToday } from 'date-fns';

const HomePage = () => {
  const windowSize = useWindowSize();
  const { extraButtonsRef } = useRefs();
  const api = useApi();
  const apiNotices = useApi();
  const apiChats = useApi();
  const notify = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pendingPositions = api?.response?.pendingPositionTrainings || [];

  const handleRefresh = async () => {
    const eventsResponse = await api.fetchData(endpoints.GET_EVENTS.get());
    await handleRefreshChats();

    if (eventsResponse?.success) {
      notify('Events refreshed');
    }
  };

  const handleRefreshChats = async () => {
    const chatsResponse = await apiChats.fetchData(endpoints.GET_ACTIVE_CHATS.get());

    if (chatsResponse?.data && chatsResponse.data.length > 0) {
      dispatch(setHasChats(true));
    } else {
      dispatch(setHasChats(false));
    }
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_EVENTS.get());
    apiNotices.fetchData(endpoints.NOTICES.get());
    handleRefreshChats();
  }, []);

  useEffect(() => {
    if (apiNotices.response?.items?.length) {
      dispatch(setNotices(apiNotices.response.items));
      navigate(routes.NOTICES);
    }
  }, [apiNotices.response, navigate]);

  const todayEvents = api?.response?.data.filter((event: any) => isToday(new Date(event.date))) || [];
  const upcomingEvents = api?.response?.data.filter((event: any) => !isToday(new Date(event.date))) || [];

  return (
    <>
      <Portal node={extraButtonsRef.current}>
        {windowSize.width < TABLET_BREAKPOINT && (
          <Button variant="alternative" icon={<Refresh />} onClick={handleRefresh} disabled={api.isLoading} />
        )}
      </Portal>

      <div className="page-home">
        {api?.response?.pendingGlobalTrainings && (
          <Alert>
            Please go to the “My Trainings” tab and complete your <span>general training requirements</span>. Once you
            have done that, you will be able to sign up for events! Please email us at {''}
            <a className="underline" href="mailto:support@eats2seats.com">
              support@eats2seats.com
            </a>{' '}
            with any questions you may have.
          </Alert>
        )}
        {api?.response?.pendingVenuesTrainings && (
          <Alert>
            Please go to the “My Trainings” tab and complete your <span>venue’s training requirements</span>. Once you
            have done that, you will be able to sign up for events! Please email us at{' '}
            <a className="underline" href="mailto:support@eats2seats.com">
              support@eats2seats.com
            </a>{' '}
            with any questions you may have.
          </Alert>
        )}
        {pendingPositions.length > 0 && (
          <Alert>
            As a {formatPositions(pendingPositions)} you will need to complete your <span>positional training</span> in
            your "My Trainings" tab and achieve a 100% on the trainings to work. Please complete this ASAP before your
            next shift and email any questions you have to{' '}
            <a className="underline" href="mailto:support@eats2seats.com">
              support@eats2seats.com
            </a>
            .
          </Alert>
        )}

        {!!todayEvents.length && (
          <>
            <Card title="Today's Events"> </Card>
            {todayEvents.map((item: any) => (
              <EventCard
                key={item.id}
                id={item.id}
                address={item.address}
                date={item.date}
                reportTimeAt={item.report_time}
                groupMeLink={item.group_me}
                payModelUrl={item.paymodel_url}
                channelUrl={item.sendbird_channel_id}
                eventLogisticsUrl={item.event_logistics_url}
                eventTrainingUrl={item.event_training_url}
                title={item.venue}
                subscribed={item.subscribed}
                requireBackgroundCheck={item.requirebackgroundcheck && !api.response.employeeHasBackgroundCheck}
                requireAlcoholCertification={
                  item.requirealcoholcertification && !api.response.employeeHasAlcoholCertification
                }
                backgroundCheckProcessing={api.response.backgroundCheckProcessing}
                timezone={item.timezone}
                canConfirm={item.canConfirm}
                canDropout={item.canDropout}
                dropped={item.dropped}
                confirmationDeadline={item.confirmationDeadline}
                jobType={api.response.jobType}
                allocation={item.allocation}
              />
            ))}
          </>
        )}

        {!!upcomingEvents.length && (
          <>
            <Card title="Upcoming Events"> </Card>
            {upcomingEvents.map((item: any) => (
              <EventCard
                key={item.id}
                id={item.id}
                address={item.address}
                date={item.date}
                reportTimeAt={item.report_time}
                groupMeLink={item.group_me}
                payModelUrl={item.paymodel_url}
                channelUrl={item.sendbird_channel_id}
                eventLogisticsUrl={item.event_logistics_url}
                eventTrainingUrl={item.event_training_url}
                title={item.venue}
                subscribed={item.subscribed}
                requireBackgroundCheck={item.requirebackgroundcheck && !api.response.employeeHasBackgroundCheck}
                requireAlcoholCertification={
                  item.requirealcoholcertification && !api.response.employeeHasAlcoholCertification
                }
                backgroundCheckProcessing={api.response.backgroundCheckProcessing}
                timezone={item.timezone}
                canConfirm={item.canConfirm}
                canDropout={item.canDropout}
                dropped={item.dropped}
                confirmationDeadline={item.confirmationDeadline}
                jobType={api.response.jobType}
                allocation={item.allocation}
              />
            ))}
          </>
        )}

        {!api.response?.data.length && (
          <Card>
            <Card.Item>There are no upcoming events yet</Card.Item>
          </Card>
        )}
      </div>
    </>
  );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Building,
  DocumentText,
  Home,
  Logout,
  Medal,
  Message2,
  Money,
  ScanBarcode,
  StatusUp,
  UserSquare,
  Wallet,
  Warning2
} from 'iconsax-react';
import routes from 'constants/routes';
import { logoutMessage } from 'constants/messages';
import { useModal } from 'hooks/useModal';
import { logout } from 'store/actions/user';
import { RootState } from 'types/store';
import { ModalsList } from 'types/modal';

type Props = {
  open: boolean;
  onClose: () => void;
};

const TRANSITION_DURATION = 300;

const MenuMobile = ({ open, onClose }: Props) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const modal = useModal();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { data, onBoardingCompleted, hasChats } = useSelector((state: RootState) => state.user);

  const menuRoutes = [
    {
      icon: <Home />,
      name: 'Home',
      route: routes.HOME,
      disabled: data.suspended
    },
    {
      icon: <ScanBarcode />,
      name: 'Staff Passport',
      route: routes.STAFF_PASSPORT,
      disabled: data.suspended
    },
    {
      icon: <UserSquare />,
      name: 'My Profile',
      route: routes.MY_PROFILE,
      disabled: false
    },
    {
      icon: <Message2 />,
      name: 'My Chats',
      route: routes.MY_CHATS,
      disabled: !hasChats
    },
    {
      icon: <Medal />,
      name: 'My Trainings',
      route: routes.MY_TRAININGS,
      disabled: data.suspended
    },
    {
      icon: <Money />,
      name: 'Payments',
      route: routes.PAYMENTS,
      disabled: false
    },
    {
      icon: <Wallet />,
      name: 'My Payment Method',
      route: routes.MY_PAYMENT_METHOD,
      disabled: false
    },
    {
      icon: <DocumentText />,
      name: 'My Notices',
      route: routes.MY_NOTICES_ACCEPTED,
      disabled: data.suspended
    },
    {
      icon: <Building />,
      name: 'My Venues',
      route: routes.MY_VENUES,
      disabled: data.suspended
    },
    {
      icon: <StatusUp />,
      name: 'My Stats',
      route: routes.MY_STATS,
      disabled: data.suspended
    },
    {
      icon: <Warning2 />,
      name: 'My Warnings',
      route: routes.MY_WARNINGS,
      disabled: false
    }
  ];

  const handleNavigate = (index: number, route: string, disabled: boolean) => () => {
    if (disabled || (!onBoardingCompleted && index !== 0)) {
      return;
    }

    onClose();
    navigate(route);
  };

  const handleLogout = () => {
    modal.open(
      ModalsList.CONFIRM,
      flag => {
        if (flag) {
          onClose();
          dispatch(logout());
        }
      },
      logoutMessage
    );
  };

  useEffect(() => {
    if (open) {
      setVisible(true);
    } else {
      setTimeout(() => {
        setVisible(false);
      }, TRANSITION_DURATION);
    }
  }, [open]);

  return (
    <Portal key="mobile-menu-portal" node={document.getElementById('root')}>
      {visible && (
        <div className={classnames('menu-mobile', { open })}>
          <div className="menu">
            {menuRoutes.map((item, index) => (
              <div
                key={index}
                className={classnames('menu-item', {
                  selected: item.route === pathname,
                  disabled: (!onBoardingCompleted && index !== 0) || item.disabled
                })}
                onClick={handleNavigate(index, item.route, item.disabled)}
              >
                {item.icon}
                <span>{item.name}</span>
              </div>
            ))}
            <div className="menu-item" onClick={handleLogout}>
              <Logout />
              <span>Logout</span>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default MenuMobile;

import { differenceInYears, format, startOfMonth, subMonths } from 'date-fns';
import { AvailablePositions, LeaderRoles, PositionMapType, RANKING } from 'constants/user';
import { VIDEO_DURATION } from 'constants/video';

export function getPlaneSSN(ssn: string): string {
  return ssn.replace(/-/g, '');
}

export function getTruncatedSSN(truncatedSSN: string): string {
  return truncatedSSN?.replace(/(\d{0})/, 'XXX-XX-');
}

export function isOver18(date: string): boolean {
  const diff = differenceInYears(new Date(), new Date(date));
  return diff >= 18;
}

export function checkAvailablePositions(
  leaderPositions: LeaderRoles[],
  availablePositions: AvailablePositions,
  positionMap: PositionMapType
) {
  const hasAvailablePositions = leaderPositions.some(
    position => !availablePositions[positionMap[position as LeaderRoles]]
  );

  return hasAvailablePositions;
}

export const formatNumber = (number: string): string => {
  number = number.toString();
  if (number.length === 12 && number.startsWith('+') && /^\d+$/.test(number.slice(1))) {
    const [countryCode, areaCode, firstDigits, lastDigits] = [
      number.slice(1, 2),
      number.slice(2, 5),
      number.slice(5, 8),
      number.slice(8, 12)
    ];

    return `+${countryCode} (${areaCode}) ${firstDigits}-${lastDigits}`;
  } else {
    return '';
  }
};

export function formatToUSD(number: number) {
  return number?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

export function getLastThreeMonths() {
  const currentDate = new Date();
  const getMonthName = (date: Date) => format(date, 'MMMM');
  const startOfCurrentMonth = startOfMonth(currentDate);

  const months = [
    getMonthName(subMonths(startOfCurrentMonth, 2)),
    getMonthName(subMonths(startOfCurrentMonth, 1)),
    getMonthName(startOfCurrentMonth)
  ];

  return `Last 3 Months: ${months[0]} - ${months[2]}`;
}

export function getRankingVariant(rank: string) {
  switch (rank) {
    case RANKING.BRONZE:
      return 'bronze';
    case RANKING.SILVER:
      return 'silver';
    case RANKING.GOLD:
      return 'gold';
    case RANKING.PLATINUM:
      return 'platinum';
    case RANKING.MOON:
      return 'primary';

    default:
      return '';
  }
}

export function removeGetParams(url: string) {
  const urlObject = new URL(url);

  Array.from(urlObject.searchParams.keys()).forEach(key => {
    urlObject.searchParams.delete(key);
  });

  return urlObject.toString();
}

export function formatPositions(positions: string[]) {
  if (positions.length === 1) {
    return positions[0];
  } else {
    return `${positions.slice(0, -1).join(', ')} and ${positions[positions.length - 1]}`;
  }
}

export const getLastMessageDate = (channel: any) => {
  const lastMessageDate = channel?.lastMessage?.createdAt;
  return lastMessageDate ? format(new Date(lastMessageDate), 'MMM dd') : null;
};

export function getUrlFileExtension(url: string) {
  const urlObject = new URL(url);

  return urlObject.pathname.split('.').pop()?.trim();
}

export const checkVideoDuration = (file: File): Promise<boolean> => {
  return new Promise(resolve => {
    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);
    videoElement.onloadedmetadata = () => {
      URL.revokeObjectURL(videoElement.src);
      resolve(videoElement.duration <= VIDEO_DURATION);
    };
    videoElement.onerror = () => {
      URL.revokeObjectURL(videoElement.src);
      resolve(false);
    };
  });
};

export const allPropertiesHaveValue = (obj: any) => {
  if (!obj) return false;

  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      return false;
    }
  }
  return true;
};

export async function downloadFileUrl(url: string, type: string, filename: string) {
  const response = await fetch(url);

  if (response.ok) {
    const fileData = await response.arrayBuffer();
    const blob = new Blob([fileData], { type: type });
    const element = document.createElement('a');

    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element).click();
    document.body.removeChild(element);
  } else {
    console.error(`HTTP error! Status: ${response.status}`);
  }
}

export function getFileExtension(filename: string) {
  return filename.split('.').pop();
}

import {
  LOG_IN,
  LOG_OUT,
  SET_TOKEN,
  ON_BOARDING_COMPLETED,
  NOTICES,
  UserState,
  UserActionTypes,
  SET_NOTIFICATION_TOKEN,
  SET_CHANNEL_URL,
  SET_HAS_CHATS,
  HAS_NOTIFICATIONS,
  NOTIFICATIONS,
  ADD_NOTIFICATION,
  SET_APPLICATION_COMPLETED,
  SET_BACKGROUND_CHECK_PROCESSING
} from 'types/store';

const initialState: UserState = {
  isLogged: false,
  onBoardingCompleted: false,
  isApplicationCompleted: false,
  notificationToken: null,
  pushNotificationsEnabled: false,
  notices: [],
  data: {},
  token: '',
  refreshToken: '',
  channelUrl: '',
  hasChats: false,
  notifications: [],
  hasNotifications: false,
  backgroundCheckProcessing: false
};

const user = (state: UserState = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        isLogged: true,
        data: action.data,
        onBoardingCompleted: action.onBoardingCompleted,
        isApplicationCompleted: action.isApplicationCompleted,
        token: action.token,
        refreshToken: action.refreshToken
      };
    case LOG_OUT:
      return {
        ...state,
        isLogged: false,
        data: {},
        token: '',
        refreshToken: '',
        onBoardingCompleted: false,
        isApplicationCompleted: false,
        notificationToken: null,
        channelUrl: '',
        notifications: []
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case ON_BOARDING_COMPLETED:
      return {
        ...state,
        onBoardingCompleted: true
      };
    case SET_APPLICATION_COMPLETED:
      return {
        ...state,
        isApplicationCompleted: true
      };
    case NOTICES:
      return {
        ...state,
        notices: [...action.notices]
      };
    case SET_NOTIFICATION_TOKEN:
      return {
        ...state,
        notificationToken: action.notificationToken
      };
    case SET_CHANNEL_URL:
      return {
        ...state,
        channelUrl: action.channelUrl
      };
    case SET_HAS_CHATS: {
      return {
        ...state,
        hasChats: action.hasChats
      };
    }
    case HAS_NOTIFICATIONS:
      return {
        ...state,
        hasNotifications: action.hasNotifications
      };
    case NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.notification, ...state.notifications]
      };
    case SET_BACKGROUND_CHECK_PROCESSING:
      return {
        ...state,
        backgroundCheckProcessing: true
      };
    default:
      return state;
  }
};

export default user;

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DocumentText, Import } from 'iconsax-react';
import { Capacitor } from '@capacitor/core';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import { DropFileProps } from 'types/dynamicInputs';
import useImagePicker from 'hooks/useImagePicker';
import { FilePreview } from 'components';
import { fileToBase64 } from 'helpers/data';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { disableApplicantForm } from 'helpers/applicant';
import classNames from 'classnames';

const DropImage = ({ value, onChange, schema, setSchema, disabled }: DropFileProps) => {
  const [image, setImage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const apiS3 = useApi();

  const isWebPlatform = Capacitor.getPlatform() === 'web';
  const modal = useModal();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const base64Url = await fileToBase64(file);
        setImage(base64Url);
      }
    },
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    disabled
  });

  const { showPrompt } = useImagePicker(setImage);

  const openModal = () => {
    modal.open(
      ModalsList.UPLOAD_PROFILE_RESUME,
      async file => {
        if (file) {
          disableApplicantForm({ schema, setSchema });
          setIsLoading(true);
          const signedUrl = await api.fetchData(endpoints.GET_SIGNED_PROFILE_IMAGE.get(), {
            fileName: file.name
          });
          await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);
          onChange({
            fileName: file.name,
            fileSize: file.size,
            path: signedUrl.filePath
          });
          setImage('');
          setIsLoading(false);
        } else {
          setImage('');
        }
      },
      { url: image }
    );
  };

  const confirmRemoveFile = () => {
    modal.open(
      ModalsList.CONFIRM,
      flag => {
        if (flag) {
          setImage('');
          onChange(null);
        }
      },
      {
        title: 'Remove image',
        message: 'Are you sure you want to remove the image?'
      }
    );
  };

  useEffect(() => {
    if (image) openModal();
  }, [image]);

  return (
    <>
      <section className="drop-image">
        <label className="label">Profile Picture</label>
        <>
          {value ? (
            <FilePreview
              fileName={value.fileName}
              fileSize={value.fileSize}
              onRemove={confirmRemoveFile}
              icon={<DocumentText size={16} />}
            />
          ) : (
            <div
              className={classNames('image-dropzone', {
                disabled
              })}
              {...(isWebPlatform ? getRootProps() : { onClick: disabled ? () => {} : showPrompt })}
            >
              <input style={{ display: 'none' }} {...(isWebPlatform ? getInputProps() : {})} />
              <div className="placeholder">
                {isLoading ? (
                  <div className="loading-text">Uploading image...</div>
                ) : (
                  <>
                    <Import size={24} />
                    <p>Select a file or drag and drop</p>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      </section>
    </>
  );
};

export default DropImage;

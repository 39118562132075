import React from 'react';
import { CloseCircle } from 'iconsax-react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

type Props = {
  message: string;
  files: any[];
  removeFile: (index: number) => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
};

const Dropzone = ({ message, files, getInputProps, getRootProps, removeFile }: Props) => {
  const acceptedFileItems = files.map((file, index) => (
    <li className="body file-list" key={index}>
      {index + 1} - {file.name}
      <button onClick={() => removeFile(index)}>
        <CloseCircle size={20} />
      </button>
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{message}</p>
      </div>
      <aside className="files-container">
        <ul>{acceptedFileItems}</ul>
      </aside>
    </section>
  );
};

export default Dropzone;

import * as React from 'react';

const ApprovedQuiz = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        fill="#22222C"
        d="M158.544 137.55c.601-1.038.68-2.373.483-4.153a315.48 315.48 0 0 1-.508-4.875A347.243 347.243 0 0 0 153.6 94.81a350.047 350.047 0 0 0-3.697-16.632c-.952-3.822-1.859-7.905-4.225-11.144-2.316-3.174-5.747-4.44-9.557-4.682-8.198-.509-16.636 1.286-24.296 3.763-2.416.78-5.067 1.404-7.217 2.798-3.854 2.507-5.353 7.465-6.21 11.742-1.139 5.7-1.13 11.628-1.203 17.418-.079 6.372.044 12.74.365 19.103.27 5.453.611 10.906 1.207 16.335.296 2.714.66 5.423 1.11 8.113.211 1.265-.055 3.129.946 4.088 2.016 1.929 5.575 1.113 8.059 1.098 7.285-.04 14.447-.153 21.683-1.172 8.36-1.181 16.903-3.065 24.868-5.903 1.587-.563 2.563-1.261 3.106-2.2l.005.015Z"
      />
      <path
        fill="#573BFF"
        d="M102.396 143.631a1.15 1.15 0 0 1-.143-.212c-.173-.332-.217-.712-.257-1.083-1.197-10.961-2.277-21.882-2.548-32.917-.217-8.953-.591-18.342.887-27.207.828-4.963 2.425-10.426 7.483-12.449 9.405-3.762 19.578-6.496 29.767-5.067 5.21.732 7.645 4.9 9.04 9.572 3.007 10.046 4.919 20.566 6.64 30.899 1.666 10.036 2.795 20.127 3.692 30.257.167 1.864-5.112 2.714-6.482 3.105-2.144.613-4.308 1.177-6.467 1.745a123.453 123.453 0 0 1-13.6 2.754 123.011 123.011 0 0 1-13.826 1.196c-2.317.07-4.639.07-6.955.005-1.868-.054-4.87.337-6.595-.257-.262-.089-.469-.153-.636-.341Z"
      />
      <path
        fill="#22222C"
        d="M223.571 47.664c.611-.787.981-1.726.897-2.809-.128-1.69-1.375-2.862-2.908-2.99.207-.5.335-1.029.34-1.588.02-1.686-1.203-3.095-2.745-3.238.281-1.77-.4-3.634-2.347-4.227-2.73-.83-6.097 1.898-8.207 3.812-.714-3.431-3.633-10.378-7.369-7.174-1.898 1.627-.591 4.984-.187 6.951.734 3.59 1.474 7.174 2.208 10.763-8.375 8.089-18.972 13.314-30.462 15.084-.577-.079-1.208.252-1.193 1.004.197 9.764.394 19.529.596 29.288 0 .034.01.07.015.104-.212.717.631 1.686 1.573 1.275 14.373-6.293 27.184-15.717 37.511-27.533a1.2 1.2 0 0 0 .266-.257c.074-.104.153-.207.226-.316.301-.351.607-.692.903-1.043.172-.208.251-.43.256-.653 1.183-1.75 2.464-3.545 4.19-4.499 1.478-.816 2.908-1.572 3.228-3.392.03-.167.025-.32-.01-.46 3.249-1.121 6.65-5.541 3.219-8.107v.005Z"
      />
      <path
        fill="#fff"
        d="M221.065 43.96c2.051-.45 1.173 2.136.547 2.789-.552.578-1.296.93-2.016 1.246-.67.296-1.36.578-2.085.697-1.099.178-3.223-.569-2.183-1.795a7.261 7.261 0 0 0 2.085-.89c.931-.583 1.971-1.32 2.814-2.21.217.154.503.228.833.159l.005.005ZM218.726 42.3c-.676.716-1.533 1.374-2.361 1.893-.469.292-1.011.583-1.573.717-.118 0-.241.02-.365.064-.192.02-.389.025-.581 0-1.168-.173-2.223-1.4-1.065-2.328.049-.04.089-.085.128-.13 2.09-.523 4.19-1.838 5.289-3.257a.946.946 0 0 0 .316-.054c2.09-.703.872 2.392.217 3.09l-.005.004ZM209.688 38.938c.315-.223.448-.53.458-.836 2.065-1.686 7.083-5.246 7.044-1.671-.02 1.725-1.967 2.759-3.283 3.362-.631.291-1.316.593-2.001.746a2.954 2.954 0 0 1-1.538.04c-.892-.188-1.119-.737-.68-1.646v.005Z"
      />
      <path
        fill="#fff"
        d="M213.951 59.272c-1.385 1.577-2.573 3.397-3.806 5.117a105.32 105.32 0 0 1-35.874 26.895c-.183-9.062-.37-18.12-.552-27.182 11.633-1.873 22.398-7.282 30.906-15.455.517-.133.971-.608.833-1.28-.799-3.881-1.592-7.757-2.391-11.638-.197-.97-.448-1.933-.596-2.912-.227-1.468.857-1.908 2.026-.96 1.493 1.217 2.06 3.97 2.168 5.74.035.535.38.851.784.965-.488 1.503.35 3.253 2.129 3.851.227.074.464.124.705.163-.335 1.217.079 2.62 1.109 3.486.493.41 1.04.662 1.617.79-.231 1.094.143 2.26 1.149 3.066.083.064.167.124.251.178-.68 1.211-.636 2.734.192 3.955.823 1.212 2.209 1.815 3.623 1.889-.626 1.572-3.164 2.066-4.273 3.327v.005Z"
      />
      <path
        fill="#fff"
        d="M219.733 53.655c-.858.272-1.893.346-2.691-.158-1.006-.638-1.336-1.839-.528-2.724 1.454.06 2.992-.529 4.284-1.127a9.204 9.204 0 0 0 1.173-.653c.03.02.049.04.079.06 2.45 1.468-.814 4.123-2.317 4.602Z"
      />
      <path
        fill="#22222C"
        d="M182.974 73.456c-.512-3.718-.66-9.66-3.687-12.38-2.341-2.106-5.693-1.32-7.448 1.048a102.414 102.414 0 0 1-35.012.168c-1.459-1.142-3.115-1.7-5.018-1.058-2.193.742.395 6.961.72 8.336a499.376 499.376 0 0 1 3.219 14.436 507.551 507.551 0 0 1 5.156 29.12 480.28 480.28 0 0 1 2.124 16.355c.311 2.734.597 5.468.863 8.202.162 1.7-.079 3.47.374 5.141.631 2.349 2.613 1.587 4.589 1.197 2.12-.42 4.18-1.132 6.103-2.116 1.917-.984 3.366-2.487 5.156-3.595 2.223-1.379 3.189-4.108 3.036-6.822-.291-5.112-2.188-10.382-3.362-15.351l-4.258-17.991a57.441 57.441 0 0 0 19.953-4.603c-.404.173.508 1.602.764 1.706 1.385.553 3.633-.53 4.688-1.424 1.114-.95 1.823-2.28 2.272-3.659 1.05-3.233.902-6.827.596-10.17-.197-2.195-.537-4.365-.838-6.545l.01.005Z"
      />
      <path
        fill="#fff"
        d="M138.463 67.79c1.292 1.878 2.726 3.727 3.89 5.685.67 1.128 1.449 2.626.877 3.995-.345.826-1.272 1.449-1.873 2.081-.592.618-1.08 1.236-1.4 1.973a45.81 45.81 0 0 1-1.38-.816c-.528-.326-1.035-.118-1.321.272a495.56 495.56 0 0 0-4.141-17.867c2.544-.257 4.077 2.833 5.343 4.682l.005-.005ZM137.598 82.577c1.138.702 2.282 1.4 3.421 2.101 2.025 1.246 3.568 1.627 2.198 4.554-.853 1.824-2.095 3.58-3.273 5.305a495.143 495.143 0 0 0-2.346-11.96Z"
      />
      <path
        fill="#fff"
        d="M155.046 96.07c-1.06-4.475-2.115-8.944-3.175-13.419-.31-1.315-2.336-.761-2.025.559 2.158 9.131 4.322 18.268 6.481 27.404l3.121 13.196c.611 2.585 1.656 5.102 1.764 7.791.192 4.761-5.511 7.921-9.277 9.369-.443.173-5.525 1.503-5.525 1.305a506.483 506.483 0 0 0-5.93-44.777c.025-.03.049-.06.074-.094l3.682-5.859c.838-1.33 2.228-2.97 1.982-4.696-.163-1.142-.912-1.85-1.79-2.527-.818-.633-1.715-1.196-2.632-1.74.675-1.893 2.943-2.848 3.584-4.85.808-2.516-.917-4.885-2.253-6.838-1.07-1.567-2.366-4.044-3.953-6.13a105.486 105.486 0 0 0 33.104-.593c1.242-.213 2.42 18.762 2.553 20.7.114 1.622.291 3.243.286 4.87-.005 1.592-.3 1.839-1.72 2.412a55.484 55.484 0 0 1-6.033 2.027c-1.065.292-12.126 2.744-12.328 1.89h.01ZM177.707 92.91c-.73-.667-.468-3.297-.498-3.98-.163-3.653-.108-7.317-.483-10.956a114.255 114.255 0 0 0-2.593-15.143c-.01-.044-.029-.079-.039-.119 1.552-1.399 3.726-.538 4.732 1.325.537 1 .739 2.176.971 3.273a93.617 93.617 0 0 1 1.523 9.854c.301 3 .734 6.007.513 9.032-.109 1.488-.415 3.046-1.045 4.405-.38.826-1.366 1.83-2.14 2.284-.424.248-.729.223-.946.025h.005Z"
      />
      <path
        fill="#22222C"
        d="M97.227 133.575c-.247-3.762-2.835-6.684-5.265-9.319a4187.865 4187.865 0 0 0-12.771-13.789c-2.13-2.294-4.264-4.588-6.398-6.887-1.583-1.706-3.352-6.793-6.295-4.188-1.37 1.217-2.8 2.383-4.12 3.654-.686.658-2.544 2.339-2.544 3.362-1.494 2.947-2.135 6.516-1.7 9.804 1.015 7.683 6.5 13.843 12.756 17.941 3.647 2.393 7.66 4.292 11.707 5.908 3.342 1.335 7.147 2.235 10.548.584 2.755-1.34 4.274-4 4.077-7.07h.005Z"
      />
      <path
        fill="#fff"
        d="M84.054 138.38c-8.236-3.025-17.138-7.816-21.55-15.746-4.047-7.273-3.036-17.487 5.205-21.269.04-.02.07-.04.104-.059 6.398 6.901 12.796 13.798 19.194 20.7 2.977 3.209 7.724 6.902 8.118 11.574.552 6.516-6.896 6.338-11.07 4.805v-.005Z"
      />
      <path
        fill="#22222C"
        d="M111.634 64.498c-.197-.88-1.163-.925-1.695-.465-2.48.39-4.195 2.037-5.171 4.287-14.383 5.972-27.308 14.91-37.925 26.336-1.681-.47-3.382.148-4.476 1.622-1.503 2.027-1.296 4.632-.113 6.748 1.577 2.833 4.382 5.181 6.728 7.362 2.534 2.353 5.28 4.568 8.434 6.031 2.213 1.029 5.461 2.339 7.704.688a3.441 3.441 0 0 0 1.356-2.136c.01-.005.02-.01.03-.02a43.067 43.067 0 0 1 8.827-5.592l.557 24.478c.099 4.42-1.06 13.225 4.106 15.148.104.074.222.139.37.178 7.561 1.988 15.384 1.874 22.881-.356.414-.123.804-.554.769-1.013a563.857 563.857 0 0 0-12.377-83.291l-.005-.005Z"
      />
      <path
        fill="#fff"
        d="M110.125 91.526c.069-1.542 1.4-3.02 2.189-4.29.473-.757.941-1.514 1.415-2.27a557.387 557.387 0 0 1 2.06 11.633c-.705-.485-1.415-.97-2.12-1.454-.7-.48-1.414-.949-2.104-1.443-1.075-.766-1.504-.762-1.44-2.175ZM109.861 66.169a555.91 555.91 0 0 1 3.293 15.781c-.03.04-.059.07-.089.114-.714 1.147-1.434 2.294-2.149 3.436-2.188-.97-4.372-1.933-6.561-2.902.405-2.744.809-5.483 1.218-8.227.212-1.414.414-3.199.951-4.756.079-.129.128-.272.148-.415.592-1.484 1.548-2.685 3.189-3.036v.005ZM83.337 115.673a5.756 5.756 0 0 1-2.766-.262c-1.124-.282-2.203-.821-3.218-1.365-2.036-1.087-3.885-2.472-5.62-3.99-2.681-2.353-7.078-5.651-8.162-9.205-.346-1.132-.262-2.448.557-3.362 1.375-1.543 2.77-.668 3.963.425 1.69 1.552 3.243 3.288 4.85 4.929l6.664 6.788c.621.633 6.112 5.701 3.732 6.042Z"
      />
      <path
        fill="#fff"
        d="M102.28 147.685c-1.001-.326-1.888-.786-2.573-1.429-2.272-2.136-1.582-6.026-1.646-8.81l-.222-9.819c-.143-6.402-.29-12.8-.434-19.202a43.943 43.943 0 0 1 3.983-1.429c1.065-.326.877-1.76-.069-2.066-1.982-.643-4.254.731-5.89 1.7-1.948 1.157-4.037 2.255-5.89 3.55-1.11.771-2.165 2.156-3.663 2.027-1.7-.143-2.86-2.106-3.939-3.208-2.02-2.057-4.041-4.119-6.063-6.175-1.69-1.721-3.376-3.446-5.067-5.162-.65-.658-1.306-1.38-2.045-1.968C78.694 85.13 90.657 76.69 103.946 70.94c-.562 2.27-.922 4.588-1.203 6.907-.187 1.557-1.084 4.632-.221 6.06.483.802 6.974 3.882 7.285 3.392-.276.445-.557.89-.828 1.335-.715 1.162-1.676 2.463-1.326 3.92.261 1.093 1.168 1.756 2.021 2.384 2.144 1.582 4.402 3.026 6.6 4.529a560.941 560.941 0 0 1 5.595 47.536c.059.811-9.494 1.453-10.426 1.458-2.494.015-6.279.163-9.148-.776h-.015Z"
      />
      <path
        fill="#22222C"
        d="M106.527 120.335c-2.174-1.988-5.831-1.978-8.542-1.478-5.117.944-9.262 4.696-11.012 9.571-1.464 4.084-2.026 12.118 3.84 12.924 3.603.494 7.27-1.316 9.804-3.783 1.277.243 2.613.332 3.751-.415 1.06-.697 1.676-2.012 1.257-3.253a3.134 3.134 0 0 0-.286-.598c.188-.079.35-.228.468-.47.493-1.033.454-2.002.065-2.853.009-.009.019-.014.029-.029 1.011-1.122.922-2.319.34-3.382.173-.034.345-.119.503-.267 1.789-1.666 1.548-4.351-.222-5.962l.005-.005Z"
      />
      <path
        fill="#fff"
        d="M103.417 135.226c-.597.865-1.992.415-2.849.469-1.484.094-2.026 1.024-3.19 1.82-1.414.969-3.066 1.607-4.776 1.76-3.667.326-4.628-3.787-4.466-6.674.232-4.059 2.524-8.039 6.038-10.096 1.74-1.018 3.717-1.646 5.733-1.77 1.656-.099 8.656 1.009 5.358 4.084a.936.936 0 0 0-.207.272 8.93 8.93 0 0 0-.577-.495c-2.109-1.641-4.756-2.378-7.408-2.18-1.34.099-1.35 2.205 0 2.106 1.553-.114 3.061.079 4.485.737.7.321 3.594 1.948 2.953 3.065a5.945 5.945 0 0 0-.71-.489c-2.267-1.365-4.929-1.667-7.497-1.217-1.326.233-.764 2.265.557 2.032 1.4-.247 2.83-.193 4.185.248 1.01.326 3.677 1.354 2.947 2.882a.885.885 0 0 0-.064.178c-1.883-1.132-4.742-1.33-6.491-1.211-1.341.093-1.351 2.2 0 2.106 1.705-.119 3.455.074 5.017.81.562.267 1.504.787.957 1.578l.005-.015Z"
      />
      <path
        fill="#22222C"
        d="M102.344 56.212c1.538.183 3.042-.223 4.234-1.132a6.253 6.253 0 0 0 2.411-4.212 6.738 6.738 0 0 0-1.375-4.97 6.484 6.484 0 0 0-4.309-2.422c-1.542-.183-3.179.223-4.367 1.078-.759.549-1.311 1.241-1.631 2.062-.286.721-.434 1.522-.449 2.383a8.597 8.597 0 0 0 1.242 4.543c.907 1.483 2.46 2.457 4.249 2.67h-.005Z"
      />
      <path
        fill="#fff"
        d="M99.69 52.529c1.257 2.037 4.091 2.437 5.91.91 1.794-1.513 1.961-3.995.714-5.918-.655-1.014-1.68-1.825-2.859-2.121-1.173-.297-2.474.059-3.42.786-1.065.816-1.405 1.987-1.316 3.297a6.724 6.724 0 0 0 .976 3.04l-.005.006Z"
      />
      <path
        fill="#22222C"
        d="M139.821 54.124c1.538.183 3.041-.222 4.234-1.132a6.232 6.232 0 0 0 2.41-4.212 6.738 6.738 0 0 0-1.375-4.969 6.48 6.48 0 0 0-4.308-2.422c-1.543-.183-3.179.222-4.367 1.078-.759.549-1.311 1.24-1.632 2.061-.286.722-.434 1.523-.448 2.383a8.597 8.597 0 0 0 1.242 4.544c.907 1.483 2.459 2.457 4.249 2.67h-.005Z"
      />
      <path
        fill="#fff"
        d="M137.166 50.436c1.257 2.037 4.091 2.437 5.91.91 1.794-1.513 1.962-3.995.715-5.918-.656-1.014-1.681-1.825-2.859-2.121-1.173-.297-2.474.06-3.421.786-1.065.816-1.405 1.987-1.316 3.298a6.722 6.722 0 0 0 .976 3.04l-.005.005Z"
      />
      <path
        fill="#22222C"
        d="M132.419 69.886c.024-.632.044-1.265.039-1.903a39.822 39.822 0 0 0-.266-4.128c-.148-1.3-.094-2.635-.128-3.94-.01-.579-.168-1.355.153-1.87.325-.519 1.109-.746 1.602-1.082.695-.475 1.35-1.038 1.966-1.612a18.73 18.73 0 0 0 3.451-4.32c2.972-5.128 4.16-11.703 3.623-20.098-.488-7.604-2.908-21.017-13.659-25.402a5.023 5.023 0 0 0-.345-.124c-.108-.034-.222-.069-.365-.133a17.347 17.347 0 0 0-5.19-1.444c-3.13-.37-6.477.06-9.681 1.231-5.062 1.86-9.139 5.958-11.48 11.54-1.809 4.316-2.672 9.467-2.568 15.31.133 7.412 1.464 13.587 3.948 18.358a17.927 17.927 0 0 0 4.747 5.77c.67.538 1.385 1.028 2.119 1.463.429.252.449.44.518 1.048l.034.302a69.897 69.897 0 0 1 .474 5.023 54.162 54.162 0 0 1-.025 6.704c-.005.079 8.301 22.505 14.349 20.374 2.933-1.034 5.254-5.023 5.875-8.044.715-3.486.705-7.144.73-10.689.005-.781.044-1.557.079-2.338v.004Z"
      />
      <path
        fill="#fff"
        d="M103.52 45.25c1.41 4.524 3.914 8.297 7.887 10.69.222.192.419.405.591.627.143.193.262.396.375.604.212.642.38 1.295.483 1.967 0 .03.02.055.035.074a58.61 58.61 0 0 1 .394 11c.661 4.475 9.237 19.02 12.338 18.13 2.164-.623 3.095-3.342 3.633-5.275.778-2.783 1.084-5.7 1.271-8.583.128-1.957.242-3.92.192-5.883-.049-1.889-.384-3.752-.473-5.636-.079-1.7-.315-3.58.345-5.23.735-1.85 2.564-2.473 3.944-3.729a16.7 16.7 0 0 0 3.49-4.484c2.39-4.499 3.036-9.789 3.056-14.817.034-9.314-2.145-21.867-11.121-26.717-.404-.252-.547-.331-.833-.494-4.106-2.373-8.946-2.383-13.511-1.093-4.544 1.285-8.216 4.356-10.533 8.46-2.272 4.029-3.278 8.67-3.564 13.259-.355 5.715.301 11.658 2.006 17.13h-.005Z"
      />
      <path
        fill="#D3DCDF"
        d="M129.954 7.993c-.405-.252-.548-.331-.833-.494-4.106-2.374-8.947-2.383-13.511-1.093-4.545 1.285-8.217 4.356-10.534 8.46-2.272 4.028-3.278 8.67-3.564 13.259-.335 5.389.242 10.98 1.735 16.186-.019-1.878.227-3.732.843-5.537.917-2.7 2.79-4.776 4.087-7.268.921-1.77 1.498-3.648 1.882-5.581 2.258.41 4.511-.267 6.192-1.884 2.873 1.375 6.161.682 8.325-1.602 2.371 1.192 5.23.747 7.103-1.162a9.014 9.014 0 0 0 5.121.134c1.425 4.47 2.844 8.944 4.269 13.413v-.109c.034-9.314-2.144-21.867-11.12-26.717l.005-.005ZM121.871 57.977c-1.775.148-4.466-.416-5.033 1.809-.562 2.2 1.4 5.705 2.248 7.629 1.37 3.114 3.051 6.5 5.363 9.032 1.71 1.874 3.874 2.25 5.762 1.483.133-1.147.241-2.304.315-3.45.128-1.958.242-3.921.192-5.884-.049-1.888-.384-3.752-.473-5.636-.079-1.7-.315-3.58.345-5.23.252-.628.631-1.108 1.07-1.528-3.209.865-6.467 1.488-9.789 1.765v.01Z"
      />
      <path
        fill="#22222C"
        d="M104.132 53.908c-.35-1.206-1.572-2.022-2.696-1.775h-.03c-1.794.227-3.282 1.612-3.095 3.53.177 1.815 1.942 3.105 3.721 2.615 1.785-.489 2.578-2.699 2.095-4.365l.005-.005Z"
      />
      <path
        fill="#fff"
        d="M101.337 56.8c-.779.05-1.415-.598-1.45-1.36-.038-.939.893-1.611 1.736-1.72.197-.024.404-.02.577.074.207.114.33.346.399.574.281.924-.133 2.363-1.262 2.437V56.8Z"
      />
      <path
        fill="#22222C"
        d="M145.21 50.947c-.35-1.207-1.572-2.023-2.696-1.775h-.029c-1.795.227-3.283 1.611-3.096 3.53.177 1.814 1.942 3.105 3.722 2.615 1.784-.49 2.577-2.7 2.094-4.365l.005-.005Z"
      />
      <path
        fill="#fff"
        d="M142.415 53.839c-.779.05-1.414-.598-1.449-1.36-.039-.94.892-1.611 1.735-1.72.197-.025.404-.02.577.074.207.114.33.346.399.573.281.925-.133 2.364-1.262 2.438v-.005Z"
      />
      <path
        fill="#22222C"
        d="M114.354 36.352c-.552-.904-1.479-.633-1.943.1-.65 1.028-.414 2.748.474 3.564.576.534 1.587.45 1.843-.396.33-1.097.222-2.284-.379-3.268h.005ZM131.907 33.544c-.458-.954-1.405-.776-1.942-.094-.754.959-.685 2.694.113 3.594.523.589 1.538.608 1.874-.207.438-1.059.453-2.25-.05-3.293h.005ZM125.174 33.979c-.473-1.256-2.504-.712-2.025.559.483 1.275.892 2.566 1.173 3.9.118.56.345 1.36.291 1.924-.183 1.77-1.726 1.512-2.973 1.409-1.345-.11-1.341 1.997 0 2.106 2.135.173 4.54-.242 5.003-2.71.429-2.264-.685-5.122-1.473-7.188h.004ZM126.796 45.755c-3.338 1.3-6.847 1.078-10.071-.43-1.222-.574-1.843 1.439-.636 2.007 3.751 1.755 7.833 1.79 11.673.292 1.257-.49.286-2.358-.966-1.869ZM137.668 9.12c.128-3.01-2.8-5.28-5.561-4.177-.798-2.769-3.913-4.04-6.472-2.541a5.155 5.155 0 0 0-8.665-.08c-2.869-1.636-6.418-.242-7.374 2.942-3.046-.642-5.743 1.32-6.171 4.42-2.973.208-4.999 2.779-4.407 5.716-2.765 1.087-2.997 4.84-.064 6.422a.96.96 0 0 0 .227.094c1.759 3.44 5.934 4.177 8.463 1.394 2.755 1.35 5.821.998 8.227-.93 2.686 1.276 5.89.722 7.822-1.587 2.332 1.187 5.132.796 7.005-1.068 3.12 1.602 7.334-.558 9.217-3.268 1.849-2.66.952-6.966-2.252-7.336h.005Z"
      />
      <path
        fill="#22222C"
        d="M138.068 15.459c-.473.648-1.193 1.186-1.863 1.612-1.484.94-3.49 1.73-5.038.47-.429-.352-1.139-.297-1.474.158-1.355 1.819-3.756 2.259-5.609.875-.414-.307-1.164-.337-1.474.158-1.331 2.13-4.008 2.986-6.211 1.567-.522-.336-1.03-.277-1.474.158-1.878 1.85-4.682 2.116-6.886.683-.443-.292-1.129-.346-1.474.158-.685 1.008-1.843 1.775-3.1 1.621-1.572-.192-2.031-1.616-3.086-2.546-.448-.395-1.617-.984-1.646-1.696-.03-.741 1.222-1.226 1.735-1.478.665-.06 1.134-.81.863-1.434-.947-2.185.419-4.31 2.913-3.984.705.094 1.242-.46 1.212-1.152-.128-2.67 2.189-4.084 4.515-3.06.582.257 1.405-.1 1.464-.792.242-2.833 3.096-4.044 5.25-2.086.488.445 1.375.218 1.617-.39 1.193-3.011 4.899-2.957 5.989.098.286.796 1.276.816 1.794.272 1.72-1.82 4.372-.544 4.121 1.919-.084.805.936 1.7 1.661 1.013 1.745-1.651 4.559-.37 3.564 2.195-.262.678.241 1.646 1.069 1.478 2.598-.529 2.593 2.794 1.573 4.178l-.005.005ZM102.428 26.974c-.675 0-.675 1.053 0 1.053.676 0 .676-1.053 0-1.053ZM106.296 26.123h-.079c-.675 0-.675 1.053 0 1.053h.079c.676 0 .676-1.053 0-1.053ZM104.13 30.083c-.676 0-.676 1.053 0 1.053.675 0 .675-1.053 0-1.053ZM106.857 29.752v.08c0 .677 1.05.677 1.05 0v-.08c0-.677-1.05-.677-1.05 0ZM102.892 34.122h-.079c-.675 0-.675 1.053 0 1.053h.079c.675 0 .675-1.053 0-1.053ZM105.292 33.5c-.676 0-.676 1.053 0 1.053.675 0 .675-1.053 0-1.053ZM103.665 37.539c-.676 0-.676 1.053 0 1.053.675 0 .675-1.053 0-1.053ZM138.134 20.685h-.079c-.675 0-.675 1.053 0 1.053h.079c.675 0 .675-1.053 0-1.053ZM139.452 24.492c-.676 0-.676 1.053 0 1.053.675 0 .675-1.053 0-1.053ZM139.37 27.286c-.676 0-.676 1.053 0 1.053.675 0 .675-1.053 0-1.053ZM137.745 23.093h-.078c-.676 0-.676 1.053 0 1.053h.078c.676 0 .676-1.053 0-1.053ZM139.77 30.345c-.024.05-.049.104-.079.154-.3.603.602 1.137.907.529.025-.05.05-.104.079-.154.301-.603-.601-1.137-.907-.529Z"
      />
      <path
        fill="#fff"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M28.318 69.06h12.255l13.633 9.26c2.022 1.377 4.749-.093 4.749-2.596V69.06c9.191 0 15.318-6.258 15.318-15.644V34.644C74.273 25.257 68.146 19 58.955 19H28.318C19.128 19 13 25.257 13 34.644v18.772c0 9.386 6.127 15.644 15.318 15.644Z"
      />
      <path
        stroke="#22222C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m34.852 46.931 5.375 5.376L54.56 37.972"
      />
    </svg>
  );
};
export default ApprovedQuiz;

export function disableApplicantForm({ schema, setSchema }: { schema: any; setSchema: (args: any) => void }) {
  setSchema({
    ...schema,
    foodExperience: {
      ...schema.foodExperience,
      disabled: true
    },
    managementExperience: {
      ...schema.managementExperience,
      disabled: true
    },
    profilePicture: {
      ...schema.profilePicture,
      disabled: true
    },
    resume: {
      ...schema.resume,
      disabled: true
    },
    servingOthers: {
      ...schema.servingOthers,
      disabled: true
    },
    videoPresentation: {
      ...schema.videoPresentation,
      disabled: true
    }
  });
}

import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';

const LegalName = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    const { legalFirstName, legalLastName, legalMiddleName } = params.data.personal;
    formHandler.setFullForm({ legalFirstName, legalLastName, legalMiddleName: legalMiddleName || '' });
  }, []);

  return (
    <Base
      header="Full legal name"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Save changes" disabled={!formHandler.valid} onClick={() => onClose(formHandler.data)} />}
    >
      <div className="modal-legal-name">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default LegalName;

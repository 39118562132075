import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import PageContent from './content';
import { ChannelSettingsProvider } from '@sendbird/uikit-react/ChannelSettings/context';
import { GroupChannelProvider } from '@sendbird/uikit-react/GroupChannel/context';

const MyChatsPage = () => {
  const { channelUrl, hasChats, data } = useSelector((state: RootState) => state.user);

  if (!hasChats) {
    return <div>No chats</div>;
  }

  return (
    <div className="my-chats-page page-no-padding">
      <ChannelSettingsProvider channelUrl={channelUrl}>
        <GroupChannelProvider channelUrl={channelUrl}>
          <PageContent channelUrl={channelUrl} userId={data.id} />
        </GroupChannelProvider>
      </ChannelSettingsProvider>
    </div>
  );
};

export default MyChatsPage;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { DocumentDownload, DocumentUpload, Edit2, Eye } from 'iconsax-react';
import endpoints from 'constants/endpoints';
import { PermissionStatus } from 'constants/permissions';
import { isPluginAvailable } from 'constants/capacitor';
import { certificationStatus } from 'constants/certifications';
import { AlcoholInstructions, Button, Card, ProfilePicture, ToggleSwitch } from 'components';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import useApi from 'hooks/useApi';
import { downloadFromBase64 } from 'helpers/data';
import { getTruncatedSSN } from 'utils/data';
import { ModalsList } from 'types/modal';
import { RootState } from 'types/store';
import { ALCOHOL_CERTIFICATE, AlcoholCertificateKey } from 'constants/alcoholCertificate';
import { UNIFORM_STATUS, UniformStatus } from 'constants/uniforms';
import { statuses } from 'constants/backgroundChecks';

export const confirmUploadMessage = {
  title: 'Confirm upload',
  message: 'Are you sure you want to upload this file?'
};

type EmployeeVenueType = {
  id: string;
  name: string;
  site_url: string | null;
};

const MyProfilePage = () => {
  const { notificationToken } = useSelector((state: RootState) => state.user);
  const [permissions, setPermissions] = useState<boolean>(false);
  const [downloading, setDownloading] = useState(false);
  const [documentName, setDocumentName] = useState<any>();

  const notify = useToast();
  const modal = useModal();
  const api = useApi();
  const apiUpdate = useApi();
  const apiDocuments = useApi();
  const apiSettingsNotifications = useApi();
  const data = api?.response?.data;
  const pendingApproval = data?.alcoholCertification?.status === certificationStatus.PENDING_APPROVAL;
  const approved = data?.alcoholCertification?.status === certificationStatus.COMPLETED;
  // const pluralLink = data?.employeeVenues?.length > 1 ? 'links' : 'link';
  const multipleInstructions = data?.alcoholInstructions?.length > 1;
  const profilePicture = data?.profilePhoto?.url;

  const pendingApprovalUniform = data?.uniform?.status === UniformStatus.PENDING_APPROVAL;
  const approvedUniform = data?.uniform?.status === UniformStatus.APPROVED;

  const getTaxes = () => {
    api.fetchData(endpoints.GET_MY_TAXES.get());
  };

  const handleSwitch = () => {
    if (!apiUpdate.isLoading) {
      apiUpdate.fetchData(endpoints.EDIT_PERSONAL_INFO.get(), {
        w2ElectronicConsent: !data?.w2ElectronicConsent
      });
    }
  };

  const handleSwitchNotifications = async () => {
    if (!permissions) {
      openNotificationSettings();
      return;
    }

    if (data?.pushNotificationsEnabled) {
      modal.open(
        ModalsList.DISABLE_NOTIFICATIONS,
        async data => {
          if (data) {
            await apiSettingsNotifications.fetchData(endpoints.SETTINGS_NOTIFICATIONS.get());
          }
        },
        { title: 'Disable Notifications' }
      );
    } else {
      await api.fetchData(endpoints.SAVE_NOTIFICATION_TOKEN.get(), { token: notificationToken });
      await apiSettingsNotifications.fetchData(endpoints.SETTINGS_NOTIFICATIONS.get());
    }
  };

  const handleDownloadFile = (id: string, name: string) => () => {
    apiDocuments.fetchData(endpoints.GET_TAXES_DOCUMENTS.get({ id }));
    setDocumentName(name);
  };

  const handleUpdate = (modalKey: ModalsList) => () => {
    modal.open(
      modalKey,
      (data: any) => {
        !!data && apiUpdate.fetchData(endpoints.EDIT_PERSONAL_INFO.get(), data);
      },
      { data }
    );
  };

  const openInNewTab = (url: string) => () => {
    window.open(url, '_blank', 'noreferrer');
  };

  const openNotificationSettings = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.AppNotification,
          optionIOS: IOSSettings.App
        });
      } else {
        console.log('This feature is only available on native platforms (Android/iOS).');
      }
    } catch (error) {
      console.error('Error opening notification settings:', error);
    }
  };

  const handleUpload = () => {
    modal.open(
      ModalsList.FILE_UPLOADER,
      async flag => {
        if (flag) {
          getTaxes();
          notify('File uploaded successfully');
        }
      },
      {
        title: 'Upload Alcohol Certificate',
        message: 'Press here to upload your Alcohol Certification files - (Maximum of 2 files allowed)',
        maxFiles: 2,
        accept: {
          'image/jpeg': ['.jpeg', '.png', '.jpg'],
          'application/pdf': ['.pdf']
        },
        endpoint: endpoints.SAVE_CERTIFICATION.get()
      }
    );
  };

  const handleUploadUniformFiles = () => {
    modal.open(
      ModalsList.FILE_UPLOADER,
      async flag => {
        if (flag) {
          getTaxes();
          notify('File uploaded successfully');
        }
      },
      {
        title: 'Upload Uniform Files',
        message: 'Press here to upload your Uniform files - (Maximum of 5 files allowed)',
        maxFiles: 5,
        accept: {
          'image/jpeg': ['.jpeg', '.png', '.jpg']
        },
        endpoint: endpoints.SAVE_EMPLOYEE_UNIFORMS.get()
      }
    );
  };

  const handleOpenDocument = () => {
    const url = data?.alcoholInstructions?.[0]?.url;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const alcoholCertificateFiles = data?.alcoholCertification?.files || [];

  const handleViewAlcoholCertificate = () => {
    modal.open(ModalsList.VIEW_ALCOHOL_CERTIFICATE, undefined, { files: alcoholCertificateFiles });
  };

  useEffect(() => {
    getTaxes();
  }, [apiUpdate.response, apiSettingsNotifications.response]);

  useEffect(() => {
    if (apiDocuments.response) {
      setDownloading(true);
      downloadFromBase64(apiDocuments.response.pdf, documentName).then(() => {
        setDownloading(false);
      });
    }
  }, [apiDocuments.response]);

  useEffect(() => {
    if (isPluginAvailable) {
      const checkPermissions = async () => {
        PushNotifications.checkPermissions().then(result => {
          if (result.receive === PermissionStatus.GRANTED) {
            setPermissions(true);
          } else {
            setPermissions(false);
          }
        });
      };

      checkPermissions();

      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          checkPermissions();
        }
      });

      return () => {
        App.removeAllListeners();
      };
    }
  }, []);

  return (
    <div className="page-my-profile">
      <Card title="My Profile"> </Card>

      <Card
        title="Profile Picture"
        description="Upload a professional headshot as your profile picture for E2S event chats."
      >
        <Card.Item>
          <ProfilePicture imageUrl={profilePicture} handleRefresh={getTaxes} />
        </Card.Item>
      </Card>

      {data?.employeeVenues.filter((item: EmployeeVenueType) => item.site_url).length > 0 && (
        <Card title="Notion Site" description="Visit the following to access more employee resources">
          {data?.employeeVenues
            .filter((item: EmployeeVenueType) => item.site_url)
            .map((venue: EmployeeVenueType) => (
              <Card.Item key={venue.id} grid>
                <a className="body-2" href={venue.site_url!} target="_blank" rel="noreferrer">
                  {venue.name}
                </a>
              </Card.Item>
            ))}
        </Card>
      )}

      {Capacitor.isNativePlatform() && (
        <Card
          title="Notification Settings"
          description={
            permissions
              ? 'Enable or disable push notifications.'
              : 'You have disabled the permissions to receive notifications. Go to your phone settings to enable them'
          }
        >
          <Card.Item grid>
            <div className="body-2">Push Notifications</div>
            <ToggleSwitch
              value={data?.pushNotificationsEnabled}
              onChange={handleSwitchNotifications}
              disabled={api.isLoading || apiSettingsNotifications.isLoading}
            />
          </Card.Item>
        </Card>
      )}

      <Card
        title="W-2 Delivery Options"
        description="By consenting to digital delivery, you'll be able to access, download, and print your W-2 online the moment it becomes available on January 5th, 2025"
      >
        <Card.Item grid>
          <div className="body-2">Digital delivery</div>
          <ToggleSwitch
            value={data?.w2ElectronicConsent || false}
            onChange={handleSwitch}
            disabled={apiUpdate.isLoading || api.isLoading}
          />
        </Card.Item>
      </Card>

      <Card title="Personal Information" description="Review the information we have on file to ensure it is accurate.">
        <Card.Item grid>
          <div className="body-2">Full Legal Name</div>
          <div className="body-2">{data?.personal?.fullLegalName}</div>
          <div>
            <Button
              icon={<Edit2 />}
              text="Update"
              variant="alternative"
              disabled={!data}
              small
              onClick={handleUpdate(ModalsList.LEGAL_NAME)}
            />
          </div>
        </Card.Item>

        <Card.Item grid>
          <div className="body-2">Residential Address</div>
          <div className="body-2">{data?.personal?.planeResidentialAddress}</div>
          <div>
            <Button
              icon={<Edit2 />}
              text="Update"
              variant="alternative"
              disabled={!data}
              small
              onClick={handleUpdate(ModalsList.RESIDENTIAL_ADDRESS)}
            />
          </div>
        </Card.Item>

        <Card.Item grid>
          <div className="body-2">Email</div>
          <div className="body-2">{data?.personal?.email}</div>
          <div>
            <Button
              icon={<Edit2 />}
              text="Update"
              variant="alternative"
              disabled={!data}
              small
              onClick={handleUpdate(ModalsList.EMAIL)}
            />
          </div>
        </Card.Item>

        <Card.Item grid>
          <div className="body-2">Phone Number</div>
          <div className="body-2">{data?.personal?.phoneNumber}</div>
          <div>
            <Button
              icon={<Edit2 />}
              text="Update"
              variant="alternative"
              disabled={!data}
              small
              onClick={handleUpdate(ModalsList.PHONE_NUMBER)}
            />
          </div>
        </Card.Item>

        <Card.Item grid>
          <div className="body-2">Social Security Number</div>
          <div className="body-2">{getTruncatedSSN(data?.personal?.truncatedTaxPayerNumber)}</div>
          <div> </div>
        </Card.Item>
      </Card>

      {data?.backgroundCheck?.status && (
        <Card title="Background Check" description="This is the status of your background check">
          <Card.Item grid>
            <div className="body-2">Status</div>
            <div className="body-2 capitalize">{statuses[data?.backgroundCheck?.status]}</div>
          </Card.Item>
        </Card>
      )}

      {data?.alcoholCertification?.status && (
        <Card title="Alcohol Certificate" description="This is the information of your alcohol certificate">
          {data?.alcoholInstructions?.length > 0 && (
            <Card.Item grid>
              <div className="body-2">Alcohol Training Instructions</div>
              <div className=""></div>
              <AlcoholInstructions
                data={data?.alcoholInstructions}
                isMultiple={multipleInstructions}
                onOpenDocument={handleOpenDocument}
              />
            </Card.Item>
          )}

          <Card.Item grid>
            <div className="body-2">My Certificate</div>
            <div />
            <div>
              <Button
                icon={<Eye />}
                text="View"
                variant="alternative"
                small
                onClick={handleViewAlcoholCertificate}
                disabled={alcoholCertificateFiles.length === 0}
              />
            </div>
          </Card.Item>

          <Card.Item grid>
            <div className="body-2">Status</div>
            <div className="body-2 capitalize">
              {ALCOHOL_CERTIFICATE[data?.alcoholCertification?.status as AlcoholCertificateKey]}
            </div>
            <div>
              <Button
                icon={<DocumentUpload />}
                text="Upload"
                variant="alternative"
                small
                onClick={handleUpload}
                disabled={pendingApproval || approved}
              />
            </div>
          </Card.Item>
        </Card>
      )}

      <Card title="My Uniform" description="Please submit the uniform you will be wearing for the events">
        <Card.Item grid>
          <div className="body-2">Status</div>
          <div className="body-2 capitalize">{UNIFORM_STATUS[data?.uniform?.status as UniformStatus]}</div>
          <div>
            <Button
              icon={<DocumentUpload />}
              text="Upload"
              variant="alternative"
              small
              onClick={handleUploadUniformFiles}
              disabled={pendingApprovalUniform || approvedUniform}
            />
          </div>
        </Card.Item>
      </Card>

      <Card title="Documents" description="Review the documents we have on file to ensure they are accurate.">
        {data?.documents.map((file: any) => (
          <Card.Item key={file.id} grid>
            <div className="body-2">{file.label}</div>
            <div className="body-2">{file.year}</div>
            <div>
              <Button
                icon={<DocumentDownload />}
                text="Download"
                variant="alternative"
                small
                onClick={handleDownloadFile(file.id, file.label)}
                disabled={downloading}
              />
            </div>
          </Card.Item>
        ))}
      </Card>
    </div>
  );
};

export default MyProfilePage;

import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

type Props = {
  children: any;
  value: string;
  fixed?: boolean;
  disabled?: boolean;
};

const Tooltip = ({ children, value }: Props) => {
  return (
    <StatefulToolTip
      parent={children}
      position="bottom"
      arrow="center"
      group={value}
      tooltipTimeout={0}
      useHover={false}
    >
      {value}
    </StatefulToolTip>
  );
};

export default Tooltip;

import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card } from 'components';
import { Props } from '../types';

const TaxWithhold = ({ step, handleContinue }: Props) => {
  const api = useApi();
  const apiTaxWithhold = useApi();
  const url = api?.response?.url;

  const handleSubmit = () => {
    apiTaxWithhold.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step });
  };

  useEffect(() => {
    apiTaxWithhold.success && handleContinue();
  }, [apiTaxWithhold.success]);

  useEffect(() => {
    api.fetchData(endpoints.GET_TAX_WITH_HOLD_URL.get());
  }, []);

  return (
    <div className="tax-withhold">
      <Card title="Tax withhold">
        <Card.Item>{!!url && <iframe id="tax-withhold-iframe" title="Tax withhold" src={url} />}</Card.Item>
        <Card.Footer>
          <Button text="Continue" onClick={handleSubmit} loading={api.isLoading || apiTaxWithhold.isLoading} />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default TaxWithhold;

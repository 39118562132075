import React, { useState, useCallback, useEffect } from 'react';
import useApi from 'hooks/useApi';
import { Button } from 'components';
import { ViewerFile, ToggleSwitch, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { ArrowRight2 } from 'iconsax-react';
import { Props } from 'types/modal';
import { generateFilename, base64ToFile } from 'helpers/data';
import endpoints from 'constants/endpoints';
import schema from './schema';
import Base from '../base';

enum Steps {
  INSTRUCTIONS,
  SUMMARY_YOUR_RIGHTS_UNDER_FCRA,
  CONSUMER_REPORT_DISCLOSURE_DOCUMENT,
  ACKNOWLEDGMENT_OF_RECEIPT_OF_BACKGROUND_CHECK_DOCUMENTS,
  SIGNING
}

const RequestBackgroundCheckModal = ({ closing, onClose }: Props) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const [summaryYourRightsUnderFcraToggleSwitchValue, setSummaryYourRightsUnderFcraToggleSwitchValue] =
    useState<boolean>(false);
  const [consumerReportDisclosureDocumentToggleSwitchValue, setConsumerReportDisclosureDocumentToggleSwitchValue] =
    useState<boolean>(false);
  const [signatureDataUrl, setSignatureDataUrl] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.INSTRUCTIONS);

  useEffect(() => {
    api.fetchData(endpoints.GET_FAIR_CREDIT_REPORTING_ACT_FILE.get());
  }, []);

  const signatureCanvasRef = useCallback(
    (canvas: HTMLCanvasElement | null) => {
      if (canvas !== null) {
        const context: CanvasRenderingContext2D = canvas.getContext('2d')!;
        context.fillStyle = 'aliceblue';
        context.fillRect(0, 0, canvas.width, canvas.height);

        if (formHandler.data.legalName) {
          context.fillStyle = 'black';
          context.font = 'bold 48px "Brush Script MT", cursive';
          context.textAlign = 'center';
          context.textBaseline = 'middle';

          context.fillText(
            formHandler.data.legalName.toLowerCase().replace(/(^| )(\w)/g, (s: string) => s.toUpperCase()),
            canvas.width / 2,
            canvas.height / 2
          );

          setSignatureDataUrl(canvas.toDataURL('image/png'));
        }
      }
    },
    [formHandler.data]
  );

  const handleNextStep = async () => {
    if (currentStep === Steps.INSTRUCTIONS) {
      setCurrentStep(Steps.SUMMARY_YOUR_RIGHTS_UNDER_FCRA);
    } else if (currentStep === Steps.SUMMARY_YOUR_RIGHTS_UNDER_FCRA) {
      setCurrentStep(Steps.CONSUMER_REPORT_DISCLOSURE_DOCUMENT);
    } else if (currentStep === Steps.CONSUMER_REPORT_DISCLOSURE_DOCUMENT) {
      setCurrentStep(Steps.ACKNOWLEDGMENT_OF_RECEIPT_OF_BACKGROUND_CHECK_DOCUMENTS);
    } else if (currentStep === Steps.ACKNOWLEDGMENT_OF_RECEIPT_OF_BACKGROUND_CHECK_DOCUMENTS) {
      setCurrentStep(Steps.SIGNING);
    } else if (currentStep === Steps.SIGNING) {
      const fileName = generateFilename('background-check-consent-signature', 'png');
      const signatureFile = await base64ToFile(signatureDataUrl, fileName);

      onClose({
        success: true,
        signatureText: formHandler.data.legalName,
        signatureDate: new Date(),
        signatureFile
      });
    }
  };

  return (
    <Base
      header="Confirm Background Check"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" onClick={() => onClose({ success: false })} variant="secondary" capitalize />
          <Button
            text={
              currentStep === Steps.ACKNOWLEDGMENT_OF_RECEIPT_OF_BACKGROUND_CHECK_DOCUMENTS
                ? 'Click To Sign'
                : currentStep === Steps.SIGNING
                ? 'Start Background Check'
                : 'Next'
            }
            icon={currentStep !== Steps.SIGNING ? <ArrowRight2 /> : null}
            iconOnRight={true}
            variant="quaternary"
            onClick={handleNextStep}
            capitalize
            disabled={
              currentStep === Steps.SUMMARY_YOUR_RIGHTS_UNDER_FCRA
                ? !summaryYourRightsUnderFcraToggleSwitchValue
                : currentStep === Steps.CONSUMER_REPORT_DISCLOSURE_DOCUMENT
                ? !consumerReportDisclosureDocumentToggleSwitchValue
                : currentStep === Steps.SIGNING
                ? !formHandler.valid
                : false
            }
          />
        </>
      }
    >
      <div className="request-background-check-modal">
        {currentStep === Steps.INSTRUCTIONS && (
          <div>
            <p>
              Please review and acknowledge receipt of the following documents in order to start your background check.
            </p>
          </div>
        )}
        {currentStep === Steps.SUMMARY_YOUR_RIGHTS_UNDER_FCRA && (
          <div>
            <p className="document-name">A Summary of Your Rights Under the Fair Credit Reporting Act</p>
            {api.response?.file && <ViewerFile fileExtension="pdf" fileUrl={api.response?.file} />}
            <hr />
            <p>
              I acknowledge receipt of the <em>A Summary of Your Rights Under the Fair Credit Reporting Act</em> and
              certify that I have read and understand this document
            </p>
            <div className="toggle-switch-container">
              <ToggleSwitch
                value={summaryYourRightsUnderFcraToggleSwitchValue}
                disabled={false}
                onChange={e => setSummaryYourRightsUnderFcraToggleSwitchValue(e)}
              />
            </div>
          </div>
        )}
        {currentStep === Steps.CONSUMER_REPORT_DISCLOSURE_DOCUMENT && (
          <div>
            <p className="document-name">Consumer Report Disclosure Document</p>
            <p>
              Eats2Seats (&quot;the Company&quot;) may obtain information about you from a third party consumer
              reporting agency for employment purposes. Thus, you may be the subject of &quot;consumer reports&quot;
              which may include information about your character, general reputation, personal characteristics, and/or
              mode of living. These reports may contain information regarding your criminal history, social security
              verification, motor vehicle records (&quot;driving records&quot;), education or employment history, or
              other similar background check information.
            </p>
            <hr />
            <p>
              I acknowledge receipt of the <em>Consumer Report Disclosure</em> and certify that I have read and
              understand this document
            </p>
            <div className="toggle-switch-container">
              <ToggleSwitch
                value={consumerReportDisclosureDocumentToggleSwitchValue}
                disabled={false}
                onChange={e => setConsumerReportDisclosureDocumentToggleSwitchValue(e)}
              />
            </div>
          </div>
        )}
        {currentStep === Steps.ACKNOWLEDGMENT_OF_RECEIPT_OF_BACKGROUND_CHECK_DOCUMENTS && (
          <div>
            <p className="document-name">Acknowledgment of Receipt of Background Check Documents</p>
            <p>
              I acknowledge receipt of the separate documents entitled below and certify that I have read and understand
              those documents.
            </p>
            <ul className="documents-list">
              <li>Consumer Report Disclosure</li>
              <li>A Summary of Your Rights Under the Fair Credit Reporting Act</li>
            </ul>
            <p className="document-name">Authorization Document</p>
            <p>
              I hereby authorize the obtaining of &quot;consumer reports&quot; by Eats2Seats (&quot;the Company&quot;)
              at any time after receipt of this authorization and throughout my employment, if applicable, for
              employment purposes. To this end, I hereby authorize any law enforcement agency, administrator, state or
              federal agency, institution, school or university (public or private), information service bureau,
              employer, or insurance company to furnish any and all background information requested by{' '}
              <strong>AssureHire</strong>, 2206 Plaza Drive Suite 100, Rocklin, CA 95765,{' '}
              <a href="tel:1-737-258-2571">1-737-258-2571</a>,{' '}
              <a href="https://assurehire.com" target="_blank" rel="noreferrer">
                assurehire.com
              </a>{' '}
              that is necessary to prepare the reports. I agree that an electronic copy of this Authorization shall be
              as valid as the original.
            </p>
          </div>
        )}
        {currentStep === Steps.SIGNING && (
          <div>
            <div className="signature-canvas-container">
              <canvas ref={signatureCanvasRef} width={500} height={100} />
            </div>
            <DynamicForm handler={formHandler} />
          </div>
        )}
      </div>
    </Base>
  );
};

export default RequestBackgroundCheckModal;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useReducer, useState } from 'react';
import useFormHandler from './useFormHandler';
import { createSchemaFromGoogleForm } from 'helpers/googleForms';
import endpoints from 'constants/endpoints';
import useApi from './useApi';

type TrainingStep = {
  title: string;
  description: string;
  step: number;
  videos: string[];
  files: string[];
  quiz: any;
};

enum Action {
  SET_DATA = 'SET_DATA',
  ADVANCE_STEP = 'ADVANCE_STEP',
  PREV_STEP = 'PREV_STEP'
}

const initialState: TrainingStep = {
  title: '',
  description: '',
  step: 0,
  videos: [],
  files: [],
  quiz: null
};

type TrainingStepAction =
  | { type: Action.SET_DATA; payload: TrainingStep }
  | { type: Action.ADVANCE_STEP; payload: { step: number } }
  | { type: Action.PREV_STEP; payload: { step: number } };

export enum Step {
  VIDEOS,
  FILES,
  QUIZ
}

const trainingStepReducer = (state: TrainingStep, action: TrainingStepAction) => {
  switch (action.type) {
    case Action.SET_DATA:
      return { ...state, ...action.payload };
    case Action.ADVANCE_STEP:
      return { ...state, step: action.payload.step };
    default:
      return state;
  }
};

const getInitialStep = (data: any) => {
  if (data.videos && data.videos.length > 0) {
    return Step.VIDEOS;
  }
  if (data.files && data.files.length > 0) {
    return Step.FILES;
  }
  if (data.quiz) {
    return Step.QUIZ;
  }

  return 0;
};

const useTraining = ({ trainingId }: { trainingId: string }) => {
  const [state, dispatch] = useReducer(trainingStepReducer, initialState);
  const [activeStep, setActiveStep] = useState(1);
  const [completed, setCompleted] = useState<boolean>(false);
  const formHandler = useFormHandler({});
  const api = useApi();

  const totalSteps = [
    state.videos && state.videos.length > 0 ? 1 : null,
    state.files && state.files.length > 0 ? 2 : null,
    state.quiz ? 3 : null
  ].filter(Boolean).length;

  const advanceToNextStep = () => {
    let nextStep = state.step + 1;

    if (activeStep < totalSteps) {
      setActiveStep(currentStep => currentStep + 1);
    }

    while (nextStep <= Step.QUIZ) {
      switch (nextStep) {
        case Step.VIDEOS:
          if (state.videos && state.videos.length > 0) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: nextStep } });
          }
          break;
        case Step.FILES:
          if (state.files && state.files.length > 0) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: nextStep } });
          }
          break;
        case Step.QUIZ:
          if (state.quiz) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: nextStep } });
          }
          break;
      }
      nextStep++;
    }
  };

  const prevStep = () => {
    let prevStep = state.step - 1;

    if (activeStep > 1) {
      setActiveStep(currentStep => currentStep - 1);
    }

    while (prevStep >= 0) {
      switch (prevStep) {
        case Step.QUIZ:
          if (state.quiz) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: prevStep } });
          }
          break;
        case Step.FILES:
          if (state.files && state.files.length > 0) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: prevStep } });
          }
          break;
        case Step.VIDEOS:
          if (state.videos && state.videos.length > 0) {
            return dispatch({ type: Action.ADVANCE_STEP, payload: { step: prevStep } });
          }
          break;
      }
      prevStep--;
    }
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_TRAINING.get({ id: trainingId }));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      setCompleted(api.response.data.completed);

      const buildData = (data: any) => {
        const name: string = data.name;
        const description: string = data.quiz.length > 0 ? data.quiz[0].quiz_data.info?.description : '';
        const files: string[] = data.files.length > 0 ? data.files.map((file: any) => file.url) : null;
        const videos: string[] = data.videos.length > 0 ? data.videos.map((video: any) => video.youtube_id) : null;
        const quiz = data.quiz.length > 0 ? data.quiz[0].quiz_data : null;

        return {
          step: 0,
          data: {
            name,
            description,
            videos,
            files,
            quiz
          }
        };
      };

      const createdData = buildData(api.response.data);
      createdData.data.quiz && formHandler.setFullSchema(createSchemaFromGoogleForm(createdData.data.quiz));

      dispatch({
        type: Action.SET_DATA,
        payload: {
          title: createdData.data.name,
          description: createdData.data.description,
          step: getInitialStep(createdData.data),
          videos: createdData.data.videos,
          files: createdData.data.files,
          quiz: createdData.data.quiz
        }
      });
    }
  }, [api.loaded]);

  return {
    title: state.title,
    description: state.description,
    step: state.step,
    videos: state.videos,
    files: state.files,
    activeStep,
    totalSteps,
    currentStep: state.step + 1,
    formHandler,
    isLoading: api.isLoading,
    prevStep,
    advanceToNextStep,
    setActiveStep,
    completed,
    hasQuiz: state.quiz
  };
};

export default useTraining;

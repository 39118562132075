/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import cameraIcon from 'assets/images/camera-icon.svg';
import avatar from 'assets/images/avatar.png';
import useImagePicker from 'hooks/useImagePicker';
import { Capacitor } from '@capacitor/core';

type Props = {
  imageUrl: string;
  handleRefresh: () => void;
};

const ProfilePicture = ({ imageUrl, handleRefresh }: Props) => {
  const [image, setImage] = useState<string>();
  const [imageLoading, setImageLoading] = useState(true);
  const isWebPlatform = Capacitor.getPlatform() === 'web';

  const modal = useModal();
  const { showCamera, showPrompt } = useImagePicker(setImage);

  const openModal = () => {
    modal.open(
      ModalsList.UPLOAD_PROFILE_PICTURE,
      async (success: boolean) => {
        if (success) {
          handleRefresh();
          setImage(undefined);
        } else {
          setImage(undefined);
        }
      },
      { url: image }
    );
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    if (image) {
      openModal();
    }
  }, [image]);

  const renderProfileImage = () => {
    const imgSrc = imageLoading ? avatar : imageUrl || avatar;
    return (
      <div className="user-photo">
        <img className="camera-icon" src={cameraIcon} alt="camera" />
        <img className="profile-img" src={imgSrc} alt="profile" onLoad={handleImageLoad} />
      </div>
    );
  };

  return (
    <div className="profile-picture">
      <span className="body">Profile Picture</span>
      <div className="">
        <button onClick={isWebPlatform ? showCamera : showPrompt}>{renderProfileImage()}</button>
      </div>
    </div>
  );
};

export default ProfilePicture;

export const ssnMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

//US phone number with the format "+1 (XXX) XXX-XXXX".
export const phoneNumberMask = [
  '+',
  '1',
  ' ',
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Sanitized from '../sanitized';
import { getRelativeTime } from 'helpers/date';

type Props = {
  title: string;
  sender: string;
  created_at: string;
  message: string;
  image: string;
  onClick: () => void;
};

const UnreadNotification = ({ title, sender, created_at, message, image, onClick }: Props) => {
  return (
    <div className="unread-notification" onClick={onClick}>
      <div className="picture">
        <div className="img-container">
          <LazyLoadImage alt="profile" src={image} effect="blur" />
        </div>
      </div>
      <div className="content">
        <div className="title">
          <span className="body">{title}</span>
        </div>
        <div className="info">
          {created_at && <span className="body-2 date">{getRelativeTime(created_at).replace('about', '')}</span>}
          <small className="body-2 new-indicator">New - {sender}</small>
        </div>
        <div className="text-content">
          <div className="text-wrapper">
            <Sanitized html={message} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnreadNotification;

import formErrors from 'constants/formErrors';
import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  profilePicture: {
    value: {
      path: '',
      fileName: '',
      fileSize: 0
    },
    label: 'Profile Picture',
    input: InputsEnum.DROP_IMAGE,
    joi: joi.object({
      path: joi.string().required(),
      fileName: joi.string().required(),
      fileSize: joi.number().required()
    })
  },
  videoPresentation: {
    value: {
      path: '',
      fileName: '',
      fileSize: 0
    },
    label: 'Video Presentation',
    input: InputsEnum.DROP_VIDEO,
    joi: joi.object({
      path: joi.string().required(),
      fileName: joi.string().required(),
      fileSize: joi.number().required()
    })
  },
  resume: {
    value: {
      path: '',
      fileName: '',
      fileSize: 0
    },
    label: 'Attach Resume',
    input: InputsEnum.DROP_FILE,
    acceptedFileTypes: { 'application/pdf': ['.pdf'] },
    joi: joi.object({
      path: joi.string().required(),
      fileName: joi.string().required(),
      fileSize: joi.number().required()
    })
  },
  foodExperience: {
    value: null,
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ],
    label: 'Do you have bartending / food service experience?',
    input: InputsEnum.RADIO_SELECTOR,
    joi: joi.boolean().required()
  },
  managementExperience: {
    value: null,
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ],
    label: 'Do you have management experience?',
    input: InputsEnum.RADIO_SELECTOR,
    joi: joi.boolean().required()
  },
  servingOthers: {
    value: '',
    label: 'Do you enjoy serving others? In your words, what does it mean  to serve someone?',
    limit: 1000,
    input: InputsEnum.ALTERNATIVE_TEXT_AREA,
    type: TextInputTypes.TEXT,
    joi: joi.string().max(1000).required().messages({
      'string.pattern.invert.base': formErrors.REQUIRED,
      'string.max': formErrors.INVALID_LENGTH
    })
  }
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import endpoints from 'constants/endpoints';
import routes from 'constants/routes';
import useApi from 'hooks/useApi';
import { Button, Card, ViewerFile, YoutubeEmbed } from 'components';
import { onBoardingCompleted } from 'store/actions/user';

const videoId = 'k2gmH5BoXzI';

const I9Verification = () => {
  const [showIframe, setShowIframe] = useState(false);
  const api = useApi();
  const apiRedirect = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { url, urlPdf } = api?.response || {};

  useEffect(() => {
    api.fetchData(endpoints.GET_I9_URL.get());
  }, []);

  useEffect(() => {
    if (api.response) {
      if (api.response.completed) {
        dispatch(onBoardingCompleted());
        navigate(routes.HOME);
      }
    }
  }, [api.response, dispatch, navigate]);

  useEffect(() => {
    if (apiRedirect?.response) {
      window.location = apiRedirect.response.url;
    }
  }, [apiRedirect.response]);

  const isOnboardingCloseToCompletion = () => {
    const { sectionOne, sectionTwo, sectionThree } = api?.response || {};
    return sectionOne && sectionTwo && !sectionThree;
  };

  const renderContent = () => {
    if (api.isLoading || apiRedirect.isLoading) {
      return 'Loading...';
    }

    if (isOnboardingCloseToCompletion()) {
      return 'You are closer to finish your onboarding. We are verifying the information so you could be part of E2S soon';
    }

    return 'Something wrong happened';
  };

  return (
    <div className="i9-Verification">
      <Card title="I-9 Verification">
        {!showIframe && urlPdf && (
          <>
            <ViewerFile fileExtension="pdf" fileUrl={urlPdf} downloadBtn={false} />
            <div className="i9Video">
              <YoutubeEmbed embedId={videoId} />
            </div>
            <div className="i9btn">
              <Button variant="alternative" text="Continue" small onClick={() => setShowIframe(true)} />
            </div>
          </>
        )}
        {showIframe && url && (
          <Card.Item>
            <iframe id="payment-iframe" title="Payment" src={url} height={'840px'} />
          </Card.Item>
        )}
        {!url && !showIframe && <Card.Item>{renderContent()}</Card.Item>}
      </Card>
    </div>
  );
};

export default I9Verification;

import React, { createContext, useContext, useRef } from 'react';

type Values = {
  toolbarMiddleRef: any;
  extraButtonsRef: any;
};

const RefsContext = createContext<Values>({} as Values);

export const useRefs = () => {
  const refs = useContext(RefsContext);
  return refs;
};

export default ({ children }: { children: React.ReactNode }) => {
  const toolbarMiddleRef = useRef(null);
  const extraButtonsRef = useRef(null);
  const refs = { toolbarMiddleRef, extraButtonsRef };

  return <RefsContext.Provider value={refs}>{children}</RefsContext.Provider>;
};

import React from 'react';
import { Button, DynamicForm } from 'components';
import { forms } from 'types/signIn';

type Props = {
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
  handleSetCurrentForm: (form: forms) => () => void;
};

const Login = ({ handleSetCurrentForm, formHandler, loading, onSubmit }: Props) => {
  return (
    <>
      <h6>Sign in to an existing account</h6>
      <p>
        or{' '}
        <span className="link" onClick={handleSetCurrentForm(forms.REGISTER)}>
          create a new account
        </span>
      </p>
      <hr />
      <DynamicForm handler={formHandler} />
      <Button variant="alternative" text="Login" loading={loading} disabled={!formHandler.valid} onClick={onSubmit} />
    </>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { ApplicantStepper, Card, Spinner } from 'components';
import Education from './steps/education';
import Venue from './steps/venues';
import Resume from './steps/resume';
import Referral from './steps/referral';
import { Application, StepSequence } from './steps/types';
import Summary from './steps/summary';
import PendingReview from './steps/pendingReview';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import { useDispatch } from 'react-redux';
import { setApplicationCompleted } from 'store/actions/user';

type StepsComponents = {
  [key: number]: React.FC<{
    step: number;
    application: Application;
    handleContinue: () => void;
    handleBack: () => void;
    updateApplication?: (data: Application) => void;
  }>;
};

const stepsComponents: StepsComponents = {
  [StepSequence.VENUES]: Venue,
  [StepSequence.EDUCATION]: Education,
  [StepSequence.PERSONAL_INTRODUCTION]: Resume,
  [StepSequence.HOW_DID_YOU_HEAR_ABOUT_US]: Referral,
  [StepSequence.SUMMARY]: Summary,
  [StepSequence.PENDING_REVIEW]: PendingReview
};

const SHOW_STEPPER = {
  [StepSequence.VENUES]: true,
  [StepSequence.EDUCATION]: true,
  [StepSequence.PERSONAL_INTRODUCTION]: true,
  [StepSequence.HOW_DID_YOU_HEAR_ABOUT_US]: true,
  [StepSequence.SUMMARY]: false,
  [StepSequence.PENDING_REVIEW]: false
};

const HIDE_DIVIDER = [StepSequence.SUMMARY, StepSequence.PENDING_REVIEW];

const Applicant: React.FC = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [application, setApplication] = useState({} as Application);
  const [currentStep, setCurrentStep] = useState(StepSequence.VENUES);

  const handleContinue = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_APPLICATION.get()).then(res => {
      const stepStatus = res?.data?.applicationDetails?.stepStatus;

      if (res?.data?.isApplicationCompleted) {
        navigate(routes.ON_BOARDING);
        dispatch(setApplicationCompleted());
      } else {
        setApplication(res?.data ?? null);
        setCurrentStep(stepStatus?.currentStatus ?? 1);
      }
    });
  }, []);

  const StepComponent = stepsComponents[currentStep];

  return (
    <div className="page-applicant">
      {api.isLoading ? (
        <div className="applicant-page-loading">
          <Spinner />
        </div>
      ) : (
        <>
          {currentStep !== StepSequence.PENDING_REVIEW && (
            <div className="header">
              <h6 className="title">
                Welcome to <span>EATS2SEATS</span>
              </h6>
              <p className="body subtitle">Complete the following form to start</p>
            </div>
          )}

          <div className="content">
            <Card>
              <div className="applicant-body">
                {SHOW_STEPPER[currentStep] && (
                  <div className="stepper-wrapper">
                    <ApplicantStepper currentStep={currentStep} />
                  </div>
                )}

                {!HIDE_DIVIDER.includes(currentStep) && <div className="divider" />}

                {StepComponent ? (
                  <StepComponent
                    step={currentStep}
                    application={application}
                    handleContinue={handleContinue}
                    handleBack={handleBack}
                    updateApplication={setApplication}
                  />
                ) : null}
              </div>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default Applicant;

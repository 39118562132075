import React, { useEffect, useState } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card, VenueItem } from 'components';
import { Props } from '../types';

const Venues = ({ handleContinue, step }: Props) => {
  const api = useApi();
  const apiVenues = useApi();
  const [selected, setSelected] = useState<string[]>([]);

  const handleSubmit = () => {
    api.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step, selected });
  };

  const handleToggle = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  useEffect(() => {
    if (api.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [api.response]);

  useEffect(() => {
    apiVenues.fetchData(endpoints.VENUES.get());
  }, []);

  return (
    <div className="venues">
      <Card title="Venues" description="Select the venues you could work.">
        <>
          <Card.Item className="venue-items">
            {!!apiVenues.response &&
              apiVenues.response.items.map((item: any, index: number) => (
                <VenueItem
                  key={index}
                  selected={selected}
                  id={item.id}
                  name={item.name}
                  address={item.addressVenue.address}
                  onToggle={handleToggle}
                />
              ))}
          </Card.Item>
          <Card.Footer>
            <Button text="Continue" onClick={handleSubmit} disabled={!selected.length} loading={api.isLoading} />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default Venues;

import React, { useState, useEffect } from 'react';
import { format, isToday, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Calendar, Location, Warning2, Link1, Timer1, TickCircle, TickSquare } from 'iconsax-react';
import { dateFormatWithDay, shortWeekdayAndDateFormat, timeFormat } from 'constants/dates';
import useApi from 'hooks/useApi';
import useEventSubscription from 'hooks/useEventSubscription';
import { useToast } from 'hooks/useToast';
import { useModal } from 'hooks/useModal';
import { Badge, Button, Card, IconBag } from 'components';
import E2S from 'components/icons/e2s';
import { getTimeStatus } from 'helpers/date';
import { useDispatch, useSelector } from 'react-redux';
import { setChannelUrl, setBackgroundCheckProcessing } from 'store/actions/user';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import { ModalsList } from 'types/modal';
import endpoints from 'constants/endpoints';
import { RootState } from 'types/store';

type Props = {
  id: string;
  address: string;
  reportTimeAt: string;
  groupMeLink: string;
  payModelUrl: string;
  channelUrl: string;
  eventLogisticsUrl: string;
  eventTrainingUrl: string;
  date: string;
  subscribed: boolean;
  title: string;
  requireBackgroundCheck: boolean;
  backgroundCheckProcessing: boolean;
  requireAlcoholCertification: boolean;
  timezone?: string;
  canConfirm: boolean;
  canDropout: boolean;
  dropped: boolean;
  registrationCallback?: () => void;
  confirmationDeadline: string;
  jobType: Array<number>;
  allocation: Allocation;
};

export type Allocation = {
  position: string | null;
  stand: string | null;
};

const EventCard = ({
  address,
  date,
  reportTimeAt,
  groupMeLink,
  payModelUrl,
  channelUrl,
  eventLogisticsUrl,
  eventTrainingUrl,
  subscribed,
  title,
  id,
  requireBackgroundCheck,
  requireAlcoholCertification,
  backgroundCheckProcessing,
  timezone,
  canConfirm,
  canDropout,
  dropped,
  registrationCallback,
  confirmationDeadline,
  jobType,
  allocation
}: Props) => {
  const api = useApi();
  const apiS3 = useApi();
  const apiBgCheck = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useModal();
  const toast = useToast();

  const isLeader = jobType?.length > 0;
  const isTodayEvent = isToday(parseISO(date));
  const { backgroundCheckProcessing: userBackgroundCheckProcessing } = useSelector((state: RootState) => state.user);
  const [backgroundCheckProcessingState, setBackgroundCheckProcessingState] = useState<boolean>(
    backgroundCheckProcessing || userBackgroundCheckProcessing
  );

  const hookProps = {
    isLeader,
    eventId: id,
    jobType,
    subscribed,
    canConfirm,
    date,
    timezone: timezone!,
    canDropout,
    dropped,
    allocation,
    confirmationDeadline,
    registrationCallback: registrationCallback!
  };

  const {
    handleSubscribe,
    handleDropEvent,
    handleConfirm,
    confirmStatus,
    userAllocation,
    isLoading,
    eventStatusLoading,
    availableLeaderPositionsLoading
  } = useEventSubscription(hookProps);

  const showGoToChat = channelUrl && isTodayEvent && confirmStatus.subscribed;
  const showGoToChat14DaysBefore = channelUrl && !isTodayEvent;

  const handleGetBackgroundCheck = () => {
    modal.open(ModalsList.REQUEST_BACKGROUND_CHECK, async data => {
      if (data?.success) {
        const signedUrl = await api.fetchData(endpoints.GET_SIGNED_SIGNATURE_IMAGE.get(), {
          fileName: data.signatureFile.name
        });

        await apiS3.uploadFileToS3(data.signatureFile, signedUrl.extension, signedUrl.url);

        await apiBgCheck.fetchData(endpoints.GET_BACKGROUND_CHECK.get(), {
          consentSignatureFile: signedUrl.filePath,
          consentSignatureText: data.signatureText,
          consentSignatureDate: data.signatureDate
        });

        dispatch(setBackgroundCheckProcessing());
        toast('Your background check is processing and should finish in between 1 to 3 days');
      }
    });
  };

  useEffect(() => {
    setBackgroundCheckProcessingState(backgroundCheckProcessing || userBackgroundCheckProcessing);
  }, [userBackgroundCheckProcessing]);

  const handleOpenExternalLink = (link: string) => {
    window.open(link, '_blank');
  };

  const handleGoToGroupChat = () => {
    dispatch(setChannelUrl(channelUrl));
    navigate(routes.MY_CHATS);
  };

  const renderAllocation = () => {
    if (!userAllocation.stand) {
      return (
        <div className="content">
          <span className="small">Position</span>
          <p>{userAllocation.position}</p>
        </div>
      );
    } else {
      return (
        <div className="allocation-info">
          <div className="content">
            <span className="small">Position</span>
            <p>{userAllocation.position}</p>
          </div>
          <div className="content">
            <span className="small">Stand Assignment</span>
            <p>{userAllocation.stand}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <Card className="event-card" title={title}>
      <Card.Item>
        <div className="info-item">
          <IconBag icon={<Location />} variant="primary" />
          {address}
        </div>

        <div className="info-item">
          <IconBag icon={<Calendar />} variant="primary" />
          Held On: {format(utcToZonedTime(date, timezone!), dateFormatWithDay)}
        </div>

        <div className="info-item">
          <IconBag icon={<Timer1 />} variant="primary" />
          Report Time:
          {reportTimeAt
            ? ` ${format(utcToZonedTime(reportTimeAt, timezone!), timeFormat)} ${getTimeStatus(
                reportTimeAt,
                timezone!
              )}`
            : ` TBD`}
        </div>
        {groupMeLink && (
          <div className="info-item">
            <IconBag icon={<Link1 />} variant="primary" />
            <Badge onClick={() => handleOpenExternalLink(groupMeLink)} text="Join Event-Specific GroupMe" />
          </div>
        )}
        {payModelUrl && (
          <div className="info-item">
            <IconBag icon={<Link1 />} variant="primary" />
            <Badge onClick={() => handleOpenExternalLink(payModelUrl)} text="View Pay Model" />
          </div>
        )}
        {eventLogisticsUrl && (
          <div className="info-item">
            <IconBag icon={<Link1 />} variant="primary" />
            <Badge onClick={() => handleOpenExternalLink(eventLogisticsUrl)} text="Event Logistics" />
          </div>
        )}
        {eventTrainingUrl && (
          <div className="info-item">
            <IconBag icon={<Link1 />} variant="primary" />
            <Badge onClick={() => handleOpenExternalLink(eventTrainingUrl)} text="Event Training" />
          </div>
        )}
        {requireBackgroundCheck && (
          <div className="info-item">
            <IconBag icon={<Warning2 />} variant="primary" />
            To apply for this job, we need to have your background check first
          </div>
        )}
        {requireAlcoholCertification && (
          <div className="info-item">
            <IconBag icon={<Warning2 />} variant="primary" />
            This event requires Alcohol Certificate
          </div>
        )}
        {confirmStatus.confirmationDeadline && !confirmStatus.canDropout && (
          <div className="info-item">
            <IconBag icon={<TickCircle />} variant="primary" />
            Deadline to confirm:{' '}
            {format(utcToZonedTime(confirmStatus.confirmationDeadline, timezone!), shortWeekdayAndDateFormat)}
          </div>
        )}
        {confirmStatus.subscribed && !isTodayEvent && (
          <>
            {showGoToChat14DaysBefore ? (
              <div className="info-item">
                <IconBag icon={<Link1 />} variant="primary" />
                <Badge onClick={handleGoToGroupChat} text="Go to Group Chat" />
              </div>
            ) : (
              <div className="info-item">
                <IconBag icon={<Warning2 />} variant="primary" />
                You will find the chat available 14 days before the event
              </div>
            )}
          </>
        )}

        {userAllocation.position && (
          <div className="info-item">
            <IconBag icon={<E2S />} variant="primary" />
            <div className="allocation-info">{renderAllocation()}</div>
          </div>
        )}

        {confirmStatus.canDropout && (
          <div className="info-item">
            <IconBag icon={<TickSquare />} variant="success" />
            <span className="confirmed-success">You have confirmed this event</span>
          </div>
        )}
      </Card.Item>
      <Card.Footer grid>
        {requireBackgroundCheck ? (
          <Button
            onClick={handleGetBackgroundCheck}
            disabled={backgroundCheckProcessingState}
            text={backgroundCheckProcessingState ? 'Background check in progress' : 'Get background check'}
            variant="primary"
            loading={apiBgCheck.isLoading}
          />
        ) : (
          <>
            {!confirmStatus.dropped ? (
              <div className="buttons">
                {showGoToChat && <Button onClick={handleGoToGroupChat} text="Go to Group Chat" variant="primary" />}
                {!confirmStatus.canDropout && (
                  <Button
                    onClick={handleSubscribe}
                    text={!confirmStatus.subscribed ? 'Sign Up' : 'Drop Out'}
                    variant={!confirmStatus.subscribed ? 'primary' : 'tertiary'}
                    disabled={isLoading || eventStatusLoading}
                  />
                )}
                {confirmStatus.canConfirm && (
                  <Button
                    onClick={handleConfirm}
                    text="Confirm"
                    variant="quaternary"
                    disabled={isLoading || availableLeaderPositionsLoading}
                  />
                )}
                {confirmStatus.canDropout && (
                  <Button onClick={handleDropEvent} text="Drop Event" variant="tertiary" disabled={isLoading} />
                )}
              </div>
            ) : (
              <div className="dropped">
                <span>You have dropped this event</span>
              </div>
            )}
          </>
        )}
      </Card.Footer>
    </Card>
  );
};

export default EventCard;

import React from 'react';
import { Button, DynamicForm } from 'components';

type Props = {
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
};

const EmailVerification = ({ formHandler, loading, onSubmit }: Props) => {
  return (
    <>
      <h6>Verify your email</h6>
      <p className="body">
        Before continuing, you must verify your email address by clicking on the link we emailed you. If you didn&apos;t
        receive the email, we will gladly send you another.
      </p>
      <hr />
      <DynamicForm handler={formHandler} />
      <Button text="Resend verification email" loading={loading} disabled={!formHandler.valid} onClick={onSubmit} />
    </>
  );
};

export default EmailVerification;

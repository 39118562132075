import { utcToZonedTime } from 'date-fns-tz';
import { DateTime } from 'luxon';
import { Timezone } from 'constants/timezone';
import { formatDistanceToNow } from 'date-fns';

export const utcDate = (dateTime: Date | string | number) => {
  return utcToZonedTime(dateTime, 'UTC');
};

export const getTimeStatus = (dateTime: Date | string | number, timezone: string) => {
  let luxonDate;

  if (dateTime instanceof Date) {
    luxonDate = DateTime.fromJSDate(dateTime, { zone: timezone });
  } else if (typeof dateTime === 'string') {
    luxonDate = DateTime.fromISO(dateTime, { zone: timezone });
  } else if (typeof dateTime === 'number') {
    luxonDate = DateTime.fromMillis(dateTime, { zone: timezone });
  } else {
    throw new Error('Invalid dateTime input');
  }

  const isInDST = luxonDate.isInDST;

  switch (timezone) {
    case Timezone.NEW_YORK:
      return isInDST ? 'EDT' : 'EST';
    case Timezone.CHICAGO:
      return isInDST ? 'CDT' : 'CST';
    case Timezone.DENVER:
      return isInDST ? 'MDT' : 'MST';
    case Timezone.LOS_ANGELES:
      return isInDST ? 'PDT' : 'PST';
    case Timezone.ANCHORAGE:
      return isInDST ? 'AKDT' : 'AKST';
    case Timezone.PHOENIX:
      return 'MST';
    case Timezone.HONOLULU:
      return 'HST';
    default:
      return '';
  }
};

export const isTimeDifferenceLessThanHours = (dateTime1: Date, dateTime2: Date, hours: number) => {
  const timeDifference = dateTime1.getTime() - dateTime2.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference < hours;
};

export const getRelativeTime = (date: string) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

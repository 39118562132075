import React, { forwardRef } from 'react';
import { flexRender, Table as ITable } from '@tanstack/react-table';

type TableProps<T> = {
  config: ITable<T>;
  fetchMoreOnBottomReached?: (containerRefElement?: HTMLDivElement | null) => void;
  isLoading?: boolean;
};

const Table = forwardRef<HTMLDivElement, TableProps<any>>((props, ref) => {
  const { config, isLoading, fetchMoreOnBottomReached } = props;

  return (
    <div
      className="table-container"
      ref={ref}
      onScroll={e => fetchMoreOnBottomReached && fetchMoreOnBottomReached(e.target as HTMLDivElement)}
    >
      <table>
        <thead>
          {config.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className="body strong">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {config.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td className="body" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <div className="loading-indicator body">Loading...</div>}
    </div>
  );
});

export default Table;

/** Constants **/

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_TOKEN = 'SET_TOKEN';
export const ON_BOARDING_COMPLETED = 'ON_BOARDING_COMPLETED';
export const SET_APPLICATION_COMPLETED = 'SET_APPLICATION_COMPLETED';
export const NOTICES = 'NOTICES';
export const SET_NOTIFICATION_TOKEN = 'SET_NOTIFICATION_TOKEN';
export const SET_CHANNEL_URL = 'SET_CHANNEL_URL';
export const SET_HAS_CHATS = 'SET_HAS_CHATS';
export const HAS_NOTIFICATIONS = 'HAS_NOTIFICATIONS';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SET_BACKGROUND_CHECK_PROCESSING = 'SET_BACKGROUND_CHECK_PROCESSING';

/** States **/

export interface UserState {
  isLogged: boolean;
  token: string;
  refreshToken: string;
  onBoardingCompleted: boolean;
  isApplicationCompleted: boolean;
  notificationToken: string | null;
  pushNotificationsEnabled: boolean;
  notices: any[];
  data: any;
  channelUrl: string;
  hasChats: boolean;
  notifications: any[];
  hasNotifications: boolean;
  backgroundCheckProcessing: boolean;
}

export interface RootState {
  user: UserState;
}

/** Actions **/

interface LogInAction {
  type: typeof LOG_IN;
  data: any;
  onBoardingCompleted: boolean;
  isApplicationCompleted: boolean;
  token: string;
  refreshToken: string;
}

interface LogOutAction {
  type: typeof LOG_OUT;
}

interface SetTokenAction {
  type: typeof SET_TOKEN;
  token: string;
}

interface OnBoardingCompletedAction {
  type: typeof ON_BOARDING_COMPLETED;
}

interface SetNoticesAction {
  type: typeof NOTICES;
  notices: any[];
}

interface SetNotificationTokenAction {
  type: typeof SET_NOTIFICATION_TOKEN;
  notificationToken: string | null;
}

interface SetChannelUrlAction {
  type: typeof SET_CHANNEL_URL;
  channelUrl: string | undefined;
}

interface SetHasChatsAction {
  type: typeof SET_HAS_CHATS;
  hasChats: boolean;
}

interface SetHasNotificationsAction {
  type: typeof HAS_NOTIFICATIONS;
  hasNotifications: boolean;
}

interface SetNotificationsAction {
  type: typeof NOTIFICATIONS;
  notifications: any[];
}

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  notification: any;
}

interface SetApplicationCompletedAction {
  type: typeof SET_APPLICATION_COMPLETED;
}

interface SetBackgroundCheckProcessingAction {
  type: typeof SET_BACKGROUND_CHECK_PROCESSING;
}

export type UserActionTypes =
  | LogInAction
  | LogOutAction
  | SetTokenAction
  | OnBoardingCompletedAction
  | SetNoticesAction
  | SetNotificationTokenAction
  | SetChannelUrlAction
  | SetHasChatsAction
  | SetHasNotificationsAction
  | SetNotificationsAction
  | AddNotificationAction
  | SetApplicationCompletedAction
  | SetBackgroundCheckProcessingAction;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useApi from './useApi';
import endpoints from 'constants/endpoints';
import { useDispatch } from 'react-redux';
import { setHasNotifications } from 'store/actions/user';

interface Props {
  isLogged: boolean;
  onBoardingCompleted: boolean;
  isApplicationCompleted: boolean;
}

const useActiveNotifications = ({ isLogged, onBoardingCompleted, isApplicationCompleted }: Props) => {
  const dispatch = useDispatch();
  const api = useApi();
  const location = useLocation();
  const userActive = isLogged && onBoardingCompleted && isApplicationCompleted;

  useEffect(() => {
    if (userActive) {
      api.fetchData(endpoints.GET_UNREAD_NOTIFICATIONS.get());
    }
  }, [isLogged, location.pathname]);

  useEffect(() => {
    if (api.response) {
      dispatch(setHasNotifications(api.response.unReadNotifications));
    }
  }, [api.response, dispatch]);
};

export default useActiveNotifications;

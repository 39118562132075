import React from 'react';
import { TickCircle } from 'iconsax-react';
import classnames from 'classnames';
import { Address } from 'types/address';

type Props = {
  id: string;
  selected: string[];
  onToggle: (id: string) => void;
  name: string;
  address: Address;
};

const VenueItem = ({ id, selected, onToggle, name, address }: Props) => (
  <div className={classnames('venue-item', { selected: selected.includes(id) })} onClick={() => onToggle(id)}>
    <h6 className="strong">{name}</h6>
    <p className="body-2">{address.line_one}</p>
    {!!address?.line_two && <p className="body-2">{address.line_two}</p>}
    <p className="body-2">
      {address.city}, {address.state}, {address.country}, {address.postal_code}
    </p>
    <TickCircle />
  </div>
);

export default VenueItem;

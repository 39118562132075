import React from 'react';
import { format } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';

export type EventStatsType = {
  eventName: string;
  standName: string;
  positionName: string;
  heldOn: string;
};

const columnHelper = createColumnHelper<EventStatsType>();

export const columns = [
  columnHelper.accessor('eventName', {
    header: 'Event',
    cell: info => <div className="event-name-cell">{info.renderValue()}</div>
  }),
  columnHelper.accessor('standName', {
    header: 'Stand',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('positionName', {
    header: 'Position',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('heldOn', {
    header: 'Date',
    cell: info => <div className="event-date-cell">{format(new Date(info.renderValue()!), 'LLL dd, yy')}</div>
  })
];

import React from 'react';

type RadioButtonProps = {
  label?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const RadioButton = ({ label, name, value, checked, onChange, onBlur }: RadioButtonProps) => {
  return (
    <label className="radio-button">
      <input type="radio" name={name} value={value} checked={checked} onChange={onChange} onBlur={onBlur} />
      <span className="body">{label}</span>
      <span className="checkmark"></span>
    </label>
  );
};

export default RadioButton;

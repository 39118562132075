import React, { useEffect } from 'react';
import { AlcoholInstructions, Button, Card, TrainingDropdown } from 'components';
import { DocumentUpload, Global, Location, Shop } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import routes from 'constants/routes';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import { useToast } from 'hooks/useToast';
import { ALCOHOL_CERTIFICATE, AlcoholCertificateKey } from 'constants/alcoholCertificate';
import { certificationStatus } from 'constants/certifications';

const MyTrainingsPage = () => {
  const modal = useModal();
  const navigate = useNavigate();
  const api = useApi();
  const notify = useToast();
  const pendingAprroval = api?.response?.alcoholCertification?.status === certificationStatus.PENDING_APPROVAL;
  const approved = api?.response?.alcoholCertification?.status === certificationStatus.COMPLETED;
  const multipleInstructions = api?.response?.alcoholInstructions?.length > 1;

  const positions = api.response?.positions?.map((position: any) => ({
    label: position.name,
    value: position.id,
    completed: position.completed
  }));

  const venues = api.response?.venues?.map((venue: any) => ({
    label: venue.name,
    value: venue.id,
    completed: venue.completed
  }));

  const globals = api.response?.global?.map((global: any) => ({
    label: global.name,
    value: global.id,
    completed: global.completed
  }));

  const getTrainingStatus = () => {
    api.fetchData(endpoints.GET_TRAINING_STATUS_LIST.get({ flow: 'MyTrainings' }));
  };

  useEffect(() => {
    getTrainingStatus();
  }, []);

  const handleGoToWizard = (id: number) => {
    navigate(routes.TRAINING_WIZARD.replace(':id', id!.toString()));
  };

  const handleUpload = () => {
    modal.open(
      ModalsList.FILE_UPLOADER,
      async flag => {
        if (flag) {
          getTrainingStatus();
          notify('File uploaded successfully');
        }
      },
      {
        title: 'Upload Alcohol Certificate',
        message: 'Press here to upload your Alcohol Certification files - (Maximum of 2 files allowed)',
        maxFiles: 2,
        accept: {
          'image/jpeg': ['.jpeg', '.png', '.jpg'],
          'application/pdf': ['.pdf']
        },
        endpoint: endpoints.SAVE_CERTIFICATION.get()
      }
    );
  };

  const handleOpenDocument = () => {
    const url = api?.response?.alcoholInstructions?.[0]?.url;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="my-training-page">
      <Card title="My Trainings" />
      <TrainingDropdown
        title="Venue-Specific Trainings"
        items={venues}
        icon={<Location />}
        onClick={handleGoToWizard}
      />
      <TrainingDropdown
        title="Position-Specific Trainings"
        items={positions}
        icon={<Shop />}
        onClick={handleGoToWizard}
      />
      <TrainingDropdown title="General Trainings" items={globals} icon={<Global />} onClick={handleGoToWizard} />

      <Card title="Alcohol Certificate" description="This is the information of your alcohol certificate">
        {api?.response?.alcoholInstructions?.length > 0 && (
          <Card.Item grid>
            <div className="body-2">Alcohol Training Instructions</div>
            <div className=""></div>
            <AlcoholInstructions
              data={api?.response?.alcoholInstructions}
              isMultiple={multipleInstructions}
              onOpenDocument={handleOpenDocument}
            />
          </Card.Item>
        )}
        <Card.Item grid>
          <div className="body-2">Status</div>
          <div className="body-2 capitalize">
            {ALCOHOL_CERTIFICATE[api?.response?.alcoholCertification?.status as AlcoholCertificateKey]}
          </div>
          <div className="actions-width">
            <input className="hidden" type="file" accept=".pdf,.jpg,.png" onChange={() => {}} />
            <Button
              icon={<DocumentUpload />}
              text="Upload"
              variant="alternative"
              small
              onClick={handleUpload}
              disabled={pendingAprroval || approved || api.isLoading}
            />
          </div>
        </Card.Item>
      </Card>
    </div>
  );
};

export default MyTrainingsPage;

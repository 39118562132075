export enum InputsEnum {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  TEXTAREA = 'TEXTAREA',
  MASKED = 'MASKED',
  ASYNC_SELECT = 'ASYNC_SELECT',
  CHECK_GROUP = 'CHECK_GROUP',
  RADIO_GROUP = 'RADIO_GROUP',
  TIME_PICKER = 'TIME_PICKER',
  FORM_SECTION = 'FORM_SECTION',
  DATE = 'DATE',
  FILE_INPUT = 'FILE_INPUT',
  VENUE_SELECTOR = 'VENUE_SELECTOR',
  RADIO_SELECTOR = 'RADIO_SELECTOR',
  DROP_IMAGE = 'DROP_IMAGE',
  DROP_VIDEO = 'DROP_VIDEO',
  DROP_FILE = 'DROP_FILE',
  ALTERNATIVE_TEXT_AREA = 'ALTERNATIVE_TEXT_AREA'
}

export enum TextInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PHONE = 'phone',
  PASSWORD = 'password',
  EMAIL = 'email',
  DATE = 'date'
}

export enum DateInputTypes {
  DATE = 'date',
  DATE_TIME = 'datetime-local'
}

export type CommonProps = {
  onChange: (value: any, name?: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (e: any) => void;
  label?: string;
  error?: string;
  pristine?: boolean;
  required?: boolean;
  disabled?: boolean;
  isQuiz?: number;
  questionId?: string;
  wrongAnswer?: boolean;
};

export type DatePickerProps = {
  type: DateInputTypes;
  name?: string;
  value: Date;
  allowedDates?: Date[];
} & CommonProps;

export type TimePickerProps = {
  type: DateInputTypes;
  name?: string;
  value: Date;
  allowedDates?: Date[];
} & CommonProps;

export type TimeRangePickerProps = {
  value: [string | null, string | null];
  name?: string;
} & CommonProps;

export type TextInputProps = {
  value: string | number | any[];
  type: TextInputTypes;
  name?: string;
  disabled?: boolean;
  limit?: number;
  placeholder?: string;
  autoComplete?: string;
  keyBlocker?: (event: any) => void;
} & CommonProps;

export type SelectProps = {
  value: string;
  options: { value: string; label: string }[];
} & CommonProps;

export type TextareaProps = {
  value: string;
  name?: string;
  limit?: number;
  placeholder?: string;
  alernative?: boolean;
} & CommonProps;

export type MaskedInputProps = {
  value: string | number;
  name?: string;
  placeholder?: string;
  mask: (string | RegExp)[];
} & CommonProps;

export type CheckProps = {
  value: boolean;
} & CommonProps;

export type CheckboxgroupProps = {
  value: string[];
  options: Option[];
  name?: string;
} & CommonProps;

export type SelectorCheckboxProps = {
  value: string;
  options: Option[];
  name?: string;
} & CommonProps;

export type DropFileProps = {
  value: any;
  name?: string;
  acceptedFileTypes?: any;
  schema?: any;
  setSchema?: any;
} & CommonProps;

export type VenuSelector = {
  value: VenueSelectorValue;
  label: string;
  name?: string;
} & CommonProps;

type VenueSelectorValue = {
  state: string;
  venues: string[];
};

export type Option = {
  label: string;
  value: string;
};

import formErrors from 'constants/formErrors';
import { emailRegex } from 'constants/regex';
import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  email: {
    value: '',
    label: 'Email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';

import useWindowSize from '@revolt-digital/use-window-size';
import { setChannelUrl } from 'store/actions/user';
// import { useIsChannelOperator } from 'hooks/useIsChannelOperator';
import MobileChannelList from './mobile/mobileChannelList';
import DesktopChannelList from './desktop/desktopChannelList';

interface PageContentProps {
  channelUrl: string;
  userId: string;
}

const PageContent: React.FC<PageContentProps> = ({ channelUrl, userId }) => {
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);

  const globalStore = useSendbirdStateContext();
  const context = useGroupChannelContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const [isOperator, setIsOperator] = useState(false);
  // const { isOperator, setIsOperator, checkIsOperator } = useIsChannelOperator();

  const window = useWindowSize();
  const isMobile = window.width < 1024;

  const handleChannelSelect = (channel: any) => {
    // checkIsOperator(channel?.url!);
    dispatch(setChannelUrl(channel?.url!));
  };

  // useEffect(() => {
  //   checkIsOperator(channelUrl);
  // }, []);

  useEffect(() => {
    return () => {
      dispatch(setChannelUrl(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (sdkInstance && channelUrl) {
      sdkInstance.groupChannel.getChannel(channelUrl).then(group => {
        const currentUser = group.members.find(member => member.userId === userId);
        if (currentUser?.role === 'operator') {
          setIsOperator(true);
        } else {
          setIsOperator(false);
        }
      });
    }
  }, [channelUrl, sdkInstance]);

  return isMobile ? (
    <MobileChannelList
      sdkInstance={sdkInstance}
      channelUrl={channelUrl}
      context={context}
      showSettings={showSettings}
      isOperator={isOperator}
      dispatch={dispatch}
      handleChannelSelect={handleChannelSelect}
      setShowSettings={setShowSettings}
      setIsOperator={setIsOperator}
    />
  ) : (
    <DesktopChannelList
      sdkInstance={sdkInstance}
      channelUrl={channelUrl}
      context={context}
      showSettings={showSettings}
      isOperator={isOperator}
      dispatch={dispatch}
      checkIsOperator={() => {}}
      setShowSettings={setShowSettings}
      setIsOperator={setIsOperator}
    />
  );
};

export default PageContent;

import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import { Record, StopCircle } from 'iconsax-react';
import useVideoRecorder from 'hooks/useVideoRecorder';

const RecordVideoModal: React.FC<Props> = ({ closing, onClose }) => {
  const [cameraAccessDenied, setCameraAccessDenied] = useState(false);

  const {
    videoFile,
    webcamRef,
    capturing,
    videoURL,
    timeLeft,
    setVideoURL,
    formatTime,
    handleStartCaptureClick,
    handleStopCaptureClick
  } = useVideoRecorder();

  useEffect(() => {
    const checkCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAccessDenied(false);
      } catch (error) {
        setCameraAccessDenied(true);
      }
    };
    checkCameraAccess();
  }, []);

  return (
    <Base
      header="Video Presentation"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          onClick={() => {
            onClose(videoFile);
          }}
          disabled={cameraAccessDenied}
        />
      }
    >
      <div className="record-video-modal">
        {cameraAccessDenied ? (
          <div className="camera-access-denied">
            <p>Access to the camera has been denied. Please allow camera access to record a video.</p>
          </div>
        ) : !videoURL ? (
          <>
            <Webcam
              muted
              videoConstraints={{
                facingMode: 'user'
              }}
              audio
              audioConstraints={{
                noiseSuppression: true,
                echoCancellation: true
              }}
              ref={webcamRef}
            />
            <div className="record-video-buttons">
              {capturing ? (
                <div className="stop-recording-container">
                  <Button
                    icon={<StopCircle size={14} />}
                    text="Stop recording"
                    small
                    tiny
                    variant="secondary"
                    onClick={handleStopCaptureClick}
                  />
                  <span className="countdown body-2">
                    <span className="recording-indicator"></span>
                    {formatTime(timeLeft)}
                  </span>
                </div>
              ) : (
                <div className="start-recording-container">
                  <Button
                    text="Start recording"
                    icon={<Record size={14} />}
                    small
                    tiny
                    variant="secondary"
                    onClick={handleStartCaptureClick}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <video src={videoURL} controls>
              <track kind="captions" />
            </video>
            <div className="record-again-container">
              <Button text="Record again" small tiny variant="secondary" onClick={() => setVideoURL('')} />
            </div>
          </>
        )}
      </div>
    </Base>
  );
};

export default RecordVideoModal;

import React from 'react';
import GroupChannelList from '@sendbird/uikit-react/GroupChannelList';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import ChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import { SendbirdChatType } from '@sendbird/uikit-react/types/lib/types';
import { GroupChannelContextType } from '@sendbird/uikit-react/GroupChannel/context';
import { AnyAction, Dispatch } from 'redux';
import { Spinner } from 'components';

import MobileChannelHeader from './mobileChannelHeader';
import ChannelPreview from '../channelPreview';
import ChannelListHeader from '../channelListHeader';
import { Send2 } from 'iconsax-react';

type MobileChannelListProps = {
  sdkInstance: SendbirdChatType;
  channelUrl: string;
  context: GroupChannelContextType;
  dispatch: Dispatch<AnyAction>;
  showSettings: boolean;
  isOperator: boolean;
  handleChannelSelect: (channel: any) => void;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOperator: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileChannelList: React.FC<MobileChannelListProps> = ({
  sdkInstance,
  channelUrl,
  context,
  dispatch,
  showSettings,
  isOperator,
  handleChannelSelect,
  setShowSettings,
  setIsOperator
}) => {
  return (
    <>
      {!channelUrl && (
        <GroupChannelList
          onChannelCreated={() => null}
          onChannelSelect={handleChannelSelect}
          disableAutoSelect
          renderHeader={() => (
            <ChannelListHeader
              isMobile
              profileUrl={sdkInstance.currentUser?.profileUrl!}
              nickname={sdkInstance.currentUser?.nickname!}
            />
          )}
          renderChannelPreview={channel => <ChannelPreview {...channel} isMobile />}
        />
      )}

      {channelUrl &&
        (context.loading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            {!showSettings ? (
              <GroupChannel
                channelUrl={channelUrl}
                renderChannelHeader={() => {
                  return (
                    <MobileChannelHeader
                      context={context}
                      isOperator={isOperator}
                      dispatch={dispatch}
                      setIsOperator={setIsOperator}
                      setShowSettings={setShowSettings}
                    />
                  );
                }}
                renderSendMessageIcon={() => <Send2 />}
              />
            ) : (
              <ChannelSettings channelUrl={channelUrl} onCloseClick={() => setShowSettings(false)} />
            )}
          </>
        ))}
    </>
  );
};

export default MobileChannelList;

export default {
  AGE_ERROR: 'You must be at least 18 years',
  REQUIRED: 'Required field',
  PASSWORD_MUST_MATCH: 'The password must match',
  INVALID_NUMBER: 'The field must be a number',
  INVALID_LENGTH: 'Invalid field length',
  INVALID_NAME: 'Wrong name format',
  INVALID_EMAIL: 'Wrong email format',
  INVALID_PHONE_NUMBER: 'Invalid phone number format. Ex: +11234567890',
  INVALID_PASSWORD:
    'Minimum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
  INVALID_ZIP: 'Wrong zip code format',
  INVALID_DATE: 'Wrong date format',
  INVALID_EXTENSION: 'Invalid file extension'
};

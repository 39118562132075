import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';

const ConfirmModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" onClick={() => onClose(false)} variant="secondary" capitalize />
          <Button text="Confirm" onClick={() => onClose(true)} capitalize />
        </>
      }
    >
      <div dangerouslySetInnerHTML={{ __html: params.message }} />
    </Base>
  );
};

export default ConfirmModal;

import React from 'react';
import { Button, DynamicForm } from 'components';

type Props = {
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
};

const ChangePassword = ({ formHandler, loading, onSubmit }: Props) => {
  return (
    <>
      <h6>Reset your password</h6>
      <p className="body">Enter the new password you would like to use to access your account.</p>
      <hr />
      <DynamicForm handler={formHandler} />
      <Button text="Reset password" loading={loading} disabled={!formHandler.valid} onClick={onSubmit} />
    </>
  );
};

export default ChangePassword;

import * as React from 'react';

const Clock = () => (
  <svg width={65} height={66} fill="none">
    <path
      stroke="#573BFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.063}
      d="M59.583 33c0 14.95-12.133 27.083-27.083 27.083S5.417 47.95 5.417 33 17.55 5.917 32.5 5.917 59.583 18.05 59.583 33Z"
    />
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.063}
      d="m42.548 41.612-8.396-5.01c-1.462-.867-2.654-2.952-2.654-4.658V20.84"
    />
  </svg>
);
export default Clock;

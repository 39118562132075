import joi from 'joi';
import formErrors from 'constants/formErrors';
import { nameRegex } from 'constants/regex';
import { blockNonAlphaCharacters } from 'helpers/keyBlockers';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  legalFirstName: {
    value: '',
    label: 'First',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  },
  legalMiddleName: {
    value: '',
    label: 'Middle',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().allow('').regex(nameRegex).optional().messages({
      'string.pattern.base': formErrors.INVALID_NAME
    })
  },
  legalLastName: {
    value: '',
    label: 'Last',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  }
};

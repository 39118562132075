import React from 'react';
import { formatSize } from 'helpers/data';
import Close from 'components/icons/close';

type Props = {
  fileName: string;
  fileSize: number;
  icon: React.ReactNode;
  onRemove: () => void;
};

const FilePreview = ({ fileName, fileSize, icon, onRemove }: Props) => (
  <aside className="file-preview">
    <div className="left-content">
      {icon}
      <span>{fileName}</span>
    </div>
    <div className="right-content">
      <span>{formatSize(fileSize)}</span>
      <Close onClick={onRemove} />
    </div>
  </aside>
);

export default FilePreview;

import React from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';

const AppealWarningModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    onClose(formHandler.data);
  };

  return (
    <Base
      header="Appeal Warning"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Submit" disabled={!formHandler.valid} onClick={handleSubmit} />}
    >
      <div>
        <p>
          We believe in a fair and open workplace, and your right to appeal disciplinary decisions is important to us.
          To initiate the appeal process, kindly complete the Appeal Form provided. Clearly state the grounds for your
          appeal, providing any relevant documentation within three business days of receiving a written warning. Our
          designated reviewer will carefully assess your appeal, ensuring a fair and thorough review. Your perspective
          is valued, and this process is designed to uphold transparency and justice in our working environment.
        </p>
        <hr />
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default AppealWarningModal;

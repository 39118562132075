import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';
import { CommonProps, StepSequence } from '../types';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const Education = ({ application, handleContinue, handleBack, updateApplication }: CommonProps) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const submit = () => {
    const data = formHandler.data.answer;

    const transformedData = {
      currentStep: StepSequence.EDUCATION,
      data: {
        answer: data
      }
    };

    api.fetchData(endpoints.UPDATE_APPLICATION.get(), transformedData).then(r => {
      updateApplication?.(r.data);
      handleContinue();
    });
  };

  useEffect(() => {
    const education = application?.education;

    if (education) {
      formHandler.setFullForm({
        answer: education.answer
      });
    }
  }, []);

  return (
    <div className="education-form">
      <DynamicForm handler={formHandler} />
      <div className="apllicant-form-footer">
        <Button onClick={handleBack} variant="secondary" text="Back" />
        <Button onClick={submit} text="Continue" loading={api.isLoading} disabled={!formHandler.valid} />
      </div>
    </div>
  );
};

export default Education;

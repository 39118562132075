import React from 'react';

type Props = {
  label: string;
  description?: string;
};

const FormSection = ({ label, description }: Props) => {
  return (
    <div className="form-section">
      <h6>{label}</h6>
      <span className="body">{description}</span>
    </div>
  );
};

export default FormSection;

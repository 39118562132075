import React from 'react';
import classNames from 'classnames';
import { getLastMessageDate } from 'utils/data';

type ChannelPreviewProps = {
  channel: any;
  isMobile: boolean;
};

const ChannelPreview = ({ channel, isMobile }: ChannelPreviewProps) => {
  const formattedDate = getLastMessageDate(channel);
  const unreadMessageCount = channel.unreadMessageCount;

  return (
    <div className="channel-preview">
      <div className="avatar">
        <img src={channel.coverUrl} alt="avatar" />
      </div>
      <div className="channel-content">
        <div className="channel-info">
          <span className={classNames('strong', { 'channel-name': !isMobile, truncate: isMobile })}>
            {channel.name}
          </span>
          <span className="date">{formattedDate}</span>
        </div>
        <div className="channel-last-message">
          <span className="truncate truncated-text">{channel?.lastMessage?.message}</span>
          <div className={unreadMessageCount > 0 ? 'show-unread-message-count' : 'hide-unread-message-count'}>
            <div className="channel-unread-message-count">
              <span>{unreadMessageCount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelPreview;

export type CommonProps = {
  application?: Application;
  handleContinue: () => void;
  handleBack: () => void;
  updateApplication?: (data: Application) => void;
};

export const StepSequence = {
  VENUES: 1,
  EDUCATION: 2,
  PERSONAL_INTRODUCTION: 3,
  HOW_DID_YOU_HEAR_ABOUT_US: 4,
  SUMMARY: 5,
  PENDING_REVIEW: 6
};

interface Venues {
  state: string;
  venues: number[];
}

interface PersonalIntroduction {
  profilePicture: FileStructure;
  videoPresentation: FileStructure;
  resume: FileStructure;
  foodExperience: string | null;
  managementExperience: string | null;
  servingOthers: string | null;
}

interface FileStructure {
  fileName: string;
  fileSize: { size: number };
  path: string;
  url: string;
}

interface Education {
  answer: string | null;
}

interface HowDidYouHearAboutUs {
  answer: string | null;
}

interface StepStatus {
  currentStatus: number;
  nextStatus: number;
}

interface ApplicationDetails {
  stepStatus: StepStatus;
  generalStatus: string;
}

interface Summary {
  State: string;
  Venues: string[];
  'Level of Education': string;
  'Profile Picture': {
    url: string;
    fileName: string;
    fileSize: number;
    path: string;
  };
  'Video Presentation': {
    url: string;
    fileName: string;
    fileSize: number;
    path: string;
  };
  Resume: {
    url: string;
    fileName: string;
    fileSize: number;
    path: string;
  };
  'Bartender/Food Experience': boolean;
  'Management Experience': boolean;
  'What it means to serve': string;
  'How did you hear about us?': string;
}

export interface Application {
  venues: Venues;
  personalIntroduction: PersonalIntroduction;
  education: Education;
  howDidYouHearAboutUs: HowDidYouHearAboutUs;
  applicationId: number;
  isApplicationCompleted: boolean;
  applicationDetails: ApplicationDetails;
  summary: Summary;
}

import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="page-not-found">
      <h3>Oops!</h3>
      <h1>404</h1>
    </div>
  );
};

export default NotFoundPage;

import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';

export const howDidYouHearAboutUsOptions = [
  { label: 'Existing worker', value: 'existing_worker' },
  { label: 'Flyer', value: 'flyer' },
  { label: 'Digital ad', value: 'digital_ad' },
  { label: 'Word of mouth', value: 'word_of_mouth' },
  { label: 'Other', value: 'other' }
];

export default {
  answer: {
    label: 'How did you hear about us?',
    value: '',
    options: howDidYouHearAboutUsOptions,
    input: InputsEnum.RADIO_SELECTOR,
    joi: joi.string().required()
  }
};

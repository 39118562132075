import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Card, Sanitized, ViewerFile } from 'components';

const MyNoticesAcceptedPage = () => {
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoints.AGREED_NOTICES.get());
  }, []);

  return (
    <div className="page-notices-accepted">
      <Card title="My Notices">
        <Card.Item>
          {api?.response?.items?.length > 0 &&
            api?.response?.items.map((item: any, index: number) => (
              <div key={index} className="notice">
                <div className="body strong">{index + 1})</div>
                <Sanitized html={item.content} />
                {item.file && <ViewerFile fileExtension={item.file_extension} fileUrl={item.file_url} />}
              </div>
            ))}
        </Card.Item>
      </Card>
    </div>
  );
};

export default MyNoticesAcceptedPage;

import React from 'react';
import { DocumentDownload } from 'iconsax-react';
import FileViewer from 'react-file-viewer';
import { blobToBase64, downloadFromBase64 } from 'helpers/data';
import { Button } from 'components';

interface IFileViewer {
  fileExtension: string;
  fileUrl: string;
  downloadBtn?: boolean;
}

const ViewerFile = ({ fileExtension, fileUrl, downloadBtn = true }: IFileViewer) => {
  const handleDownload = (url: string) => async () => {
    const response = await fetch(`${url}?cacheblock=true`);
    const blob = await response.blob();
    const b64 = ((await blobToBase64(blob)) as string).split(',')[1];
    const fileName = url.split('/').pop() ?? 'file';

    downloadFromBase64(b64 as string, fileName, '');
  };

  return (
    <div className="viewer-container">
      {fileExtension === 'pdf' ? (
        <div className="viewer">
          <FileViewer fileType={fileExtension} filePath={fileUrl} />
        </div>
      ) : (
        <div className="viewer-img">
          <img src={fileUrl} className="image" />
        </div>
      )}
      {downloadBtn && (
        <div className="download-btn">
          <Button
            variant="alternative"
            icon={<DocumentDownload />}
            text="Download document"
            small
            onClick={handleDownload(fileUrl)}
          />
        </div>
      )}
    </div>
  );
};

export default ViewerFile;

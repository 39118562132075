import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modal';
import schema from './schema';
import Base from '../base';
import { formatNumber } from 'utils/data';

const PhoneNumber = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    const phoneNumber = formHandler.data.phoneNumber.replace(/[^\d+]/g, '');
    onClose({ phoneNumber });
  };

  useEffect(() => {
    const { phoneNumber } = params.data.personal;
    formHandler.setFullForm({ phoneNumber: formatNumber(phoneNumber) });
  }, []);

  return (
    <Base
      header="Phone number"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Save changes" disabled={!formHandler.valid} onClick={handleSubmit} />}
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default PhoneNumber;

import React from 'react';
import { toast } from 'sonner';

const useToast = () => {
  const notify = (message: string, icon?: React.ReactNode, duration = 3500) => {
    toast(message, {
      icon: icon,
      className: 'toast-message',
      duration
    });
  };

  return notify;
};

export { useToast };

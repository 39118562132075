/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import useTraining, { Step } from 'hooks/useTraining';
import { BackButton, Button, Card, DynamicForm, TrainingCompleted, ViewerFile, YoutubeEmbed } from 'components';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import QuizErrors from 'components/common/quizErrors';
import { useToast } from 'hooks/useToast';

type Props = {
  trainingId: string;
  setTrainingId?: (id: string | null) => void;
  page: 'ONBOARDING' | 'MY-TRAINING';
};

const Wizard = ({ trainingId, setTrainingId, page }: Props) => {
  const {
    title,
    description,
    step,
    videos,
    files,
    formHandler,
    activeStep,
    totalSteps,
    isLoading,
    advanceToNextStep,
    prevStep,
    completed,
    hasQuiz
  } = useTraining({
    trainingId
  });
  const [quizErros, setQuizErrors] = useState<any[]>([]);
  const ref = useRef<HTMLDivElement | null>(null);
  const api = useApi();
  const modal = useModal();
  const navigate = useNavigate();
  const toast = useToast();

  const renderVideos = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return videos.map((videoId, index) => <YoutubeEmbed key={index} embedId={videoId} />);
  };

  const renderFiles = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return files.map((file, index) => <ViewerFile key={index} fileExtension="pdf" fileUrl={file} />);
  };

  const renderQuizForm = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (completed) {
      return <TrainingCompleted />;
    }

    return (
      <>
        {description && (
          <div className="quiz-description">
            <span className="body">{description}</span>
          </div>
        )}
        <div className="quiz-form">
          <DynamicForm handler={formHandler} />
        </div>
        {quizErros.length > 0 && <QuizErrors quizErros={quizErros} />}
      </>
    );
  };

  const handleSendQuiz = () => {
    api.fetchData(endpoints.SEND_RESPONSE.get({ trainingId }), { data: formHandler.data });
  };

  const handleSubmit = async () => {
    const modalTitle = hasQuiz ? 'Send Quiz' : 'Finish Training';
    const modalText = hasQuiz
      ? 'Are you sure you want to send the quiz?'
      : 'Are you sure you want to finish the training?';

    modal.open(
      ModalsList.FINISH_TRAINING,
      async data => {
        if (data) {
          handleSendQuiz();
        }
      },
      { title: modalTitle, text: modalText }
    );
  };

  useEffect(() => {
    if (api.loaded) {
      if (!api.response.success) {
        setQuizErrors(api.response.data);
      } else {
        toast('Training finished successfully');
        page === 'MY-TRAINING' ? navigate(routes.MY_TRAININGS) : setTrainingId!(null);
      }
    }
  }, [api.loaded, api?.response?.data]);

  useEffect(() => {
    if (quizErros) {
      const updatedSchema = { ...formHandler.schema };
      const schemaKeys = Object.keys(updatedSchema);
      const errorIndexes = quizErros.map((error: any) => error.index);

      for (const key of schemaKeys) {
        updatedSchema[key] = {
          ...updatedSchema[key],
          wrongAnswer: false
        };
      }

      errorIndexes.forEach((index: number) => {
        updatedSchema[index] = {
          ...updatedSchema[index],
          wrongAnswer: true
        };
      });

      formHandler.setSchema(updatedSchema);
    }
  }, [quizErros]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [activeStep, ref.current]);

  const renderStep = () => {
    switch (step) {
      case Step.VIDEOS:
        return renderVideos();
      case Step.FILES:
        return renderFiles();
      case Step.QUIZ:
        return renderQuizForm();
      default:
        return null;
    }
  };

  return (
    <div className="wizard" ref={ref}>
      <Card
        title={isLoading ? 'Loading...' : `${title} - Step ${activeStep}/${totalSteps}`}
        leftElement={activeStep > 1 && <BackButton onClick={prevStep} />}
      >
        <Card.Item>{renderStep()}</Card.Item>
        <Card.Footer>
          <div className="wizard-footer">
            <Button
              text="Back"
              variant="secondary"
              onClick={() => {
                if (activeStep === 1) {
                  page === 'MY-TRAINING' ? navigate(routes.MY_TRAININGS) : setTrainingId!(null);
                  return;
                }

                prevStep();
              }}
            />
            <Button
              text={step === Step.QUIZ ? 'Submit' : activeStep === totalSteps ? 'Finish' : 'Next'}
              onClick={() => {
                if (step === Step.QUIZ || activeStep === totalSteps) {
                  handleSubmit();
                  return;
                }

                advanceToNextStep();
              }}
              loading={api.isLoading}
              disabled={(!formHandler.valid && step === Step.QUIZ) || (activeStep === totalSteps && completed)}
            />
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Wizard;

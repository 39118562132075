import React from 'react';
import { Props } from 'types/modal';
import Base from '../base';
import CheckCircle from 'components/icons/checkCircle';
import { Button } from 'components';
import Clock from 'components/icons/clock';

const SuccessContent = ({ position, stand }: { position: string; stand: string }) => {
  const standPosition = `at ${stand}`;

  return (
    <>
      <CheckCircle />
      <div className="text-container">
        <div className="heading-lg strong">Congratulations!</div>
        <div className="heading-lg">
          You have been assigned as a <br />
          <span className="strong highlighted-text">{`${position} ${stand ? standPosition : ''}`}</span>
        </div>
      </div>
    </>
  );
};

const WaitlistContent = () => {
  return (
    <>
      <Clock />
      <div className="text-container">
        <div className="heading-lg strong">You will receive your shift assignment soon!</div>
      </div>
    </>
  );
};

const SuccessModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base closing={closing} onClose={onClose} type="alert">
      <div className="success-modal">
        {params.position ? <SuccessContent position={params.position} stand={params.stand} /> : <WaitlistContent />}
        <Button text="Ok" variant="primary" onClick={onClose} fullWidth />
      </div>
    </Base>
  );
};

export default SuccessModal;

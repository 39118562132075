import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import { Card } from 'components';
import { RootState } from 'types/store';

const StaffPassportPage = () => {
  const { data } = useSelector((state: RootState) => state.user);
  const [qr, setQR] = useState('');

  useEffect(() => {
    if (data) {
      const { employeeId, name, email } = data;
      setQR(JSON.stringify({ id: employeeId, name, email }));
    }
  }, [data]);

  return (
    <div className="page-staff-passport">
      <Card title="Staff Passport"> </Card>

      <div className="content">
        <span className="body">Show the QR to validate your identity</span>
        {!!qr && <QRCodeSVG value={qr} size={256} />}
        <span className="body strong">{data.name}</span>
      </div>
    </div>
  );
};

export default StaffPassportPage;

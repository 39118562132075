import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import useWindowSize from '@revolt-digital/use-window-size';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import { base64ToFile, generateFilename } from 'helpers/data';
import useApi from 'hooks/useApi';
import useImageCrop from 'hooks/useImageCrop';
import endpoints from 'constants/endpoints';

const UploadProfilePictureModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const apiS3 = useApi();
  const apiUpdate = useApi();

  const { crop, setCrop, setCompletedCrop, imgRef, onImageLoad, getCroppedImg } = useImageCrop();
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const handleUploadImage = async () => {
    setIsLoading(true);

    const croppedImageUrl = getCroppedImg();
    const filename = generateFilename('profile', 'jpg');
    const file = await base64ToFile(croppedImageUrl!, filename);

    const signedUrl = await api.fetchData(endpoints.GET_SIGNED_PROFILE_IMAGE.get(), {
      fileName: file.name
    });
    await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);
    await apiUpdate.fetchData(endpoints.EDIT_PERSONAL_INFO.get(), {
      profilePhotoPath: signedUrl.filePath
    });

    setIsLoading(false);
    onClose(true);
  };

  return (
    <Base
      header="Upload Picture"
      closing={closing}
      onClose={onClose}
      size={isMobile ? 'lg' : 'md'}
      footer={<Button text="Save changes" onClick={handleUploadImage} loading={isLoading} />}
    >
      <div className="profile-crop">
        <ReactCrop
          circularCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={c => setCompletedCrop(c)}
          locked
        >
          <img ref={imgRef} src={params.url} alt="user-profile" onLoad={onImageLoad} />
        </ReactCrop>
      </div>
    </Base>
  );
};

export default UploadProfilePictureModal;

import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Card, PaymentCard } from 'components';

// @todo: add types for backend response

const PaymentsPage = () => {
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoints.GET_PAYMENTS.get());
  }, []);

  return (
    <div className="page-payments">
      <Card title="Payments"> </Card>

      {!!api.response?.data && (
        <>
          {api.response.data.map((item: any) => (
            <PaymentCard
              key={item.paymentId}
              paymentDate={item.payday}
              event={item.event}
              venue={item.venue}
              eventDate={item.held_on}
              checkPayrollId={item.check_payroll_id}
            />
          ))}

          {!api.response.data.length && (
            <Card>
              <Card.Item>No payments registered so far</Card.Item>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentsPage;

export enum UniformStatus {
  APPROVED = 'approved',
  INCOMPLETE = 'incomplete',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED = 'rejected'
}

export const UNIFORM_STATUS = {
  incomplete: 'Incomplete',
  pending_approval: 'Pending Approval',
  approved: 'Approved',
  rejected: 'Rejected'
} as const;

export type UniformKey = keyof typeof UNIFORM_STATUS;

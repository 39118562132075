import React, { useEffect } from 'react';
import { App } from '@capacitor/app';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const PendingReview = () => {
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAppStateChange = (state: any) => {
      if (state.isActive) {
        api.fetchData(endpoints.GET_APPLICATION.get()).then(response => {
          if (response.data.isApplicationCompleted) {
            navigate(routes.ON_BOARDING);
          }
        });
      }
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      App.removeAllListeners();
    };
  }, []);

  return (
    <div className="pending-review">
      <span>Application Submitted</span>
      <div className="divider" />
      <p className="body">
        We&apos;ve received your application, we&apos;ll review it and get back to you in the next few days.
      </p>
    </div>
  );
};

export default PendingReview;

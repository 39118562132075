import joi from 'joi';
import formErrors from 'constants/formErrors';
import { nameRegex } from 'constants/regex';
import { ssnMask } from 'constants/masks';
import { blockNonAlphaCharacters } from 'helpers/keyBlockers';
import { isOver18 } from 'utils/data';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  legalFirstName: {
    value: '',
    label: 'First',
    limit: 50,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  },
  legalMidName: {
    value: '',
    label: 'Middle',
    limit: 40,
    required: false,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().allow('').regex(nameRegex).optional().messages({
      'string.pattern.base': formErrors.INVALID_NAME
    })
  },
  legalLastName: {
    value: '',
    label: 'Last',
    limit: 50,
    required: true,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    keyBlocker: blockNonAlphaCharacters,
    joi: joi.string().regex(nameRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NAME,
      'string.empty': formErrors.REQUIRED
    })
  },
  dateOfBirth: {
    value: '',
    label: 'Date of birth',
    required: true,
    input: InputsEnum.INPUT,
    type: TextInputTypes.DATE,
    joi: joi
      .string()
      .required()
      .custom((value, helpers) => (isOver18(value) ? value : helpers.error('string.age')))
      .messages({
        'string.age': formErrors.AGE_ERROR,
        'string.empty': formErrors.REQUIRED
      })
  },
  ssnOrItin: {
    value: '',
    label: 'SSN or ITIN',
    required: true,
    input: InputsEnum.MASKED,
    mask: ssnMask,
    joi: joi.string().length(11).required().messages({
      'string.base': formErrors.INVALID_NUMBER,
      'string.empty': formErrors.REQUIRED,
      'string.length': formErrors.INVALID_LENGTH
    })
  },
  tshirtSize: {
    value: '',
    options: [
      { value: 'S', label: 'S' },
      { value: 'M', label: 'M' },
      { value: 'L', label: 'L' },
      { value: 'XL', label: 'XL' },
      { value: '2XL', label: '2XL' },
      { value: '3XL', label: '3XL' },
      { value: '4XL', label: '4XL' },
      { value: '5XL', label: '5XL' }
    ],
    label: 'T-shirt size',
    required: true,
    input: InputsEnum.SELECT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};

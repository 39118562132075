import joi from 'joi';
import { phoneNumberRegex } from 'constants/regex';
import formErrors from 'constants/formErrors';
import { phoneNumberMask } from 'constants/masks';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  phoneNumber: {
    value: '',
    label: 'Phone number',
    required: true,
    input: InputsEnum.MASKED,
    mask: phoneNumberMask,
    joi: joi.string().regex(phoneNumberRegex).required().messages({
      'string.base': formErrors.INVALID_NUMBER,
      'string.empty': formErrors.REQUIRED,
      'string.length': formErrors.INVALID_LENGTH,
      'string.pattern.base': formErrors.INVALID_LENGTH
    })
  }
};

import React, { useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Button, Card, DynamicForm } from 'components';
import { Props } from '../types';
import schema from './schema';
import { getPlaneSSN } from 'utils/data';

const EmployeeIdentity = ({ handleContinue, step }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    const dateOfBirth = format(parseISO(formHandler.data.dateOfBirth), 'yyyy-MM-dd');
    const ssnOrItin = getPlaneSSN(formHandler.data.ssnOrItin);

    api.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step, data: { ...formHandler.data, dateOfBirth, ssnOrItin } });
  };

  useEffect(() => {
    if (api.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [api.response]);

  return (
    <div className="personal-information">
      <Card title="Personal Details" description="We need this information to verify your legal identity.">
        <>
          <Card.Item>
            <DynamicForm handler={formHandler} />
          </Card.Item>
          <Card.Footer>
            <Button text="Continue" onClick={handleSubmit} disabled={!formHandler.valid} loading={api.isLoading} />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default EmployeeIdentity;

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';

const useImagePicker = (onSelected: (image: string | undefined) => void) => {
  const showPrompt = async () => {
    try {
      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        direction: CameraDirection.Front
      });
      onSelected(photo.dataUrl);
    } catch (error) {
      console.error('Camera error:', error);
    }
  };

  const showCamera = async () => {
    try {
      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        direction: CameraDirection.Front
      });
      onSelected(photo.dataUrl);
    } catch (error) {
      console.error('Camera error:', error);
    }
  };

  return { showPrompt, showCamera };
};

export default useImagePicker;

import React, { useEffect } from 'react';
import { Button, Card } from 'components';
import { ArrowRight2, InfoCircle, TickCircle } from 'iconsax-react';
import { TrainingItem } from '.';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';

type Props = {
  step: number;
  handleContinue: () => void;
  onSelect: (id: any) => void;
};

const TrainingList = ({ step, onSelect, handleContinue }: Props) => {
  const api = useApi();
  const apiTraining = useApi();

  const handleSubmit = () => {
    apiTraining.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step });
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_TRAINING_STATUS_LIST.get({ flow: 'Onboarding' }));
  }, []);

  useEffect(() => {
    if (apiTraining.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [apiTraining.response]);
  return (
    <Card title="Trainings">
      <>
        {api?.response?.onboarding.length > 0 ? (
          api?.response?.onboarding.map((item: TrainingItem) => (
            <div className="training-list" key={item.id}>
              <Card.Item onClick={() => onSelect(item.id)}>
                <div className="content">
                  <div className="item">
                    {!item.completed ? <InfoCircle className="info-circle" /> : <TickCircle className="tick-circle" />}
                    <p>{item.name}</p>
                  </div>
                  <ArrowRight2 className="icon" />
                </div>
              </Card.Item>
            </div>
          ))
        ) : (
          <Card.Item>
            <div>There are currently no trainings available to take. You can continue to the next step.</div>
          </Card.Item>
        )}
      </>
      <Card.Footer>
        <Button text="Continue" onClick={handleSubmit} />
      </Card.Footer>
    </Card>
  );
};

export default TrainingList;

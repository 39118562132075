import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button, Card } from 'components';
import { Props } from '../types';

const Welcome = ({ handleContinue, step }: Props) => {
  const api = useApi();

  const handleSubmit = () => {
    api.fetchData(endpoints.ON_BOARDING_UPDATE.get(), { step });
  };

  useEffect(() => {
    if (api.response) {
      const { success } = api.response;
      success && handleContinue();
    }
  }, [api.response]);

  return (
    <div className="welcome">
      <Card title="Welcome!">
        <>
          <Card.Item>
            <p className="body">
              We are so excited you&#39;re joining the team. To make sure you&#39;re ready for your first event,
              following onboarding steps and prepare to get #lockedin for the season.
            </p>
            <br />
            <p className="body">
              You can find instructions for the employee onboarding process here. If you have any questions or concerns
              while completing the onboarding process that are not covered in the provided instructions, please contact
              the EATS2SEATS Support Team at support@eats2seats.com. Cheers!
            </p>
          </Card.Item>
          <Card.Footer>
            <Button text="Start" onClick={handleSubmit} loading={api.isLoading} />
          </Card.Footer>
        </>
      </Card>
    </div>
  );
};

export default Welcome;

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { CloseSquare } from 'iconsax-react';
import { Button } from 'components';

type Props = {
  children: React.ReactNode;
  closing: boolean;
  header?: string | React.ReactNode;
  onClose: () => void;
  footer?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  type?: 'modal' | 'alert';
};

const ModalBase = ({ closing, header, children, size, onClose, footer, className, type = 'modal' }: Props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <div className={classnames('modal-base', { init, closing }, className)}>
      <div className="backdrop" onClick={() => onClose()} />

      <div
        className={classnames(size, {
          container: type === 'modal',
          'alert-container': type === 'alert'
        })}
      >
        {type === 'modal' && (
          <div className="modal-header">
            {!!header && typeof header === 'string' ? <h6>{header}</h6> : header}
            <Button icon={<CloseSquare />} onClick={() => onClose()} variant="alternative" transparent />
          </div>
        )}
        <div className="modal-body">{children}</div>
        {!!footer && <div className="modal-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default ModalBase;

import React, { ChangeEvent, useRef, useState } from 'react';
import classnames from 'classnames';
import Button from 'components/common/button';
import { TextInputProps } from 'types/dynamicInputs';

const InputFile = ({
  label,
  name,
  onChange,
  onBlur,
  error,
  required = false,
  pristine = true,
  disabled = false
}: TextInputProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onChange(file);
      setFileName(file.name);
    }
  };

  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <div className="file-input-container">
        <Button text="Choose File" onClick={handleClick} small disabled={disabled} />
        <input
          ref={hiddenFileInput}
          type="file"
          name={name}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          accept="application/pdf,image/jpeg,image/jpg,image/png,.xlsx,.csv"
        />
        {fileName && (
          <small
            className="file-name"
            onClick={handleClick}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleClick();
              }
            }}
          >
            {fileName}
          </small>
        )}
      </div>
      {pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default InputFile;

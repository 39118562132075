import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';
import { CommonProps, StepSequence } from '../types';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const Venues = ({ application, handleContinue, updateApplication }: CommonProps) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const submit = () => {
    const data = formHandler.data.venue;

    const transformedData = {
      currentStep: StepSequence.VENUES,
      data: {
        venues: data.venues.map(Number),
        state: Number(data.state)
      }
    };

    api.fetchData(endpoints.UPDATE_APPLICATION.get(), transformedData).then(r => {
      updateApplication?.(r.data);
      handleContinue();
    });
  };

  useEffect(() => {
    const data = application?.venues;

    if (data?.venues) {
      formHandler.setFullForm({
        venue: {
          venues: data.venues.map(String),
          state: data.state
        }
      });
    }
  }, []);

  return (
    <div className="venue-form">
      <DynamicForm handler={formHandler} />
      <div className="apllicant-form-footer first">
        <Button onClick={submit} text="Continue" loading={api.isLoading} disabled={!formHandler.valid} />
      </div>
    </div>
  );
};

export default Venues;

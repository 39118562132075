import React from 'react';
import classnames from 'classnames';
import classNames from 'classnames';

type Props = {
  children?: React.ReactNode;
  description?: string;
  className?: string;
  title?: string;
  leftElement?: React.ReactNode;
  onClick?: () => void;
};

type itemProps = {
  children: React.ReactNode;
  grid?: boolean;
  className?: string;
  onClick?: () => void;
};

const Item = ({ children, grid, className, onClick }: itemProps) => (
  <div
    className={classnames('card-item', className)}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick?.();
      }
    }}
    onFocus={() => {}}
    onBlur={() => {}}
    aria-pressed="false"
  >
    <div className={classnames({ grid: grid }, className)}>{children}</div>
  </div>
);

const Footer = ({ children, className }: itemProps) => (
  <div className={classNames('card-footer', className)}>{children}</div>
);

const Card = ({ title, description, children, className = '', leftElement, onClick }: Props) => (
  <div
    className={classnames('card', className)}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick?.();
      }
    }}
    onFocus={() => {}}
    onBlur={() => {}}
    aria-pressed="false"
  >
    {(!!title || !!description) && (
      <div className="header">
        <div className="content">
          {!!leftElement && leftElement}
          {!!title && <h6>{title}</h6>}
        </div>
        {!!description && <p className="body">{description}</p>}
      </div>
    )}
    {children}
  </div>
);

export default Card;

Card.Item = Item;
Card.Footer = Footer;

import { createColumnHelper } from '@tanstack/react-table';

export type PositionStatsType = {
  positionName: string;
  quantity: number;
};

const columnHelper = createColumnHelper<PositionStatsType>();

export const columns = [
  columnHelper.accessor('positionName', {
    header: 'Position',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    cell: info => info.renderValue()
  })
];

export default {
  APPLICANT: '/applicant',
  HOME: '/',
  MY_NOTICES_ACCEPTED: '/my-notices-accepted',
  EVENT_INFO: '/events/:id',
  MY_VENUES: '/my-venues',
  NOTICES: '/notices',
  ON_BOARDING: '/on-boarding',
  PAYMENTS: '/payments',
  RESET_PASSWORD: '/reset-password',
  SIGN_IN: '/sign-in',
  STAFF_PASSPORT: '/staff-passport',
  MY_PROFILE: '/my-profile',
  VERIFY_EMAIL: '/email/verify',
  MY_STATS: '/my-stats',
  MY_PAYMENT_METHOD: '/my-payment-method',
  MY_TRAININGS: '/my-trainings',
  TRAINING_WIZARD: '/my-trainings/wizard/:id',
  MY_WARNINGS: '/my-warnings',
  MY_CHATS: '/my-chats',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_DETAIL: '/notifications/:id'
};

import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';
import { ssnMask } from 'constants/masks';

export default {
  ssnOrItin: {
    value: '',
    label: 'SSN or ITIN',
    required: true,
    input: InputsEnum.MASKED,
    mask: ssnMask,
    joi: joi.string().length(11).required().messages({
      'number.base': formErrors.INVALID_NUMBER,
      'number.empty': formErrors.REQUIRED,
      'string.length': formErrors.INVALID_LENGTH
    })
  }
};

import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

type Props = {
  value: boolean;
  disabled?: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
};

const ToggleSwitch = ({ value, disabled, onChange }: Props) => {
  const handleClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  return (
    <div className={classNames('toggle-switch', { disabled })} onClick={handleClick}>
      <input type="checkbox" checked={value} onChange={() => {}} />
      <span className="switch" />
    </div>
  );
};

export default ToggleSwitch;

import React, { useState } from 'react';
import { Button, Dropzone } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import { useDropzone } from 'react-dropzone';
import { useToast } from 'hooks/useToast';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const FileUploader = ({ closing, onClose, params }: Props) => {
  const [files, setFiles] = useState<any[]>([]);
  const toast = useToast();
  const apiFile = useApi();
  const apiS3 = useApi();
  const apiSaveFile = useApi();
  const loading = apiFile.isLoading || apiS3.isLoading || apiSaveFile.isLoading;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles.length + files.length > params.maxFiles) {
        toast(`Maximum of ${params.maxFiles} files allowed`);
        return;
      }
      setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    },
    accept: params.accept
  });

  const removeFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleUploadFiles = async () => {
    if (files?.length > 0) {
      const filePathArr: string[] = [];
      for (const file of files) {
        const signedUrl = await apiFile.fetchData(endpoints.CERTIFICATION_SIGNED_URL.get(), {
          fileName: file.name
        });
        await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);
        filePathArr.push(signedUrl.filePath);
      }

      const response = await apiSaveFile.fetchData(params.endpoint, {
        filePaths: filePathArr
      });

      onClose(response?.success);
    }
  };

  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" onClick={() => onClose(false)} variant="secondary" capitalize />
          <Button
            text={loading ? 'Uploading...' : 'Upload'}
            onClick={handleUploadFiles}
            capitalize
            disabled={files.length === 0}
            loading={loading}
          />
        </>
      }
    >
      <Dropzone
        message={params.message}
        files={files}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        removeFile={removeFile}
      />
    </Base>
  );
};

export default FileUploader;

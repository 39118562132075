import React from 'react';
import { InfoCircle } from 'iconsax-react';

type Props = {
  quizErros: any[];
};

const QuizErrors = ({ quizErros }: Props) => {
  return (
    <div className="quiz-errors">
      <div className="heading question-title">
        <InfoCircle size={22} />{' '}
        <span className="body">Please review the following questions and answer them again</span>
      </div>
      <div className="error-list">
        {quizErros.map((error, index) => (
          <div key={index} className="error-item">
            <span className="body">
              #{error.questionNumber}: {error.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizErrors;

import React from 'react';
import FileViewer from 'react-file-viewer';
import { Button } from 'components';
import { Props } from 'types/modal';
import Base from '../base';
import { getUrlFileExtension } from 'utils/data';

const acceptedImgExtensions = ['png', 'jpg', 'jpeg'];

interface File {
  id: number;
  path: string;
  url: string;
}

const ViewAlcoholCertificateModal = ({ closing, onClose, params: { files } }: Props) => {
  return (
    <Base
      header="My Alcohol Certificate"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={
        <>
          <Button text="Close" onClick={() => onClose()} capitalize />
        </>
      }
    >
      <div className="alcohol-viewer-container">
        {files?.map((file: File) => {
          const fileType = getUrlFileExtension(file.url) ?? '';

          return fileType === 'pdf' ? (
            <div key={file.id} className="viewer-pdf">
              <FileViewer fileType="pdf" filePath={file.url} />
            </div>
          ) : acceptedImgExtensions.includes(fileType) ? (
            <div key={file.id} className="viewer-img">
              <img src={file.url} className="image" />
            </div>
          ) : null;
        })}
      </div>
    </Base>
  );
};

export default ViewAlcoholCertificateModal;

import React from 'react';
import { Button, DynamicForm } from 'components';
import { forms } from 'types/signIn';

type Props = {
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
  handleSetCurrentForm: (form: forms) => () => void;
};

const Register = ({ handleSetCurrentForm, formHandler, loading, onSubmit }: Props) => {
  return (
    <>
      <h6>Create a new account</h6>
      <p className="body">
        or{' '}
        <span className="link" onClick={handleSetCurrentForm(forms.LOGIN)}>
          sign in to an existing account
        </span>
      </p>
      <hr />
      <DynamicForm handler={formHandler} />
      <Button text="Register" loading={loading} disabled={!formHandler.valid} onClick={onSubmit} />
    </>
  );
};

export default Register;

import { useEffect, useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import endpoints from 'constants/endpoints';
import { positionMap } from 'constants/user';
import { confirmDropEventMessage } from 'constants/messages';
import useApi from './useApi';
import { useModal } from './useModal';
import { ModalsList } from 'types/modal';
import { checkAvailablePositions } from 'utils/data';
import { isTimeDifferenceLessThanHours } from 'helpers/date';
import { Allocation } from 'components/common/eventCard';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { useDispatch } from 'react-redux';
import { setHasChats } from 'store/actions/user';

type EventPreference = {
  positionPreference: number;
  employeePreference: string;
  standPreference: number;
};

interface Props {
  eventId: string;
  isLeader: boolean;
  jobType: number[];
  subscribed: boolean;
  canConfirm: boolean;
  date: string;
  timezone: string;
  canDropout: boolean;
  dropped: boolean;
  allocation: Allocation;
  confirmationDeadline: string | null;
  registrationCallback: () => void;
}

const useEventSubscription = ({
  isLeader,
  eventId,
  jobType,
  subscribed,
  canConfirm,
  date,
  timezone,
  canDropout,
  dropped,
  allocation,
  confirmationDeadline,
  registrationCallback
}: Props) => {
  const api = useApi();
  const { data } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const apiEventStatus = useApi();
  const apiAvailableLeaderPositions = useApi();
  const modal = useModal();

  const [confirmStatus, setConfirmStatus] = useState({
    subscribed: false,
    canConfirm: false,
    canDropout: false,
    dropped: false,
    confirmationDeadline: null as string | null
  });

  const [userAllocation, setUserAllocation] = useState(allocation);

  const updateEventSubscription = async (data?: EventPreference) => {
    await api.fetchData(endpoints.EVENTS_UPDATE_SUBSCRIPTION.get({ id: eventId }), data);
  };
  const handleConfirmAttendance = async (data?: any) => {
    await api.fetchData(endpoints.CONFIRM_ATTENDANCE.get({ id: eventId }), data ? data : undefined);
  };
  const checkAvailableLeaderPositions = async () => {
    await apiAvailableLeaderPositions.fetchData(endpoints.AVAILABLE_LEADER_POSITIONS.get({ id: eventId }));
  };
  const handleCheckEventStatus = async () => {
    await apiEventStatus.fetchData(endpoints.EVENT_STATUS.get({ id: eventId }));
  };

  const handleSubscribe = () => {
    if (isLeader) {
      updateEventSubscription();
    } else {
      handleCheckEventStatus();
    }
  };

  const handleSubscribeStaff = async (canSetPreference: boolean) => {
    if (canSetPreference && !confirmStatus.subscribed) {
      handleEventPreferenceModal();
    } else {
      updateEventSubscription();
    }

    registrationCallback && registrationCallback();
  };

  const handleConfirm = async () => {
    if (isLeader) {
      checkAvailableLeaderPositions();
    } else {
      handleConfirmAttendance();
    }
  };

  const handleConfirmAttendanceLeader = async (canSetPreference: boolean) => {
    if (canSetPreference) {
      handleEventPreferenceModal();
    } else {
      handleConfirmAttendance();
    }
  };

  const showNoAvailablePositions = () => {
    modal.open(
      ModalsList.LEAD_POSITION_NO_AVAILABLE,
      (flag: boolean) => {
        if (flag) {
          handleCheckEventStatus();
        }
      },
      { title: 'No available positions' }
    );
  };

  const handleEventPreferenceModal = () => {
    modal.open(
      ModalsList.EVENT_PREFERENCES,
      async (data: any) => {
        if (data) {
          if (isLeader) {
            handleConfirmAttendance(data);
          } else {
            updateEventSubscription(data);
          }
        }
      },
      { title: 'Stand Preferences', id: eventId, userId: data?.id }
    );
  };

  const handleDropEvent = async () => {
    if (isTimeDifferenceLessThanHours(utcToZonedTime(date, timezone!), new Date(), 24)) {
      modal.open(
        ModalsList.CONFIRM,
        async (flag: boolean) => {
          if (flag) {
            await api.fetchData(endpoints.DROPOUT_EVENT.get({ id: eventId }));
            removeAllocation();
          }
        },
        confirmDropEventMessage
      );
    } else {
      await api.fetchData(endpoints.DROPOUT_EVENT.get({ id: eventId }));
      removeAllocation();
    }
  };

  const successfulConfirmation = (allocation: any, waitlistPosition: number) => {
    modal.open(ModalsList.SUCCESS_MODAL, async () => {}, {
      position: allocation.position,
      stand: allocation.stand,
      waitlistPosition
    });
  };

  const removeAllocation = () => {
    setUserAllocation({
      position: null,
      stand: null
    });
  };

  useEffect(() => {
    if (apiEventStatus.response) {
      const canSetPreferences = apiEventStatus.response.canSetPreferences;

      if (isLeader) {
        handleConfirmAttendanceLeader(canSetPreferences);
      } else {
        handleSubscribeStaff(canSetPreferences);
      }
    }
  }, [apiEventStatus.response]);

  useEffect(() => {
    if (apiAvailableLeaderPositions.response) {
      const leadersPositions = jobType;
      const availablePositions = apiAvailableLeaderPositions.response.data;

      const hasFreePositions = checkAvailablePositions(leadersPositions, availablePositions, positionMap);
      if (hasFreePositions) {
        handleConfirmAttendance();
      } else {
        showNoAvailablePositions();
      }
    }
  }, [apiAvailableLeaderPositions.response]);

  useEffect(() => {
    setConfirmStatus({ subscribed, canConfirm, canDropout, dropped, confirmationDeadline });
  }, [subscribed, canConfirm, canDropout, dropped, confirmationDeadline]);

  useEffect(() => {
    if (api.response) {
      if (api.response.data) {
        setConfirmStatus(api.response.data);
        dispatch(setHasChats(api.response.data.userAssignedToChat));

        if (api.response.data.allocation) {
          setUserAllocation(api.response.data.allocation);
          successfulConfirmation(api.response.data.allocation, api.response.data.waitlistPosition);
        }
      }
    }
  }, [api.response]);

  return {
    handleSubscribe,
    handleDropEvent,
    handleConfirm,
    confirmStatus,
    userAllocation,
    isLoading: api.isLoading,
    eventStatusLoading: apiEventStatus.isLoading,
    availableLeaderPositionsLoading: apiAvailableLeaderPositions.isLoading
  };
};

export default useEventSubscription;
